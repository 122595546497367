<template>
<ol >
        <li>Upload an audio file or click the record button to record lines.</li>
        <li>The uploaded/recorded audio will appear as a waveform</li>
        <li>Drag to select the portion of the waveform for a line</li>
        <li>click the <v-icon>mdi-plus-circle</v-icon> next to the corresponding line to assign the selected audio</li>
</ol> 
</template>

<script>

export default {
    name: "HelpImportAudio",
    props: {},
    computed: {
        
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>