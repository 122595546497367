export default {
  "title": "Tutorial 2: Running your lines",
  "created": 1681310447318,
  "id": "33585932-c11f-4251-89ac-6d6b304ed6a6",
  "rawLines": [],
  "pageinfo": {
    "numPages": 1,
    "x": 600,
    "y": 1000
  },
  "blocks": [
    {
      "type": "action",
      "texts": [
        "EXT. ON THE WAY TO THE AUDITION"
      ],
      "segments": [
        "EXT. ON THE WAY TO THE AUDITION"
      ],
      "indentpct": 1,
      "page": 1,
      "id": "77e47ec4-2e6d-41a1-9b52-0a90677a2c1c"
    },
    {
      "type": "action",
      "texts": [
        "Your audition is coming up and it’s time to start working on the script."
      ],
      "segments": [
        "Your audition is coming up and it’s time to start working on the script."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "a5b77981-7cf6-4eb7-82a1-ee36fe19b01c"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "I’ve got the script in MyLine, but now I have to start learning my lines. How exactly does the app help me do that?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "I’ve got the script in MyLine, but now I have to start learning my lines. How exactly does the app help me do that?"
      ],
      "id": "a59c94bf-8c73-4d9f-a63d-5f16275fce4b",
      "lang": ""
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "The app has a bunch of ways to help you work on your script. The most basic is to simply click the “Run Lines” button."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "The app has a bunch of ways to help you work on your script. The most basic is to simply click the “Run Lines” button."
      ],
      "id": "eab17a4f-9bd3-452e-a964-993b60878565",
      "lang": ""
    },
    {
      "type": "action",
      "texts": [
        "Try clicking the RUN LINES button and see what happens."
      ],
      "segments": [
        "Try clicking the RUN LINES button and see what happens."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "569d903a-0a48-4f96-abfa-35c387b5278a"
    },
    {
      "type": "action",
      "texts": [
        "Clicking the RUN LINES button again will PAUSE the run. While paused, you can change which line you resume running lines by clicking on that line."
      ],
      "segments": [
        "Clicking the RUN LINES button again will PAUSE the run. While paused, you can change which line you resume running lines by clicking on that line."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "a99d5934-538a-498b-8541-4d469a83aa0e"
    },
    {
      "type": "action",
      "texts": [
        "Clicking the STOP button (while paused) will stop running lines and reset the loop to the top."
      ],
      "segments": [
        "Clicking the STOP button (while paused) will stop running lines and reset the loop to the top."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "32422435-a383-46bb-8ae2-9c8a9dba3890"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "Oh, so running lines just plays all of the lines in order with the line audio being generated. But what if I don’t want to hear my own lines or I want to have the lines play again automatically after the script is finished?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "Oh, so running lines just plays all of the lines in order with the line audio being generated. But what if I don’t want to hear my own lines or I want to have the lines play again automatically after the script is finished?"
      ],
      "id": "dc7f69ef-33ae-429b-a223-580a2aeb38bf",
      "lang": ""
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "You can change various options by opening the RUN menu at the top of the screen."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "You can change various options by opening the RUN menu at the top of the screen."
      ],
      "id": "24e40219-242b-416e-b087-9b181024d43d",
      "lang": ""
    },
    {
      "type": "action",
      "texts": [
        "Open the RUN menu at the top of the screen."
      ],
      "segments": [
        "Open the RUN menu at the top of the screen."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "26076985-5181-4fdc-b3c0-87ccf19292af"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "Under “My Line Audio” you can cycle through what the app does with your line audio. The possibilities are PLAYING (it will play the audio), REPEAT AFTER ME (it will play the audio and then leave a gap for you to say it before going to the next line, and MUTED (it will just leave a gap)."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "Under “My Line Audio” you can cycle through what the app does with your line audio. The possibilities are PLAYING (it will play the audio), REPEAT AFTER ME (it will play the audio and then leave a gap for you to say it before going to the next line, and MUTED (it will just leave a gap)."
      ],
      "id": "490e428e-b10a-4811-a5cb-9cbc13aae937",
      "lang": ""
    },
    {
      "type": "action",
      "texts": [
        "Change your line audio to MUTED by clicking on it 2 times."
      ],
      "segments": [
        "Change your line audio to MUTED by clicking on it 2 times."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "69fe91cf-085a-42ff-b5b8-1b4ffc3aa12e"
    },
    {
      "type": "action",
      "texts": [
        "Start running lines again by clicking the RUN LINES button."
      ],
      "segments": [
        "Start running lines again by clicking the RUN LINES button."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "4d6b0f6c-8857-4afe-9c89-272c5c1dae87"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "You can also get an audio prompt of your line by clicking the PROMPT button (on mobile devices). This is enabled when the current line is yours and your audio is muted."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "You can also get an audio prompt of your line by clicking the PROMPT button (on mobile devices). This is enabled when the current line is yours and your audio is muted."
      ],
      "id": "f3f00381-1909-4b1d-bf55-c2857fe797f8",
      "lang": ""
    },
    {
      "type": "action",
      "texts": [
        "Pause the script when it is on your line and click the PROMPT button."
      ],
      "segments": [
        "Pause the script when it is on your line and click the PROMPT button."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "b5c7a1b8-0174-4a08-a028-f22d6342f1ab"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "Great! What if I want to have the script repeat once it gets to the end?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "Great! What if I want to have the script repeat once it gets to the end?"
      ],
      "id": "572375dc-5133-4fb3-a61c-7481e03bed20",
      "lang": ""
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "That’s called LOOPING and you can simply toggle that setting on and off. You’ll be able to see any settings by the icons on the RUN menu button."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "That’s called LOOPING and you can simply toggle that setting on and off. You’ll be able to see any settings by the icons on the RUN menu button."
      ],
      "id": "d1ebb59c-ee20-47a9-8bfb-62e16f5cf920",
      "lang": ""
    },
    {
      "type": "action",
      "texts": [
        "Turn on looping and run your script again. See what happens when it gets to the end!"
      ],
      "segments": [
        "Turn on looping and run your script again. See what happens when it gets to the end!"
      ],
      "indentpct": 1,
      "page": 1,
      "id": "8a1a04ed-e072-434d-8e94-8ae21de9820b"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "You can also change how your line text looks: FIRST WORD only shows the first word of each line. FIRST LETTER shows the first letter of every word in your lines and HIDDEN hides the line completely. If your lines are not fully visible, clicking on the line will reveal the entire line."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "You can also change how your line text looks: FIRST WORD only shows the first word of each line. FIRST LETTER shows the first letter of every word in your lines and HIDDEN hides the line completely. If your lines are not fully visible, clicking on the line will reveal the entire line."
      ],
      "id": "77e4f5c9-9e62-4cc7-ba80-45a29253ad10",
      "lang": ""
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "That’s really useful. What other options do I have?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "That’s really useful. What other options do I have?"
      ],
      "id": "38c6d25f-cdfa-4425-b9f8-ccbf249e5310",
      "lang": ""
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "If you only want to run a part of the script, you can add a SECTION from the EDIT menu. We can cover that in another tutorial. You can also set a COUNTDOWN to give yourself time to get ready for the run to start."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "If you only want to run a part of the script, you can add a SECTION from the EDIT menu. We can cover that in another tutorial. You can also set a COUNTDOWN to give yourself time to get ready for the run to start."
      ],
      "id": "a9205dd5-4866-4a51-9e9e-1098f1540676",
      "lang": ""
    },
    {
      "type": "action",
      "texts": [
        "Specific help for Sections and the countdown can be found by clicking the ? when you are editing them."
      ],
      "segments": [
        "Specific help for Sections and the countdown can be found by clicking the ? when you are editing them."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "e7c0f4c2-ed9c-4f44-86fc-bad9d2de6837"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "You also have the option of running the script in different modes."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "You also have the option of running the script in different modes."
      ],
      "id": "3b03066a-ad3a-4f89-939c-b6400a87956d",
      "lang": ""
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "Modes? What do you mean?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "Modes? What do you mean?"
      ],
      "id": "32eef191-7a2f-4136-b556-4a924fa5cdf0",
      "lang": ""
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "There are times when you don’t want to see the entire script but just want to work on your cues and lines. So MyLine includes two other modes: FLASHCARD and VIRTUAL PARTNER.",
        "(pause)",
        "Flashcard mode will show you your cue line. It is not timed and you can click a button to reveal your next word (revealing your line word by word) or reveal your entire line.  Once you have revealed the line, you can redo that line or go on to the next line.",
        "(pause)",
        "Virtual Partner mode is still a work in progress but uses speech recognition to listen for when you have finished saying your line before going on to the next line."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "There are times when you don’t want to see the entire script but just want to work on your cues and lines. So MyLine includes two other modes: FLASHCARD and VIRTUAL PARTNER.",
        "(pause)",
        "Flashcard mode will show you your cue line. It is not timed and you can click a button to reveal your next word (revealing your line word by word) or reveal your entire line.  Once you have revealed the line, you can redo that line or go on to the next line.",
        "(pause)",
        "Virtual Partner mode is still a work in progress but uses speech recognition to listen for when you have finished saying your line before going on to the next line."
      ],
      "id": "478e28dc-76ac-4503-a3b8-af41eca14017",
      "lang": ""
    },
    {
      "type": "action",
      "texts": [
        "To get to the other modes, go to the VIEW menu at the top and click “View as”. You can switch back and forth between script mode and the other modes."
      ],
      "segments": [
        "To get to the other modes, go to the VIEW menu at the top and click “View as”. You can switch back and forth between script mode and the other modes."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "398ac3d0-3355-437e-aba5-3a7ee874d72e"
    }
  ],
  "sections": [],
  "characters": [
    {
      "name": "MY CHARACTER",
      "type": "Me",
      "photo": "",
      "voice": "",
      "eyeline": "",
      "lang": ""
    },
    {
      "name": "MY FRIEND",
      "type": "Them",
      "photo": "",
      "voice": "",
      "eyeline": "",
      "lang": ""
    }
  ],
  "notes": [],
  "videos": [],
  "inCloud": false,
  "updated": 1681310447319,
  "lastOpened": 1681310447728,
  "details": {
    "director": "",
    "production": "",
    "deadline": "",
    "castingDir": "",
    "lang": "",
    "tags": [
      "tutorial"
    ],
    "color": "",
    "description": "This tutorial will show you how to start running your lines. It goes through the different ways you can run lines and work on your scripts!",
    "instructions": "",
    "customFields": ""
  },
  "myCharName": "MY CHARACTER",
  "dialogueIndent": 20
}
// {
//     "title": "Tutorial 2: Running Lines",
//     "created": 1680850374504,
//     "id": "ddadf98e-5e2a-477a-bc26-5e5754b289a8",
//     "rawLines": [],
//     "pageinfo": {
//       "numPages": 1,
//       "x": 600,
//       "y": 1000
//     },
//     "blocks": [
//       {
//         "type": "dialogue",
//         "character": "Me",
//         "charName": "MY CHARACTER",
//         "texts": [
//           "Great! My lines are recorded and I’m ready to start learning my text!",
//           "(pause)",
//           "How do I use the app to do that?"
//         ],
//         "indentpct": 20,
//         "segments": [
//           "MY CHARACTER",
//           "Great! My lines are recorded and I’m ready to start learning my text!",
//           "(pause)",
//           "How do I use the app to do that?"
//         ],
//         "id": "da64c75d-0d61-4da8-87df-1f4185dd03d4",
//         "lang": "en-US"
//       },
//       {
//         "type": "dialogue",
//         "character": "Them",
//         "charName": "YOUR FRIEND",
//         "texts": [
//           "You can start running lines simply by clicking the RUN LINES button on the top right (on desktop) or bottom (mobile)."
//         ],
//         "indentpct": 20,
//         "segments": [
//           "YOUR FRIEND",
//           "You can start running lines simply by clicking the RUN LINES button on the top right (on desktop) or bottom (mobile)."
//         ],
//         "id": "a0cd0002-3262-4bd2-ab40-3cecd6f96fed",
//         "lang": "en-US"
//       },
//       {
//         "type": "action",
//         "texts": [
//           "If there is audio recorded for the line, the app will play that audio. If not, it will use a computer voice to read out the line. You can set each character to have a different voice when you edit that character from the character list."
//         ],
//         "segments": [
//           "If there is audio recorded for the line, the app will play that audio. If not, it will use a computer voice to read out the line. You can set each character to have a different voice when you edit that character from the character list."
//         ],
//         "indentpct": 1,
//         "id": "d7151cd8-9805-421d-9cb2-c6a6f439772a"
//       },
//       {
//         "type": "dialogue",
//         "character": "Them",
//         "charName": "YOUR FRIEND",
//         "texts": [
//           "Open the RUN menu at the top of the page. You can see that there are settings for adjusting what to do with your line audio, your line text, whether to loop, have a countdown and which part of the script to run."
//         ],
//         "indentpct": 20,
//         "segments": [
//           "YOUR FRIEND",
//           "Open the RUN menu at the top of the page. You can see that there are settings for adjusting what to do with your line audio, your line text, whether to loop, have a countdown and which part of the script to run."
//         ],
//         "id": "9f82dfe9-75a3-41ac-93ce-c1470a5aee3b",
//         "lang": "en-US"
//       },
//       {
//         "type": "action",
//         "texts": [
//           "The My Line Audio setting lets you select whether to PLAY your line’s audio, MUTE it (leave a gap for you to speak) or REPEAT AFTER ME (which plays the line, then leaves a gap for you to say it). Just click on the My Line Audio setting to cycle through the three options."
//         ],
//         "segments": [
//           "The My Line Audio setting lets you select whether to PLAY your line’s audio, MUTE it (leave a gap for you to speak) or REPEAT AFTER ME (which plays the line, then leaves a gap for you to say it). Just click on the My Line Audio setting to cycle through the three options."
//         ],
//         "indentpct": 1,
//         "id": "a4e56872-b5c2-4e1f-a734-e54e57a01b17"
//       },
//       {
//         "type": "action",
//         "texts": [
//           "The My Line Text setting adjusts how your line appears on the page. It can either be fully visible, just show the first word of the line, show the first letter of each word of your line or be completely hidden. When your text is not fully visible, clicking on it will toggle from your visibility setting to fully visible and back."
//         ],
//         "segments": [
//           "The My Line Text setting adjusts how your line appears on the page. It can either be fully visible, just show the first word of the line, show the first letter of each word of your line or be completely hidden. When your text is not fully visible, clicking on it will toggle from your visibility setting to fully visible and back."
//         ],
//         "indentpct": 1,
//         "id": "d02b8ca6-ae38-4f9d-8ee8-75804d2fa9fe"
//       },
//       {
//         "type": "action",
//         "texts": [
//           "Looping will simply restart the text once it reaches the end. If you are running a specific section of the script, then the loop will start at the first line of the section and restart when it reaches the end of the section."
//         ],
//         "segments": [
//           "Looping will simply restart the text once it reaches the end. If you are running a specific section of the script, then the loop will start at the first line of the section and restart when it reaches the end of the section."
//         ],
//         "indentpct": 1,
//         "id": "24790fd2-156a-4f50-a67f-2533e8b7b819"
//       },
//       {
//         "type": "action",
//         "texts": [
//           "The countdown counts you in before the line run starts. You can set the number of seconds it counts down as well as which seconds you’d like it to give audio cues on (comma separated). An audio cue on second “0” will say “Go!”. You can also adjust these settings by clicking on the settings on the left navigation menu."
//         ],
//         "segments": [
//           "The countdown counts you in before the line run starts. You can set the number of seconds it counts down as well as which seconds you’d like it to give audio cues on (comma separated). An audio cue on second “0” will say “Go!”. You can also adjust these settings by clicking on the settings on the left navigation menu."
//         ],
//         "indentpct": 1,
//         "id": "de05b8a1-749c-4d00-be31-4772a52a9b16"
//       }
//     ],
//     "sections": [],
//     "characters": [
//       {
//         "name": "MY CHARACTER",
//         "type": "Me",
//         "photo": "",
//         "voice": "",
//         "eyeline": "",
//         "lang": "en-US"
//       },
//       {
//         "name": "YOUR FRIEND",
//         "type": "Them",
//         "photo": "",
//         "voice": "",
//         "eyeline": "",
//         "lang": "en-US"
//       }
//     ],
//     "notes": [],
//     "videos": [],
//     "inCloud": false,
//     "updated": 1680850374504,
//     "lastOpened": 1680850374727,
//     "details": {
//       "director": "",
//       "production": "",
//       "deadline": "",
//       "castingDir": "",
//       "lang": "en-US",
//       "tags": [
//         "tutorial"
//       ],
//       "color": "",
//       "description": "",
//       "instructions": "",
//       "customFields": ""
//     },
//     "myCharName": "MY CHARACTER",
//     "dialogueIndent": 20
//   }