import { db , deUndefine } from '@/firebase/firebaseClient'
import {
    setDoc, 
    addDoc,
    deleteDoc,
    doc, 
    //getDoc, 
    collection, 
    // getDocs, 
    // query, 
    // where, 
    //arrayUnion,
    //arrayRemove 
    updateDoc,
    onSnapshot,
    //arrayUnion,
} from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
const store = {
    state: {
        friends: [],
        shared: [],
        sharedScripts: [],
        requests: [],
        shareUpdated: false,
    },
    getters: {
        getFriends: state => {
            let friends = state.friends.filter(f => f.status != 'unfriended')
            friends.forEach(f => {
                f.scripts = state.shared.filter(s => s.owner_id == f.uid)
            })
            return friends
        },
        getShareUpdated: state => state.shareUpdated,
        getSharedList: state => state.shared,
        getScriptsOwnedBy: state => ownerId => state.shared.filter(s => s.owner_id == ownerId),
        getSharedScriptOptions: state => script_id => state.shared.find(s => s.script_id == script_id),
        getSharedScripts: state => state.sharedScripts,
        getSharedScript: state => id => state.sharedScripts.find(s => s.id == id),
        getRequests: state => state.requests,
        
    },
    mutations: {
        sharedUpdated(state,payload) {
            state.shareUpdated = payload
        },
        addFriendRequest(state,payload) {
            
            const index = state.requests.findIndex(r => r.id == payload.id)
            if (index >= 0) {
                state.requests.splice(index,1,payload)
            } else {
                state.requests.push(payload)
            }
        },
        resetFriendsAndShared(state) {
            state.friends = []
            state.shared = []
            state.requests = []
        }
    },
    actions: {
        saveStatusUpdate(context,payload) {
            let data = deUndefine(payload)
            
            console.log(data)
            setDoc(doc(db,'status_updates',payload.uid),data)
            data.displayName = data.name
            delete data.name
            console.log(data)
            setDoc(doc(db,'users',payload.uid),
                data
            ,{merge: true})
        },
        updateSharedScript(context,payload) {
            
            let uid = context.getters.getUser.uid
            for (var i in payload.shares) {
                if (payload.shares[i].fid == uid) {
                    payload.shares[i].lastUpdate = Date.now()
                }
            }
            
            const opts = context.getters.getSharedScriptOptions(payload.id)
            setDoc(doc(db,'uploads',opts.owner_id,'scripts',payload.id,'shared',opts.fid),{
                lastUpdate: Date.now()
            },{merge: true}
            )
            context.dispatch('saveScriptToCloud',payload)
            setDoc(doc(db,'mail','share_script_updated_' + opts.script_id + '_' + Date.now()),{
                toUids: [opts.owner_id],
                template: {
                    name: 'sharedScriptUpdated',
                    data: {
                        ownerName: opts.ownerName,
                        sharedName: context.getters.getUser.name,
                        title: opts.title,
                        scriptId: opts.script_id,
                    }
                }
            })
        },
            
        async openSharedScript(context,payload) {
            let script = context.getters.getSharedScript(payload)
            console.log('the script',script)
            if (!script) {
                await context.dispatch('loadSharedScript',payload)
                script = context.getters.getSharedScript(payload)
            }
            let uid = context.getters.getUser.uid
            let changed = false
            for (var i in script.shares) {
                if (script.shares[i].fid == uid && script.shares[i].status == 'invited') {
                    script.shares[i].status = 'accepted'
                    changed = true
                }
            }
            if (changed) context.dispatch('saveScriptToCloud',script)
            const opts = context.getters.getSharedScriptOptions(payload)
            setDoc(doc(db,'uploads',opts.owner_id,'scripts',payload,'shared',opts.fid),{
                lastOpened: Date.now()
            },{merge: true}
            )
        },
        revokeRequest(context,payload) {
            updateDoc(doc(db,'friend_requests',payload.id),{status: 'revoked'})
        },
        async doInvite(context,payload) {
            //check that there isn't already a request here:
            if (!(payload.email || payload.to_id)) {
                return
            }
            const user = context.getters.getUser
            const userId = user.uid
            
            let data = {
                from_id: userId,
                from_name: user.displayName,
                from_email: user.email,
                message: payload.message || '',
                to_id: payload.to_id || '',
                to_email: payload.email || '',
                to_name: payload.name || '',
                status: 'pending',
                requested: Date.now(),
                share_script: payload.share_script || {}
            }
            data = deUndefine(data)
            data.id = await addDoc(collection(db, 'friend_requests'),data)
            // console.log('add friend request from doInvite',data)
            // context.commit('addFriendRequest',data)
        },
    
        async friendUpdated(context,payload) {
            const data = await payload.doc.data()
            if (payload.type == 'added' || payload.type == 'modified') {
                let friend = context.state.friends.find(f => f.uid == data.uid)
                if (friend) {
                    Object.assign(friend,data)
                } else {
                    //add an onsnapshot listener for this friend's user 
                    context.state.friends.push(data)
    
                    if (data.status == 'approved') {
                        let listener = onSnapshot(doc(db,'status_updates',data.uid),(snap) => {
                            const friendId = snap.id
                            let friendIndex = context.state.friends.findIndex(f => f.uid == friendId)
                            let friend = context.state.friends[friendIndex]
                            Object.assign(friend,snap.data())
                            context.state.friends.splice(friendIndex,1,friend)
                        })
                        context.commit('addCloudListener',listener)
                    }
                    
                }
            } else if (payload.type == 'removed') {
                const index = context.state.friends.findIndex(f => f.uid == data.uid)
                context.state.friends.splice(index,1)
            }
        },
    
        async friendRequest(context,payload) {
            if (payload.type == 'removed') {
                const index = context.state.requests.findIndex(r => r.id == payload.doc.id)
                context.state.requests.splice(index,1)
            } else {
                const data = {id: payload.doc.id, ...payload.doc.data()}
                console.log('add friend request from friendRequest',data)
                context.commit('addFriendRequest',data)
            }
        },
        async unFriend(context,payload) {
            const myId = context.getters.getUser.uid
            await context.dispatch('unShareAllFromUser',payload.uid)
            updateDoc(doc(db,'users',myId,'friends',payload.uid),{status: 'unfriended',updated: Date.now()})
            updateDoc(doc(db,'users',payload.uid,'friends',myId),{status: 'unfriended',updated: Date.now()})
        },
        async respondToRequest(context,payload) {
            const user = context.getters.getUser
            const friendRequestRef = doc(db,'friend_requests',payload.id)
            let data = deUndefine({
                to_name: user.name,
                to_id: user.uid,
                status: payload.status
            })
            
            updateDoc(friendRequestRef,data)

            if (payload.status == 'accepted') {
                setDoc(doc(db,'mail','req_accepted_' + payload.id),{
                    toUids: [payload.from_id],
                    template: {
                        name: 'requestAccpeted',
                        data: {
                            fromName: payload.from_name,
                            toName: user.name,
                        }
                    }
                })
            }
        },
        handleSharedScriptChange(context,payload) {
            
            const index = context.getters.getSharedScripts.findIndex(s => s.id == payload.id)
            let existingScript = context.getters.getSharedScript(payload.id)
            if (existingScript && existingScript.updated >= payload.updated) {
                console.log('updated script is older than existing shared script')
                return
            }
            payload.blocks.forEach(block => {
                let existingBlock = existingScript?.blocks?.find(b => b.id == block.id)
                if (existingBlock?.audioUpdated >= block.audioUpdated && typeof existingBlock?.audio == 'object') {
                    block.audio = existingBlock.audio
                } else {
                    context.dispatch('downloadAudioForBlock',block)
                }
                
            })
            if (index >= 0) {
                context.state.sharedScripts.splice(index,1,payload)
            } else {
                context.state.sharedScripts.push(payload)
            }
            console.log('finished with saving script change',payload,context.state.sharedScripts)

        },
        async loadSharedScript(context,script_id) {
            let scriptListObj = context.getters.getSharedScriptOptions(script_id)
            if (!scriptListObj) {

                console.log('script list object not foound',script_id)
                return
            }
            console.log('list object',scriptListObj)
            const script = context.getters.getSharedScripts.findIndex(s => s.id == scriptListObj.script_id)
            if (script < 0) {
                const scriptRef = doc(db,'uploads',scriptListObj.owner_id,'scripts',scriptListObj.script_id)
                const sharedScriptListener = onSnapshot(scriptRef,(snapshot) => {
                    let data = snapshot.data()
                    //console.log(data)
                    if (data) {
                        data.owner_id = scriptListObj.owner_id
                        context.dispatch('handleSharedScriptChange',data)
                    }
                },
                (error) => console.log('error getting shared script',error))
                context.commit('addCloudListener',sharedScriptListener)
            } else {
                console.log('goodobye')
            }
            
        },
        //add listeners for script changes, friends and shares
        sharedUpdated(context,payload) {
            let data = payload.doc.data()
            data.id = data.script_id
            const index = context.getters.getSharedList.findIndex(s => s.id == data.id)
            context.commit('sharedUpdated',true)
            switch (payload.type) {
                case 'added':
                case 'modified':
                    
                    if (index >= 0) {
                        context.state.shared.splice(index,1,data)
                    } else {
                        context.state.shared.push(data)
                    }
                    break;

                case 'removed':
                    context.state.shared.splice(index,1)
                    break;

            }
        },
        async removeScriptShares(context,{script,fid}) {
            if (typeof script == 'string') {
                script = context.getters.getScript(script)
            }
            for (var s in script.shares) {
                let share = script.shares[s]
                if (fid == 'all' || fid == share.fid) {
                    context.dispatch('unShareScript',{shareId: s,share})
                }
                
            }
            
        },
        unShareScript(context,{shareId,share}) {
            if (share.fid) {
                deleteDoc(doc(db,'uploads',share.ownerId,'scripts',share.scriptId,'shared',share.fid))
            }
            
            const script = context.getters.getScript(share.scriptId)
            delete script.shares[shareId]

            //notify user?

        },
        async unShareAllFromUser(context,uid) {
            //unshare all of my scripts
            let scripts = context.getters.getScriptsSharedWithUser(uid)
            console.log('shared with them',scripts,uid)
            for (var i in scripts) {
                context.dispatch('removeScriptShares',{script:scripts[i],fid:uid})
                await context.dispatch('saveScriptToCloud',scripts[i])
            }

            //unshare all of their scripts
            scripts = context.getters.getScriptsOwnedBy(uid)
            console.log('shared with me',scripts,uid)
            for (i in scripts) {
                context.dispatch('unShareSharedScript',scripts[i])
            }

            
        },
        async unShareSharedScript(context,share) {
            console.log('unsharesharedscript',share)
            const script = context.getters.getSharedScript(share.script_id)
            for (var i in script.shares) {
                if (script.shares[i].fid == share.fid) {
                    script.shares[i].status = 'finished'
                    break;
                }
            }
            await context.dispatch('saveScriptToCloud',script)
            console.log(share)
            if (share.fid) {
                deleteDoc(doc(db,'uploads',share.owner_id,'scripts',share.script_id,'shared',share.fid))
            }
 
        },

        async shareScript(context,payload) {
            const user = context.getters.getUser
            const userId = user.uid
            const shareId = uuidv4()
            const script = context.getters.getScript(payload.script)
            if (!script.shares) script.shares = {}
            
            //determine if the friend exists or if we need to invite the friend to join
            if (typeof payload.shareWith == 'string') {
                context.dispatch('doInvite',{
                    email: payload.shareWith,
                    share_script: {
                        title: script.title,
                        id: script.id,
                        characters: payload.characters,
                        description: payload.description,
                        message: payload.message,
                        shareId,
                        sharedDate: Date.now(),
                        deadline: payload.deadline
                    },
                    to_name: '',
                })
                script.shares[shareId] = {
                    scriptId: script.id,
                    ownerId: userId,
                    email: payload.shareWith,
                    name: '',
                    characters: payload.characters,
                    description: payload.description,
                    sharedDate: Date.now(),
                    status: 'invited',
                    fid: '',
                    deadline: payload.deadline,

                }
            } else {
                //add a shared_with record
                console.log(script.shares)
                let data = {
                    title: script.title,
                    characters: payload.characters,
                    description: payload.description,
                    ownerName: user.displayName,
                    ownerEmail: user.email,
                    script_id: script.id,
                    fid: payload.shareWith.value, //friendId
                    owner_id: userId,
                    deadline: payload.deadline,
                    shareDate: Date.now()
                }
                console.log('sharing scirpt with values',data)
                data = deUndefine(data)
                setDoc(doc(db,'uploads',userId,'scripts',script.id,'shared',payload.shareWith.value),data)

                script.shares[shareId] = {
                    scriptId: script.id,
                    ownerId: userId,
                    name: payload.shareWith.name,
                    email: payload.shareWith.email,
                    fid: payload.shareWith.value, //friendId
                    characters: payload.characters,
                    description: payload.description,
                    sharedDate: Date.now(),
                    status: 'invited',
                    deadline: payload.deadline
                }
                context.dispatch('saveScriptToCloud',script)

                setDoc(doc(db,'mail','share_' + payload.shareWith.value + '_' + Date.now()),{
                    to: payload.shareWith.email,
                    message: {},
                    attachments: {},
                    template: {
                        name: 'shareScript',
                        data: {
                            fromName: user.displayName,
                            requestId: '',
                            toName: payload.shareWith.name,
                            scriptTitle: script.title,
                            characters: payload.characters,
                            deadline: payload.deadline,
                            description: payload.description,
                        }
                    }
                })
            }

            
            
            
        },
    
    }
    
}

// export the store
export default store