<template>
  <v-app :class="`grey lighten-4`">
    <Navbar  @scroll-to-home="scrollToHomeElement" />
    <v-main>
      <v-container ref="mainContainer" fluid class="px-1">
      <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer
  >
  <v-row
      justify="center"
      no-gutters
    >
    <v-btn x-small text to="/about/tos">Terms of Service</v-btn>
    <v-btn x-small text to="/about/privacy">Privacy Policy</v-btn>
    <v-btn x-small text to="/about/gdpr">GDPR</v-btn>

    </v-row>
  </v-footer>
  </v-app>
</template>
<style>
.offline {
  overscroll-behavior: contain;
}
</style>
<script>
import { getDatabase, onValue, ref, } from 'firebase/database'
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'App',
  components: {
    Navbar
  },
  async mounted() {
    const db = getDatabase()
    const connectionRef = ref(db,'.info/connected')
    
    
    onValue(connectionRef,(snapshot) => {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() == false) {
        window.onbeforeunload = function() {
            return "Dude, are you sure you want to leave? Think of the kittens!";
        }
      } else {
        window.onbeforeunload = function() {
          //
        }
      }
    })
  },
  methods: {
    checksomething() {
      return 'hello'
    },
    scrollToHomeElement(id) {
      console.log('scroll to ',id);
      this.$router.push({ path: '/', hash: `#${id}`});
    }
  },
  watch: {
    offlineclass() {
      if (this.offlineclass == 'offline') {
        document.body.classList.add("offline")
        
      } else {
        document.body.classList.remove("offline")
        
      }
    }
  },
  computed: {
    offlineclass() {
      return this.$store.getters.getOfflineclass
    }
  },
  created() {
        
        
  },
  data () {
    return {
      //
    }
  }
}
</script>

<style scoped>
  .selected {
    background-color: aqua;
  }
  </style>

