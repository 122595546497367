import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { VExpansionPanels } from 'vuetify/lib';

Vue.use(Vuetify,
    {
        components: {
            VExpansionPanels
        }
    });

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#00bcd4',
            secondary: '#3f51b5',
            accent: '#ffc107',
            error: '#e91e63',
            warning: '#ff5722',
            info: '#009688',
            success: '#4caf50'
          },
        },
      },
});
