<template>
<div>
    <v-btn text :to="`/script/${scriptId}`"><v-icon>mdi-chevron-left</v-icon> <v-icon>mdi-script-text</v-icon></v-btn>
    <v-menu :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text
        class="px-1"
        min-width="0"
          v-bind="attrs"
          v-on="on"
        >
        <slot></slot>
        </v-btn>
      </template>
      <v-list dense class="pr-2">
        <div v-for="(item,vm) in runMenu" :key="`rm-${vm}`">
            <v-subheader v-if="item.subheader" v-text="item.subheader"></v-subheader>
            
            <v-list-item v-else class="pa-0" :title="item.title" @click="item.click">
                <v-list-item-avatar class="ma-0">
                    <v-icon>{{item.icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content v-text="item.label"></v-list-item-content>
                <v-list-item-action v-if="item.action" class="ma-0">
                    <v-btn icon @click.stop.prevent="item.action">
                        <v-icon color="grey lighten-1">{{item.actionIcon}}</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </div>
      </v-list>

    </v-menu>
    <v-btn text :disabled="!hasVideos" :to="{name: 'videos',params: {id: scriptId}}">List <v-icon>mdi-filmstrip-box-multiple</v-icon></v-btn>
    <v-btn text :disabled="!hasVideos" :to="{name: 'exports',params: {id: scriptId}}">Export <v-icon>mdi-movie-open-star</v-icon></v-btn>
</div>
</template>

<script>
export default {
    
    name: "TapeMenu",
    props: ['scriptId'],
    computed: {
        hasVideos() {
            return this.$store.getters.getScript(this.scriptId).videos.length > 0
        },
        showDescription() {
            return this.$store.getters.showDescription
        },
        showInstructions() {
            return this.$store.getters.showInstructions
        },

        runMenu() {
            let opts = [
                
                {
                    click: () => this.$store.commit('adjustFlaschardFont','down'),
                    icon: 'mdi-format-font-size-decrease',
                    label: 'Decrease Text Size',
                    title: '',
                },
                {
                    click: () => this.$store.commit('adjustFlaschardFont','up'),
                    icon: 'mdi-format-font-size-increase',
                    label: 'Increase Text Size',
                    title: '',
                },
                {
                    click: () => this.$store.commit('showDescription',!this.showDescription),
                    icon: 'mdi-text',
                    label: (this.showDescription ? 'Hide' : 'Show') + ' description',
                    title: '',
                },
                {
                    click: () => this.$store.commit('showInstructions',!this.showInstructions),
                    icon: 'mdi-format-list-checks',
                    label: (this.showInstructions ? 'Hide' : 'Show') + ' instructions',
                    title: '',
                },
            ]
            
            if (this.scriptId) {
                opts.push({
                    subheader: 'Section to tape'
                })
                let section = this.$store.getters.getLoopSection
                opts.push({
                    click: () => {
                        this.$store.commit('setLoopSection',{scriptId: this.scriptId,index: 'Slate/Intro'})
                    },
                    icon: section?.index == 'Slate/Intro' ? 'mdi-check' : '',
                    label: 'Slate/Intro',
                    title: '',
                })
                opts.push({
                    click: () => {
                        this.$store.commit('setLoopSection',null)
                    },
                    icon: !section ? 'mdi-check' : '',
                    label: 'Whole script',
                    title: '',
                })
                let script = this.$store.getters.getScript(this.scriptId)
                script.sections?.forEach((s) => {
                    opts.push({
                        click: () => {
                            this.$store.commit('setLoopSection',{scriptId: this.scriptId,index: s.index})
                        },
                        icon: section?.index == s.index ? 'mdi-check' : '',
                        label: s.title,
                        title: '',
                    })
                })
            }
            return opts
        },
        
        
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>