<template>
<v-menu :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text
        class="px-1"
        min-width="0"
          v-bind="attrs"
          v-on="on"
        >
        <slot></slot> 
        <v-icon class="ml-1" small v-if="muteMe" title="muted">{{whichMute}}</v-icon>
        <v-icon class="ml-1" small v-if="loop" title="Looping">mdi-sync</v-icon>
        <v-icon class="ml-1" small v-if="hasCountdown" title="Countdown">mdi-timer-outline</v-icon>
        </v-btn>
      </template>
      <v-list dense class="pr-2">
        <div v-for="(item,vm) in runMenu" :key="`rm-${vm}`">
            <v-subheader v-if="item.subheader" v-text="item.subheader"></v-subheader>
            <v-divider v-else-if="item.divider"></v-divider>
            
                <v-menu v-else-if="item.menu" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item v-bind="attrs"
                        v-on="on">
                        <v-list-item-content>{{ item.menu.label }}</v-list-item-content>
                        <v-list-item-action class="ma-0 pa-1">
                    <v-list-item-icon class="ma-0 pa-0 d-flex align-center">
                        <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-icon>

            </v-list-item-action>        
        </v-list-item>
                    </template>
                    <v-list dense class="pr-2">
                        <v-list-item v-for="(menuItem,mIndex) in item.menu.items" :key="`rm-${vm}-${mIndex}`" class="pa-0" @click="item.menu.selectItem(menuItem)">
                            <v-list-item-avatar class="ma-0">
                                <v-icon>mdi-{{ menuItem.icon }}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>{{ menuItem.label }}</v-list-item-content>
                        </v-list-item>
                        
                    </v-list>
                
            </v-menu>
                
        
            <v-list-item v-else class="pa-0" :title="item.title" @click="item.click">
                <v-list-item-avatar class="ma-0">
                    <v-icon>{{item.icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content v-text="item.label"></v-list-item-content>
                <v-list-item-action v-if="item.action" class="ma-0">
                    <v-btn icon @click.stop.prevent="item.action">
                        <v-icon color="grey lighten-1">{{item.actionIcon}}</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </div>
      </v-list>
      <v-dialog v-model="showCountdownEdit" width="400">
        <v-card rounded="lg">
            <v-card-title class="primary">Edit countdown</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-text-field v-model.number="countdown.seconds" label="Countdown" suffix="secs"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Audio cue on" hint="Comma separated list. '0' will cue 'GO!'" v-model="countdown.beeps"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-select label="Format" :items="countdownFormats" v-model="countdown.format"></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    small
                    @click="showCountdownEdit = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    class="success" 
                    text
                    rounded
                    small
                    @click="saveCountdownEdit"
                >
                    Save settings
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </v-menu>
</template>
<style scoped>
.v-subheader {
    height: 20px
}
</style>
<script>

export default {
    name: "RunMenu",
    props: ['scriptId'],
    computed: {
        filterSection() {
            return this.$store.getters.getLoopSection
        },
        playCueAudio() {
            return this.$store.getters.getPlayCueAudio
        },
        runMenu() {
            let opts = []
            if (this.$route.name == 'script') {
                const muteOpts = ['Playing','Repeat after me','Muted']
                opts.push({
                    subheader: 'My Line Audio'
                })
                opts.push({
                    click: () => {
                        this.muteMe++
                        if (this.muteMe > 2) this.muteMe = 0
                        this.saveRunOpts()
                    },
                    icon: this.whichMute,
                    label: muteOpts[this.muteMe],
                    title: '',
                })
            } else {
                opts.push({
                    click: () => this.$store.commit('setPlayCueAudio',!this.playCueAudio),
                    icon: this.playCueAudio ? 'mdi-volume-high' : 'mdi-volume-off',
                    label: this.playCueAudio ? 'playing cue audio' : 'muting cue audio',
                    title: '',
                },)
            }
            
            let visualPrompts = this.$store.getters.getVisualPrompts
            let textLabel = ''
            if (this.hideMyLines !== false) {
                textLabel = visualPrompts[this.hideMyLines].label
            }
            let textItems = []
            visualPrompts.forEach((p,n) => {
                if (this.$route.name != 'script' && n == 3) {
                    console.log('returning ',n)

                    return
                }
                textItems.push({
                    icon: this.hideMyLines == n ? 'check' : '',
                    label: p.label,
                    index: n
                })
            })
            opts = opts.concat([
                {
                    subheader: 'My Line Text'
                },
                {
                    menu: {
                        label: textLabel,
                        icon: 'chevron-right',
                        selectItem: (item) => {
                            this.hideMyLines = item.index
                            this.saveRunOpts()
                        },
                        items: textItems
                    }
                },
                {
                    divider: true
                },
                {
                    click: () => {
                        this.loop = !this.loop
                        this.saveRunOpts()
                    },
                    icon: 'mdi-sync' + (this.loop ? '' : '-off'),
                    label: 'Looping: ' + (this.loop ? 'on' : 'off'),
                    title: '',
                },
                {
                    click: () => {
                        this.hasCountdown = !this.hasCountdown
                        this.saveRunOpts()
                    },
                    icon: 'mdi-timer-' + (this.hasCountdown ? '' : 'off-') + 'outline',
                    label: 'Countdown: ' + (this.hasCountdown ? 'on' : 'off'),
                    title: '',
                    action: () => this.showCountdownEdit = true,
                    actionIcon: 'mdi-cog-outline'
                },
            ])
            
            //add sections from script
            if (this.scriptId) {
                opts.push({
                    subheader: 'Section to run'
                })
                let section = this.$store.getters.getLoopSection
                let menuLabel = 'Whole script'
                let sections = [{
                    icon: !section ? 'check' : '',
                    label: 'Whole script',
                    index: -1
                }]
                let script = this.$store.getters.getScript(this.scriptId)
                script.sections?.forEach((s) => {
                    if (section?.index == s.index) menuLabel = s.title
                    sections.push({
                        icon: section?.index == s.index ? 'check' : '',
                        label: s.title,
                        index: s.index
                    })
                })
                opts.push({
                    menu: {
                        label: menuLabel,
                        items: sections,
                        icon: 'chevron-right',
                        selectItem: (item) => {
                            const action = (item.index == -1) ? null : {scriptId: this.scriptId,index: item.index}
                            this.$store.commit('setLoopSection',action)
                        },
                    }
                    
                })
                
            }
            return opts
        },
        whichMute() {
            const muteIcons = ['mdi-account-voice','mdi-account-convert','mdi-account-voice-off']
            return muteIcons[this.muteMe]
        },
    },
    mounted() {
        const runOpts = this.$store.getters.getSetting('runOpts')
        this.hasCountdown = runOpts.hasCountdown || false
        this.muteMe = runOpts.muteMe || 0
        this.hideMyLines = runOpts.hideMyLines || 0
        this.loop = runOpts.loop || false
    },
    data() {
        return {
            hasCountdown: false,
            muteMe: 0,
            hideMyLines: false,
            loop: false,
            showCountdownEdit: false,
            countdownFormats: ['numbers','dots','movie'],
            countdown: this.$store.getters.getSetting('countdown') || {seconds: 0,beeps: '0',format: 'numbers'},
        }
    },
    methods: {
        saveRunOpts() {
            this.$store.dispatch('saveRunOpts',{
                hasCountdown: this.hasCountdown,
                muteMe: this.muteMe || 0,
                loop: this.loop,
                hideMyLines: this.hideMyLines,
            })
        },
        saveCountdownEdit() {
            this.showCountdownEdit = false
            this.$store.dispatch('saveSettings',{countdown: this.countdown})
        },
    }
}
</script>