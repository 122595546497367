const affirmations = [
    "You got this",
    "You'll figure it out",
    "You're a smart cookie",
    "I believe in you",
    "Sucking at something is the first step towards being good at something",
    "Struggling is part of learning",
    "Everything has cracks - that's how the light gets in",
    "Mistakes don't make you less capable",
    "We are all works in progress",
    "You are a capable human",
    "You know more than you think",
    "10x engineers are a myth",
    "If everything was easy you'd be bored",
    "I admire you for taking this on",
    "You're resourceful and clever",
    "You'll find a way",
    "I know you'll sort it out",
    "Struggling means you're learning",
    "You're doing a great job",
    "It'll feel magical when it's working",
    "I'm rooting for you",
    "Your mind is full of brilliant ideas",
    "You make a difference in the world by simply existing in it",
    "You are learning valuable lessons from yourself every day",
    "You are worthy and deserving of respect",
    "You know more than you knew yesterday",
    "You're an inspiration",
    "Your life is already a miracle of chance waiting for you to shape its destiny",
    "Your life is about to be incredible",
    "Nothing is impossible. The word itself says 'I’m possible!'",
    "Failure is just another way to learn how to do something right",
    "I give myself permission to do what is right for me",
    "You can do it",
    "It is not a sprint, it is a marathon. One step at a time",
    "Success is the progressive realization of a worthy goal",
    "People with goals succeed because they know where they’re going",
    "All you need is the plan, the roadmap, and the courage to press on to your destination",
    "The opposite of courage in our society is not cowardice... it is conformity",
    "Whenever we’re afraid, it’s because we don’t know enough. If we understood enough, we would never be afraid",
    "The past does not equal the future",
    "The path to success is to take massive, determined action",
    "It’s what you practice in private that you will be rewarded for in public",
    "Small progress is still progress",
    "Don't worry if you find flaws in your past creations, it's because you've evolved",
    "Starting is the most difficult step - but you can do it",
    "Don't forget to enjoy the journey",
    "It's not a mistake, it's a learning opportunity",
    "Acting is behaving truthfully under imaginary circumstances. ** Sanford Meisner",
    "An actor is a fool for God. ** Gerardine Clark",
    "Without wonder and insight, acting is just a business. With it, it becomes creation. ** Bette Davis",
    "The best acting is instinctive. It's not intellectual, it's not mechanical, it's instinctive. ** Craig MacDonald",
    "That's what makes acting so attractive. You get to break all your own rules. ** Gerardine Clark",
    "Acting should be bigger than life. Scripts should be bigger than life. It should all be bigger than life. ** Bette Davis",
    "Acting is standing up naked and turning around very slowly. ** Rosalind Russell",
    "Acting is not about being someone different. It's finding the similarity in what is apparently different, then finding myself in there. ** Meryl Streep",
    "Good acting -- real acting is impossible to spot. Do you ever catch talents like Robert Duvall or Kathy Bates acting? No. I defy you to show me where. ** William Esper",
    "Acting is not about being famous, it's about exploring the human soul.**Annette Bening",
    "Acting is a way of living out one's insanity.**Isabelle Huppert",
    "The best way to learn how to act is to learn how to live.**Shirley MacLaine",
    "An actor is at most a poet and at least an entertainer.**Marlon Brando",
    "Acting is not about being someone, it's about becoming someone else entirely.**John Lithgow",
    "Acting is a sport. On stage you must be ready to move like a tennis player on his toes. Your concentration must be keen, your reflexes sharp; your body and mind are in top gear, the chase is on.**David Mamet",
    "Stop explaining yourself. Shut up and act! ** Craig MacDonald",

    "Find in yourself those human things which are universal. ** Sanford Meisner",
    "The more personal, the more universal. ** Gary Ballinger",
    "An actor has to burn inside with an outer ease. ** Michael Chekhov",
    "Use what you know. Don't worry about what you don't know. ** Michael Shurtleff",
    "The actor has to develop his body. The actor has to work on his voice. But the most important thing the actor has to work on is his mind. ** Stella Adler",
"An ounce of behavior is worth a pound of words. ** Sanford Meisner",
"Conflict is what creates drama. The more conflict actors find, the more interesting the performance. ** Michael Shurtleff",
"If you really do want to be an actor who can satisfy himself and his audience, you need to be vulnerable. ** Jack Lemmon",
"Creating relationship is the heart of acting. It is basic. It is essential. ** Michael Shurtleff",
"Listening is not merely hearing. Listening is reacting. Listening is being affected by what you hear. Listening is active. ** Michael Shurtleff",
"Whatever you decide is your motivation in the scene, the opposite of that is also true and should be in it. ** Michael Shurtleff",
"Humor [in a scene] is not jokes. It is that attitude toward being alive without which you would long ago have jumped off the 59th Street Bridge. ** Michael Shurtleff",
"Every scene you will ever act begins in the middle, and it is up to you, the actor, to provide what comes before. ** Michael Shurtleff",

"The first step to a better audition is to give up character and use yourself. ** Michael Shurtleff",
"Competition [in a scene] is healthy. Competition is life. Yet most actors refuse to acknowledge this. They don't want to compete. They want to get along. And they are therefore not first-rate actors. ** Michael Shurtleff",
"Honesty isn't enough for me. That becomes very boring. If you can convince people what you're doing is real and it's also bigger than life - that's exciting. ** Gene Hackman",
"Take nothing for granted. Make an emotional discovery as often as you can find one in every scene. Ask yourself: What is new? ** Michael Shurtleff",
"There's only one reason why a character drinks: to seek confrontation. To fight for what they want in ways normally denied them. ** Michael Shurtleff",
"My job is usually to express emotion as freely as possible. ** Meryl Streep",
"I'm curious about other people. That's the essence of my acting. I'm interested in what it would be like to be you. ** Meryl Streep",
"I think the most liberating thing I did early on was to free myself from any concern with my looks as they pertained to my work. ** Meryl Streep",
"I believe in imagination. I did Kramer vs. Kramer before I had children. But the mother I would be was already inside me. ** Meryl Streep",
"All an actor has is their blind faith that they are who they say they are today, in any scene. ** Meryl Streep",
"The most difficult character in comedy is that of the fool, and he must be no simpleton that plays that part. ** Miguel de Cervantes",
"Actors think more with their hearts than with their heads. ** William Esper",
"Show me a great actor and I'll show you a lousy husband. Show me a great actress, and you've seen the devil. ** W. C. Fields",
"Acting is all about honesty. If you can fake that, you've got it made. ** George Burns",
"The theater has never been any good since the actors became gentlemen. ** W.H. Auden",
"Actors die so loud. ** Henry Miller",
"I love acting. It is so much more real than life. ** Oscar Wilde",
"If I wasn't an actor, I'd be a secret agent. ** Thornton Wilder",
"The word theatre comes from the Greeks. It means the seeing place. It is the place people come to see the truth about life and the social situation. ** Stella Adler",
"I'm a skilled professional actor. Whether or not I've any talent is beside the point. ** Michael Caine",
"Acting in theatre or television or screen is only for the irrecoverably diseased, those so smitten with the need that there is no choice. ** Michael Shurtleff",
"Why, except as a means of livelihood, a man should desire to act on the stage when he has the whole world to act in, is not clear to me. ** George Bernard Shaw",
"There's nothing more boring than unintelligent actors, because all they have to talk about is themselves and acting. There have to be other things. ** Tim Robbins",
"Life beats down and crushes the soul and art reminds you that you have one. ** Stella Adler",
"I think your self emerges more clearly over time. ** Meryl Streep",
"I need to go where people are serious about acting. ** Meryl Streep",
"The work will stand, no matter what. ** Meryl Streep",
"The text is your greatest enemy. ** Sanford Meisner",
"You know, it’s all right to be wrong, but it’s not all right not to try.**Sanford Meisner",
"To be inventive, to have ideas, is an organic part of being talented.**Sanford Meisner",
"Love art in yourself, and not yourself in art.**Stanislavski",
"In the language of an actor, to know is synonymous with to feel**Stanislavski",
"You can kill the King without a sword, and you can light the fire without a match. What needs to burn is your imagination.**Stanislavki",
"The theatre infects the audience with its noble ecstasy.**Stanislavski",
"Create your own method. Don't depend slavishly on mine. Make up something that will work for you! But keep breaking traditions, I beg you.**Stanislavski",
"There are no small parts, only small actors.**Stanislavski",
"Doubt is the enemy of creativeness.**Stanislavksi",
"It is not enough to discover the secret of a play, its thought and feelings—the actor must be able to convert them into living terms.**Stanislavski",
"Talent is nothing but a prolonged period of attention and a shortened period of mental assimilation.**Stanislavski",
"On the stage do not run for the sake of running, or suffer for the sake of suffering. Don’t act “in general”, for the sake of action; always act with a purpose.**Stanislavski",
"The greatest wisdom is to realize one's lack of it.**Stanislavski",
"Acknowledging the good that you already have in your life is the foundation for all abundance.**Eckhart Tolle",
"The primary cause of unhappiness is never the situation but your thoughts about it.**Eckhart Tolle",
"To love is to recognize yourself in another.**Eckhart Tolle",
"Life isn't as serious as the mind makes it out to be.**Eckhart Tolle",
"You can only lose something that you have, but you cannot lose something that you are.**Eckhart Tolle",
"Whatever you fight, you strengthen, and what you resist, persists.**Eckhart Tolle",
"Worry pretends to be necessary but serves no useful purpose.**Eckhart Tolle",
"It is not uncommon for people to spend their whole life waiting to start living.**Eckhart Tolle",
"If you get the inside right, the outside will fall into place. Primary reality is within; secondary reality without.**Eckhart Tolle",
"The past has no power over the present moment.**Eckhart Tolle",
"You find peace not by rearranging the circumstances of your life, but by realizing who you are at the deepest level.**Eckhart Tolle",
"Life is the dancer and you are the dance.**Eckhart Tolle",
"Pleasure is always derived from something outside you, whereas joy arises from within.**Eckhart Tolle",
"All true artists, whether they know it or not, create from a place of no-mind, from inner stillness.**Eckhart Tolle",
"Living up to an image that you have of yourself or that other people have of you is inauthentic living.**Eckhart Tolle",
"Don't let a mad world tell you that success is anything other than a successful present moment.**Eckhart Tolle",
"Awareness is the greatest agent for change.**Eckhart Tolle",
"Being must be felt. It can't be thought.**Eckhart Tolle",
"Only the truth of who you are, if realized, will set you free.**Eckhart Tolle",
"What a caterpillar calls the end of the world we call a butterfly.**Eckhart Tolle",
"Where there is anger there is always pain underneath.**Eckhart Tolle",
"You have so much to learn from your enemies.**Eckhart Tolle",
"Knowing yourself is to be rooted in Being, instead of lost in your mind.**Eckhart Tolle",
"We are born makers. We move what we’re learning from our heads to our hearts through our hands.**Brené Brown",
"Want to be happy? Stop trying to be perfect.**Brené Brown",
"In a society that says, ‘Put yourself last,’ self-love and self-acceptance are revolutionary.**Brené Brown",
"The dark does not destroy the light; it defines it. It’s our fear of the dark that casts our joy into the shadows.**Brené Brown",
"I don’t have to chase extraordinary moments to find happiness—it’s right in front of me if I’m paying attention and practicing gratitude.**Brené Brown",
"If we are brave enough often enough, we will fall. These are the physics of vulnerability.**Brené Brown",
"Talk to yourself like you would to someone you love.**Brené Brown",
"There is no innovation and creativity without failure. Period.**Brené Brown",
"The middle is messy, but it is also where the magic happens.**Brené Brown",
"Connecting the dots of our lives, especially the ones we would rather erase or skip over, requires equal parts self-love and curiosity.**Brené Brown",
"Talk about your failures without apologizing.**Brené Brown",
"Grace means that all of your mistakes now serve a purpose instead of serving shame.**Brené Brown",
"The truth is that falling hurts. The dare is to keep being brave and feel your way back up.**Brené Brown",
"We can choose courage, or we can choose comfort, but we can’t have both. Not at the same time.**Brené Brown",
"Only when we are brave enough to explore the darkness will we discover the infinite power of our light.**Brené Brown",
"Sometimes the bravest and most important thing you can do is just show up.**Brené Brown",
"Imperfections are not inadequacies; they are reminders that we’re all in this together.**Brené Brown",
"Authenticity is the daily practice of letting go of who we think we’re supposed to be and embracing who we are.**Brené Brown",
"Vulnerability is not weakness. And that myth is profoundly dangerous.**Brené Brown",
"Staying vulnerable is a risk we have to take if we want to experience connection.**Brené Brown",
"To be an actor, you have to be a child.**Paul Newman",
];
export default affirmations