
import { getApps, initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";


const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  // measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL
};

let app
if (!getApps.length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps[0]
}
const auth = getAuth(app)
const db = getFirestore(app)
//const db = getFirestore();


if (location.port == '5000' || process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'local') {
  const rtdb = getDatabase()
  const functions = getFunctions(app);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(db, 'localhost', 8082);
  connectAuthEmulator(auth, "http://localhost:9099");
  const storage = getStorage();
  connectStorageEmulator(storage, "localhost", 9199);
  connectDatabaseEmulator(rtdb, "localhost", 9000);
}

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe();
      resolve(user);
    }, reject);
  })
};

const deUndefine = (obj) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value == undefined) {
      obj[key] = null
    } else if (typeof value == 'object') {
      obj[key] = deUndefine(value)
    }
  }
  return obj
}
export { getCurrentUser }

export { auth }

export { db }

export { deUndefine }

export default app