<template>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text
        class="px-1"
        min-width="0"
          v-bind="attrs"
          v-on="on"
        >
        <slot></slot>
        <v-icon class="ml-1" small v-if="filterSection != null" title="Filtered">mdi-filter</v-icon>
        <v-icon class="ml-1" small v-if="!showAction" title="Hidden action">mdi-text-box-remove</v-icon>
        </v-btn>
      </template>
      <v-list dense class="pr-2">
        <v-subheader>View as</v-subheader>
        <v-menu offset-x>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs"
                    v-on="on">
                    <v-list-item-avatar class="ma-0">
                        <v-icon>mdi-{{ selectedMode.icon }}</v-icon>
                    </v-list-item-avatar>
            <v-list-item-content>{{ selectedMode.label }}</v-list-item-content>
            <v-list-item-action class="ma-0 pa-1">
                    <v-list-item-icon class="ma-0 pa-0 d-flex align-center">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                    </v-list-item-action>
                </v-list-item>
                </template>
                        
                            <v-list dense class="pr-2">
                                <v-list-item v-for="(mode,vm) in modes" :key="`modes-${vm}`" class="pa-0" @click="selectMode(mode)">
                                    <v-list-item-avatar class="ma-0">
                                        <v-icon>mdi-{{ mode.icon }}</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>{{ mode.label }}</v-list-item-content>
                                </v-list-item>
                                
                            </v-list>
                        
                    </v-menu>
        <v-divider></v-divider>
        <v-subheader>Font size</v-subheader>
        <v-list-item class="px-1">
            <v-btn icon @click.prevent.stop="adjustFont('down')">
                    <v-icon>mdi-minus</v-icon>
                </v-btn>
            <v-list-item-content class="justify-center">{{ fontSize }}</v-list-item-content>
            <v-btn icon @click.prevent.stop="adjustFont('up')"><v-icon>mdi-plus</v-icon></v-btn>
        </v-list-item>
        
        <v-divider v-if="sections.length"></v-divider>
        <v-subheader v-if="sections.length">sections</v-subheader>
        <v-list-item :class="`pa-0 pl-2 ${filterSection == sect.index ? 'primary lighten-4' : ''}`" v-for="(sect,vm) in sections" :key="`sm-${vm}`" :title="sect.hover" @click="scrollToBlock(sect.index)">
            
            <v-list-item-content v-text="sect.title"></v-list-item-content>
            <v-list-item-action class="ma-0 pa-1" v-if="sect.index >= 0">
                    <v-list-item-icon class="ma-0 pa-0 d-flex align-center">
                        <v-menu offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense class="pr-2">
                                <v-list-item v-if="filterSection != sect.index" class="pa-0" @click="setFilterSection(sect.index)">
                                    <v-list-item-avatar class="ma-0">
                                        <v-icon>mdi-filter</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>Show this section only</v-list-item-content>
                                </v-list-item>
                                <v-list-item v-else class="pa-0" @click="setFilterSection(null)">
                                    <v-list-item-avatar class="ma-0">
                                        <v-icon>mdi-filter-remove</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>Show entire script</v-list-item-content>
                                </v-list-item>
                                <v-list-item class="pa-0" @click="$store.commit('setEditSection',{scriptId,index: sect.index,type:'edit'})">
                                    <v-list-item-avatar class="ma-0">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>Edit Section</v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line class="pa-0" @click="$store.commit('setEditSection',{scriptId,index:sect.index,type:'remove'})">
                                    <v-list-item-avatar class="ma-0">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content><v-list-item-title>Delete Section</v-list-item-title>
                                        <v-list-item-subtitle>(does not remove the actual text)</v-list-item-subtitle>
                                        </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        
                    </v-menu>
                </v-list-item-icon>
            </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="pa-0" v-for="(item,vm) in viewMenu" :key="`vm-${vm}`" :title="item.title" @click="item.click">
            <v-subheader v-if="item.subheader">{{ item.subheader }}</v-subheader>
            <v-list-item-avatar class="ma-0">
                <v-icon>{{item.icon}}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content v-text="item.label"></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
</template>
<style scoped>
.v-subheader {
    height: 20px
}
</style>
<script>

export default {
    name: "ViewMenu",
    props: ['scriptId'],
    computed: {
        selectedMode() {
            return this.modes.find(m => m.goto.name == this.$route.name)
        },
        sections() {
            if (this.$route.name != 'script') return []
            return this.$store.getters.getScript(this.scriptId).sections
        },
        filterSection() {
            return this.$store.getters.filterSection
        }, 
        isMobile() {
            return this.$vuetify.breakpoint.xs
        },
        showAction() {
            return this.$store.getters.showAction
        },
        fontSize() {
            return this.$route.name == 'script' ? this.$store.getters.getScriptFontSize(this.scriptId) : this.$store.getters.getFlashcardFontSize
        },
        showBigButtons() {
            return this.$store.getters.getSetting('runOpts').showBigButtons
        },
        showEditBlocks() {
            let res = this.$store.getters.getShowEditBlocks || false
            return res
        },
        viewMenu() {
            let menu = []
            if (this.$route.name == 'script') {
                menu.push({
                    click: () => this.$store.commit('showAction',!this.showAction),
                    icon: 'mdi-text-box',
                    label: (this.showAction ? 'Hide' : 'Show') + ' action',
                    title: 'Only show dialogue',
                })
            }
              
            return menu
        },
    },
    data() {
        return {
            
            modes: [
                {
                    icon: 'script-text',
                    label: 'Script',
                    goto: {name: 'script',params: {id: this.scriptId}}
                },
                {
                    icon: 'flash',
                    label: 'Flashcards',
                    goto: {name:'flashcard',params: {id: this.scriptId}},
                },
                {
                    icon: 'ear-hearing',
                    label: 'Virtual Partner',
                    goto: {name:'autocue',params: {id: this.scriptId}},
                },
                {
                    icon: 'video-vintage',
                    label: 'Self Tape (beta)',
                    goto: {name: 'tape',params: {id: this.scriptId}},
                },
            ]
        }
    },
    methods: {
        adjustFont(dir) {
            if (this.$route.name == 'script') {
                this.$store.dispatch('adjustScriptFont',{scriptId: this.scriptId,dir})
            } else {
                this.$store.commit('adjustFlaschardFont',dir)
            }
            
        },
        selectMode(mode) {
            if (this.$route.name != mode.goto.name) {
                this.$router.push(mode.goto)
            }
        },
        setFilterSection(n) {
            this.$store.commit('filterSection',n)
            if (n == null) {
                this.$store.commit('setLoopSection',null)
            } else {
                this.$store.commit('setLoopSection',{scriptId: this.scriptId,index: n})
            }
            
        },
        
        scrollToBlock(n) {
            if (n == undefined) return
            if (this.filterSection != null && this.filterSection != n) return
            const container = {
                //container: '#holder',
            }
            
            const targetid = '#section-' + n + '-start'
            this.$vuetify.goTo(targetid,container)
        },
    }
}
</script>