import { auth, db } from '@/firebase/firebaseClient'
import {
    setDoc,
    doc,
    collection,
    getDoc,
    getDocs,
    query,
    where,
    //updateDoc, 
    //query, where,
} from 'firebase/firestore'
import {
    signOut, //getIdToken, getRedirectResult
} from 'firebase/auth'
import tutorial1 from '@/lib/tutorials/tutorial1'
import tutorial2 from '@/lib/tutorials/tutorial2'
import tutorial3 from '@/lib/tutorials/tutorial3'
import tutorial4 from '@/lib/tutorials/tutorial4'
const store = {
    getters: {
        getUser: state => state.user,
        getSubscription: state => state.subscription,
    },
    state: {
        //The user state will initially be null. After login, this state will be updated
        user: null,
        token: null,
        decodedToken: null,
        currentUser: null,
        subscription: null,
    },
    mutations: {
        //Mutation to update the user state
        //Takes in two arguments, the state and the payload. When we call this mutation, the payload will be user object from firebase auth
        //When the user logs out, we call the mutation and the payload will be null
        setUser(state, payload) {
            console.log('setting user mutation', payload)
            state.user = payload
        },
        setCurrentUser(state, payload) {
            console.log(payload.currentUser)
            state.currentUser = payload.currentUser
        },
        setSubscription(state, payload) {
            state.subscription = payload
        }
    },
    actions: {
        // async signup(context, { email, password }){
        //     const response = await createUserWithEmailAndPassword(auth, email, password)
        //     if (response) {
        //         context.commit('setUser', response.user)
        //     } else {
        //         throw new Error('signup failed')
        //     }
        // },

        async setAuthUser(context, payload) {
            context.commit('setUser', payload)
            context.commit('setStorage', payload.uid)
            let response = await context.dispatch('syncUser', payload)
            if (response) {
                console.log('setting user with sync', response)
                context.commit('setUser', response)
            }
            return new Promise((resolve) => resolve(true))
            //Log out the user state
            //state.token = await getIdToken(payload)
        },
        async syncUser(context, authUser) {
            const docSnap = await getDoc(doc(db, "users", authUser.uid));
            const decodedToken = await authUser.getIdTokenResult(true)

            return new Promise((resolve) => {
                if (docSnap.exists()) {

                    authUser.isPremium = decodedToken?.claims?.stripeRole ? true : false;
                    authUser.isAdmin = decodedToken?.claims?.role == 'admin'
                    Object.assign(authUser, docSnap.data());
                    console.log('got user', authUser)
                    resolve(authUser);
                } else {
                    console.log('user doesnt exist')
                    resolve(false);
                }
            })
        },
        // respondToRequest(context,{from_id,status}) {
        //     updateDoc(doc(db,'users',from_id,'friends',context.getters.getUser.uid),{status})
        //     updateDoc(doc(db,'users',context.getters.getUser.uid,'friends',from_id),{status})
        // },
        async login(context, user) {
            const usersRef = collection(db, "users");
            const docRef = doc(usersRef, user.uid);
            let newData = {

            }
            context.dispatch('syncUser', user).then(async (exists) => {
                if (!exists) {
                    console.log('this is a new user')
                    //look up if someone has added this email address as a friend and add the friend details
                    if (!user.referredBy) {
                        const friendRequestRef = collection(db, 'friend_requests')
                        const requestq = query(friendRequestRef, where('to_email', '==', user.email), where('status', '==', 'pending'))
                        const querySnapshot = await getDocs(requestq);
                        let requests = []
                        querySnapshot.forEach((doc) => {
                            requests.push(doc.data())
                        });
                        if (requests.length) {
                            requests.sort((a, b) => a.requested - b.requested)
                            user.referredBy = requests[0].from_email
                        }
                    }
                    context.dispatch('saveScript', tutorial1)
                    context.dispatch('saveScript', tutorial2)
                    context.dispatch('saveScript', tutorial3)
                    context.dispatch('saveScript', tutorial4)

                    //this is a new user
                    newData = {
                        uid: user.uid,
                        email: user.email,
                        name: user.displayName,
                        provider: user.providerData[0].providerId,
                        photoURL: user.photoURL,
                        referredBy: user.referredBy || '',
                    }
                    Object.assign(user, newData)

                    console.log('updating user data', newData)
                    //check to see if there are friend requests for this user...  make sure that this email address is the one that they are 
                    setDoc(docRef, newData, { merge: true })
                }
                context.commit('setUser', user)
            })

        },

        async signout(context) {
            await signOut(auth)
            context.commit('resetState')
            context.commit('resetFriendsAndShared')
            context.commit('setUser', null)
            context.dispatch('unsubscribeFromCloudListeners')
        },
    }
}

// export the store
export default store