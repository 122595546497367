export default {
  "title": "Tutorial 1: Importing Scripts",
  "created": 1681286849256,
  "id": "0931337a-1423-447a-aacb-35b81768e446",
  "rawLines": [],
  "pageinfo": {
    "numPages": 1,
    "x": 600,
    "y": 1000
  },
  "blocks": [
    {
      "type": "action",
      "texts": [
        "INT. YOUR APARTMENT - DAY"
      ],
      "segments": [
        "INT. YOUR APARTMENT - DAY"
      ],
      "indentpct": 1,
      "page": 1,
      "id": "902479ad-c892-4d2f-9db2-ce507ff283b7"
    },
    {
      "type": "action",
      "texts": [
        "You’ve just gotten a script you need to learn for a tape. Your friend introduces you a new app called MyLine. This script is available in PDF, doc and image form so you can look at how each version works."
      ],
      "segments": [
        "You’ve just gotten a script you need to learn for a tape. Your friend introduces you a new app called MyLine. This script is available in PDF, doc and image form so you can look at how each version works."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "f5089440-794e-4ace-8db2-e0f50c2d7803"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "So you’ve just gotten a script and you want to know how to start working with it to learn your lines and get ready for your tape."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "So you’ve just gotten a script and you want to know how to start working with it to learn your lines and get ready for your tape."
      ],
      "id": "f008a621-1b17-4f70-bb28-a953b82077ad",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "That’s right."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "That’s right."
      ],
      "id": "2670e416-5533-4410-b829-1dc0555c0658",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "You can import PDFs and DOC files directly into MyLine. You can even import photos of scripts. And in just a few clicks, you can be running lines."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "You can import PDFs and DOC files directly into MyLine. You can even import photos of scripts. And in just a few clicks, you can be running lines."
      ],
      "id": "a4f7b0c1-fac0-4860-8679-b768edc192b1",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "How do I do that?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "How do I do that?"
      ],
      "id": "af858259-4c1c-4f04-b8c8-7d40588fd8e8",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "Just click on the + new button from the left navigation panel. You can open it by clicking on the three horizontal lines in the upper left corner of your screen"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "Just click on the + new button from the left navigation panel. You can open it by clicking on the three horizontal lines in the upper left corner of your screen"
      ],
      "id": "ef437687-f708-4385-882b-a9377410c75c",
      "lang": "en-US"
    },
    {
      "type": "action",
      "texts": [
        "Click on the + new button. You’ll see an option to choose a script or create your own. We’ll focus on choosing a script for now."
      ],
      "segments": [
        "Click on the + new button. You’ll see an option to choose a script or create your own. We’ll focus on choosing a script for now."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "995c0103-4785-4592-b3c8-45ae52a7932e"
    },
    {
      "type": "action",
      "texts": [
        "Click on the “Choose Script” button. You can choose PDFs, images or .docx files."
      ],
      "segments": [
        "Click on the “Choose Script” button. You can choose PDFs, images or .docx files."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "41c2b7e7-5d5e-4770-8912-cfe3d312332d"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "Ok! I’ve selected my script. Now what do I do? Can I start rehearsing now?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "Ok! I’ve selected my script. Now what do I do? Can I start rehearsing now?"
      ],
      "id": "e57931c8-0fe4-4733-84b9-bb1730d36270",
      "lang": "en-US"
    },
    {
      "type": "action",
      "texts": [
        "Step 2: Choose Your Character"
      ],
      "segments": [
        "Step 2: Choose Your Character"
      ],
      "indentpct": 1,
      "page": 1,
      "id": "f0162084-746b-42d0-9f1d-1d107e0b3623"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "Just a few more steps! First, you have to tell MyLine what character you are. Usually MyLine can figure out potential character names and give them to you in a dropdown."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "Just a few more steps! First, you have to tell MyLine what character you are. Usually MyLine can figure out potential character names and give them to you in a dropdown."
      ],
      "id": "6862f5c4-7c27-4596-8ebd-17d8d1523e88",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "What if my character isn’t in the dropdown?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "What if my character isn’t in the dropdown?"
      ],
      "id": "8251335d-3f01-40e6-94e5-f8d3c2d15c11",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "If your character isn’t in the dropdown, you can force MyLine to recognize your character name by adding it to the “Manually Enter Character Names” box. Then it will be selectable in the dropdown"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "If your character isn’t in the dropdown, you can force MyLine to recognize your character name by adding it to the “Manually Enter Character Names” box. Then it will be selectable in the dropdown"
      ],
      "id": "c7433212-cf47-4b7f-a166-912770f1fd49",
      "lang": "en-US"
    },
    {
      "type": "action",
      "texts": [
        "Select your character (in this script: MY CHARACTER) from the dropdown. You see the script being highlighted with YELLOW (your lines), BLUE (other’s lines) and GRAY (action)"
      ],
      "segments": [
        "Select your character (in this script: MY CHARACTER) from the dropdown. You see the script being highlighted with YELLOW (your lines), BLUE (other’s lines) and GRAY (action)"
      ],
      "indentpct": 1,
      "page": 1,
      "id": "a0d9efd9-2a5e-4e71-8758-7ade09f1154e"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "WOW! That’s really cool. Now what?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "WOW! That’s really cool. Now what?"
      ],
      "id": "3ff8f465-fb52-4500-8b14-f27f04bd5eda",
      "lang": "en-US"
    },
    {
      "type": "action",
      "texts": [
        "Step 3: Pages"
      ],
      "segments": [
        "Step 3: Pages"
      ],
      "indentpct": 1,
      "page": 1,
      "id": "9faccc75-b85d-4f4f-9f53-6683a6276869"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "Now you’re pretty much ready to import your script. When importing a PDF,  on the pages step, you are able to limit which pages are included (you may not want to import an entire script if you’re only in a few scenes). By default, all of the pages are included."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "Now you’re pretty much ready to import your script. When importing a PDF,  on the pages step, you are able to limit which pages are included (you may not want to import an entire script if you’re only in a few scenes). By default, all of the pages are included."
      ],
      "id": "54c33e2a-216e-4e4c-8005-6d3caf4be074",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "What if I’m importing a DOC or scanning a photo?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "What if I’m importing a DOC or scanning a photo?"
      ],
      "id": "007b9dea-a5f4-4647-92ff-46920df67e44",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "When working with a doc or scan, the process is slightly different. You need to make sure that there is a gap between any dialogue and any action that follows it. On the pages step, you can then add more pages (either new doc files or scans)."
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "When working with a doc or scan, the process is slightly different. You need to make sure that there is a gap between any dialogue and any action that follows it. On the pages step, you can then add more pages (either new doc files or scans)."
      ],
      "id": "32390f7b-f8ec-4bc3-b462-b0234415c36d",
      "lang": "en-US"
    },
    {
      "type": "action",
      "texts": [
        "Spend a little while including/excluding pages (in PDF) or adding/removing pages (in Doc/Scan)."
      ],
      "segments": [
        "Spend a little while including/excluding pages (in PDF) or adding/removing pages (in Doc/Scan)."
      ],
      "indentpct": 1,
      "page": 1,
      "id": "85d8069a-dc07-41f9-8952-2e6c7b883324"
    },
    {
      "type": "action",
      "texts": [
        "Step 4: Finishing up"
      ],
      "segments": [
        "Step 4: Finishing up"
      ],
      "indentpct": 1,
      "page": 1,
      "id": "d8d542a4-84ef-4f4d-a095-ad39dd6295cc"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "Ok! I’ve got the pages I want to import, I’ve selected my character! Now what?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "Ok! I’ve got the pages I want to import, I’ve selected my character! Now what?"
      ],
      "id": "46d91f43-7b91-443e-984e-127581f6284d",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "Now all you need to do is make sure the title of your script is correct and click “LET’S GO”. That will import your script and you can go to the next tutorial!"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "Now all you need to do is make sure the title of your script is correct and click “LET’S GO”. That will import your script and you can go to the next tutorial!"
      ],
      "id": "21088525-727a-47c6-9468-7ada05991b1e",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Me",
      "charName": "MY CHARACTER",
      "texts": [
        "What about this cloud checkbox? And the script details?"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY CHARACTER",
        "What about this cloud checkbox? And the script details?"
      ],
      "id": "e358d842-9542-461e-ae67-a1275cfb5c40",
      "lang": "en-US"
    },
    {
      "type": "dialogue",
      "character": "Them",
      "charName": "MY FRIEND",
      "texts": [
        "Click the ? help button next to the cloud checkbox to learn more about it. Script details can be added at this step or after you import your script!"
      ],
      "page": 1,
      "indentpct": 20,
      "segments": [
        "MY FRIEND",
        "Click the ? help button next to the cloud checkbox to learn more about it. Script details can be added at this step or after you import your script!"
      ],
      "id": "112b6752-182b-4edc-9557-bd8aa048d719",
      "lang": "en-US"
    },
    {
      "type": "action",
      "texts": [
        "Make sure the script title is correct (it is up to you what it is!) and click LET’S GO!"
      ],
      "segments": [
        "Make sure the script title is correct (it is up to you what it is!) and click LET’S GO!"
      ],
      "indentpct": 1,
      "page": 1,
      "id": "763f1555-0dfa-4a42-940f-f87a33969240"
    }
  ],
  "sections": [],
  "characters": [
    {
      "name": "MY FRIEND",
      "type": "Them",
      "photo": "",
      "voice": "",
      "eyeline": "",
      "lang": "en-US"
    },
    {
      "name": "MY CHARACTER",
      "type": "Me",
      "photo": "",
      "voice": "",
      "eyeline": "",
      "lang": "en-US"
    }
  ],
  "notes": [],
  "videos": [],
  "inCloud": false,
  "updated": 1681286849256,
  "lastOpened": 1681286849939,
  "details": {
    "director": "",
    "production": "",
    "deadline": "",
    "castingDir": "",
    "lang": "en-US",
    "tags": [
      "tutorial"
    ],
    "color": "",
    "description": "This tutorial walks you through how to import a script using PDF, Word doc or image.",
    "instructions": "",
    "customFields": ""
  },
  "myCharName": "MY CHARACTER",
  "dialogueIndent": 20
}
// {
//     "title": "Tutorial 1: Recording Dialogue",
//     "created": 0,
//     "id": "91fba3cb-ab51-46fc-b99a-32feff5cf3a1",
//     "rawLines": [],
//     "pageinfo": [],
//     "blocks": [
//       {
//         "texts": [
//           "INTRODUCTION - Dialogue, action and recording audio.",
//           "",
//           "Welcome to your first MyLine script! This will walk you through a few of the features to get you started working with the app.",
//           "",
//           "Click the RUN LINES button to get straight into running lines using your device’s computer voice to read out the text. You can choose how to handle your own lines (whether you want them visible or audible) in the RUN menu at the top of this page."
//         ],
//         "id": "94c387eb-362a-4d02-b8d4-786fc133b572",
//         "type": "action",
//         "page": 1,
//         "indentpct": 5,
//         "sectionStart": true
//       },
//       {
//         "type": "dialogue",
//         "character": "Me",
//         "charName": "MY CHARACTER",
//         "texts": [
//           "This is your dialogue. It’s highlighted ",
//           "in your chosen colour (which you can change ",
//           "in the SETTINGS menu in the blue sidepanel)"
//         ],
//         "segments": [],
//         "id": "2b174139-b638-42b0-944e-b66977092efe",
//         "audio": null,
//         "duration": "--:--",
//         "eyeline": "",
//         "indentpct": 20,
//         "lang": "en-US",
//         "sectionStart": true,
//         "waitBeforePlaying": 0,
//         "nextLineOn": "listen"
//       },
//       {
//         "type": "dialogue",
//         "character": "Them",
//         "charName": "OTHER CHAR 1",
//         "texts": [
//           "This is another character’s dialogue ",
//           "and is highlighted differently. ",
//           "These lines can be read aloud either ",
//           "by your device’s voice or by a human (see next line)."
//         ],
//         "segments": [],
//         "id": "e15fecd8-7b93-45d2-a29e-6e5184327389",
//         "audio": null,
//         "audioUpdated": "",
//         "duration": "--:--",
//         "eyeline": "",
//         "indentpct": 20,
//         "lang": "en-US",
//         "voice": "",
//         "waitBeforePlaying": 0,
//         "nextLineOn": "listen"
//       },
//       {
//         "type": "dialogue",
//         "character": "Me",
//         "charName": "MY CHARACTER",
//         "texts": [
//           "You can record the audio for this line ",
//           "by clicking on the red record button. ",
//           "Once a line is recorded, you can check it ",
//           "by clicking the play button."
//         ],
//         "segments": [],
//         "id": "69eae3a8-4f1c-471d-a933-f0e6ed2a4769",
//         "audio": null,
//         "audioUpdated": "",
//         "duration": "--:--",
//         "eyeline": "",
//         "indentpct": 20,
//         "lang": "en-US",
//         "waitBeforePlaying": 0,
//         "nextLineOn": "listen"
//       },
//       {
//         "texts": [
//           "This is an ACTION block for stage directions or notes. ",
//           "You can HIDE or SHOW the action in a script in the VIEW menu at the top of the page. ",
//           ""
//         ],
//         "id": "04cda93b-bfae-4d74-a8dd-7c08805ba683",
//         "type": "action",
//         "indentpct": 5
//       },
//       {
//         "type": "dialogue",
//         "character": "Them",
//         "charName": "OTHER CHAR 1",
//         "texts": [
//           "This line has already been recorded. ",
//           "This is dialogue for a character that is not yours. ",
//           "(pause)",
//           "You can re-record audio for any dialogue block ",
//           "by clicking on its red record button. ",
//           "You may need to wait a few seconds ",
//           "before the mic on your device turns on."
//         ],
//         "segments": [],
//         "id": "098ce53b-7c37-4793-92e2-5f52727906f9",
//         "audio": "tutorial/91fba3cb-ab51-46fc-b99a-32feff5cf3a1/098ce53b-7c37-4793-92e2-5f52727906f9",
//         "audioUpdated": "",
//         "duration": "--:--",
//         "eyeline": "",
//         "indentpct": 20,
//         "lang": "en-US",
//         "voice": "",
//         "waitBeforePlaying": 0,
//         "nextLineOn": "listen"
//       },
//       {
//         "texts": [
//           "You can edit any action block by double clicking on it. ",
//           "",
//           "To delete it, click the trash can icon. ",
//           "",
//           "You can add new action blocks in the EDIT menu. ",
//           ""
//         ],
//         "id": "8f5e6439-50f4-43f1-ae73-32c19bf71a65",
//         "type": "action",
//         "indentpct": 5
//       }
//     ],
//     "sections": [],
//     "characters": [
//       {
//         "name": "MY CHARACTER",
//         "type": "Me",
//         "photo": "",
//         "voice": "",
//         "eyeline": "",
//         "lang": "en-US",
//         "line": " Something else"
//       },
//       {
//         "name": "OTHER CHAR 1",
//         "type": "Them",
//         "photo": "",
//         "voice": "",
//         "eyeline": "",
//         "lang": "en-US",
//         "line": "",
//         "prevName": "OTHER CHARACTER 1"
//       }
//     ],
//     "notes": [],
//     "videos": [],
//     "inCloud": true,
//     "updated": null,
//     "lastOpened": null,
//     "details": {
//       "director": "",
//       "production": "",
//       "deadline": "",
//       "castingDir": "",
//       "lang": "en-US",
//       "tags": [
//         "tutorial"
//       ],
//       "color": "",
//       "description": "",
//       "instructions": "",
//       "customFields": ""
//     },
//     "lastUpload": null,
//     "ownerId": 'tutorial'
//   }