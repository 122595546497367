<template>
    <span>
    <v-menu offset-y>
        
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            text
            min-width="0"
            class="px-1"
            v-bind="attrs"
            v-on="on"
            >
            <slot></slot>
            </v-btn>
        </template>
        <v-list dense class="pr-2">
            <v-list-item class="pa-0" v-for="(item,fm) in fileMenu" :key="`fm-${fm}`" :title="item.title" @click="item.click">
                <v-list-item-avatar class="ma-0">
                    <v-icon>{{item.icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content v-text="item.label"></v-list-item-content>
            </v-list-item>
        </v-list>
        </v-menu>
        
    </span>

</template>

<script>

export default {
    name: "EditMenu",
    props: ['scriptId'],
    components: {
        
    },
    computed: {
        fileMenu() {
            return [
                {
                    click: () => this.$store.commit('setEditSection',{scriptId: this.scriptId,index: -1,type:'edit'}),
                    icon: 'mdi-plus',
                    label: 'Add Section',
                    title: ''
                },
                {
                    click: () => this.$store.commit('setShowAddLines',true),
                    icon: 'mdi-chat-plus',
                    label: 'Add Dialogue',
                    title: 'Add lines to script',
                },
                {
                    click: () => this.$store.commit('setShowAddAction',true),
                    icon: 'mdi-text-box-plus',
                    label: 'Add Action',
                    title: 'Add action to script',
                },
                {
                    click: () => this.openImport(),
                    icon: 'mdi-upload',
                    label: 'Bulk audio import',
                    title: 'Upload / Record audio in bulk',
                },
                
                
            ]
        },
        

    },
    data() {
        return {
            
        }
    },
    methods: {
        
        openImport() {
            this.$store.commit('showImport',true)
            this.$store.commit('setHelpComponent','HelpImportAudio')
        },
        
    }
}
</script>