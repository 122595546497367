import Mp3Encoder from './mp3-encoder'
import WavEncoder from './wav-encoder'
import { convertTimeMMSS } from './utils'

export default class {
  constructor (options = {}) {
    //console.log(options)
    this.beforeRecording = options.beforeRecording
    this.pauseRecording  = options.pauseRecording
    this.afterRecording  = options.afterRecording
    this.micCaptured  = options.micCaptured
    this.micFailed       = options.micFailed
    this.format          = options.format
    this.deviceId       = options.deviceId
    this.gainPreset = options.gain || 1

    this.encoderOptions = {
      bitRate    : options.bitRate,
      sampleRate : options.sampleRate
    }

    this.bufferSize = 4096
    this.records    = []

    this.isPause     = false
    this.isRecording = false
    this.state = 'initialized'

    this.duration = 0
    this.volume   = 0
    this.gainNode = null

    this.wavSamples = []
    this._duration = 0
  }

  start () {
    const constraints = {
      video: false,
      audio: {
        channelCount: 1,
        echoCancellation: false,
        sampleRate: this.encoderOptions.sampleRate,
        deviceId: this.deviceId
      }
    }
    //console.log('constraints',constraints)
    this.state = 'starting'
    this.beforeRecording && this.beforeRecording('start recording')
    //console.log('step 1')
    navigator.mediaDevices
             .getUserMedia(constraints)
             .then(this._micCaptured.bind(this))
             .catch(this._micError.bind(this))

    
  }

  stop () {
    this.stream.getTracks().forEach((track) => track.stop())
    this.input.disconnect()
    this.gainNode.disconnect()
    this.processor.disconnect()
    if (this.context.state != 'closed') this.context.close()

    let record = null

    if (this._isMp3()) {
      record = this.lameEncoder.finish()
    } else {
      let wavEncoder = new WavEncoder({
        bufferSize : this.bufferSize,
        sampleRate : this.encoderOptions.sampleRate,
        samples    : this.wavSamples
      })
      record = wavEncoder.finish()
      this.wavSamples = []
    }

    record.duration = convertTimeMMSS(this.duration)
    this.records.push(record)

    this._duration = 0
    this.duration  = 0

    this.isPause     = false
    //console.log('stopping recording. setting to false')
    if (this.state == 'recording') {
      this.isRecording = false
      this.state = 'stopped'
    }
    this.afterRecording && this.afterRecording(record)
  }

  pause () {
    this.stream.getTracks().forEach((track) => track.stop())
    this.input.disconnect()
    this.gainNode.disconnect()
    this.processor.disconnect()

    this._duration = this.duration
    this.isPause = true

    this.pauseRecording && this.pauseRecording('pause recording')
  }

  recordList () {
    return this.records
  }

  lastRecord () {
    return this.records.slice(-1).pop()
  }
  gain(gainValue) {
    if (!this.gainNode) return -1
    if (gainValue) {
        this.gainNode.gain.value = gainValue //setValueAtTime(gainValue,this.context.currentTime)
    }
    return this.gainNode.gain.value
  }

  _micCaptured (stream) {
    const sampleRate = stream.getAudioTracks()[0].getSettings().sampleRate;
    this.encoderOptions.sampleRate = sampleRate
    this.context    = new(window.AudioContext || window.webkitAudioContext)({sampleRate})
    this.duration   = this._duration
    this.input      = this.context.createMediaStreamSource(stream)
    this.processor  = this.context.createScriptProcessor(this.bufferSize, 1, 1)
    var gainNode = this.context.createGain();
    gainNode.gain.value = this.gainPreset
    this.stream     = stream

    // let analyser = this.context.createAnalyser();
    // analyser.smoothingTimeConstant = 0.8;
    // analyser.fftSize = 1024;

    // this.input.connect(analyser);
    // analyser.connect(this.processor);
    this.processor.onaudioprocess = (ev) => {
      if (this.state == 'starting') {
        this.isRecording = true
        this.state = 'recording'
      } else if (this.state == 'stopped') {
        return
      }
      const sample = ev.inputBuffer.getChannelData(0)
      
      if (this._isMp3()) {
        this.lameEncoder.encode(sample)
      } else {
        this.wavSamples.push(new Float32Array(sample))
      }
      this.duration = parseFloat(this._duration) + parseFloat(this.context.currentTime.toFixed(2))
      this.isPause     = false
      
      
      /** uncomment to use analyser */
      // var array = new Uint8Array(analyser.frequencyBinCount);
      // analyser.getByteFrequencyData(array);
      // var values = 0;

      // var length = array.length;
      // for (var i = 0; i < length; i++) {
      //   values += (array[i]);
      // }

      // var average = values / length;
      //this.volume = average 

      
      /** uncomment to use math */
      let sum = 0.0

      for (let i = 0; i < sample.length; ++i) {
        sum += sample[i] * sample[i]
      }

      this.volume = Math.sqrt(sum / sample.length).toFixed(2) //average 
    }

    this.input.connect(gainNode)
    gainNode.connect(this.processor)
    this.gainNode = gainNode
    this.processor.connect(this.context.destination)

    if (this._isMp3() && !this.lameEncoder) {
      this.lameEncoder = new Mp3Encoder(this.encoderOptions)
    }
    this.micCaptured && this.micCaptured(stream)
    
  }

  _micError (error) {
    this.micFailed && this.micFailed(error)
  }

  _isMp3 () {
    return this.format.toLowerCase() === 'mp3'
  }
}