<template>
    <div class="d-flex align-center">
    <v-btn 
        v-if="buttonText"
        @click="setRecordingLine"
        block
    >   
        {{ buttonText }}
        <v-icon :color="whichColor">{{whichIcon}}</v-icon>
        {{whichText}}
    </v-btn>
    <v-btn 
        v-else
        icon
        v-bind="mySize"
        @click="setRecordingLine"
        :color="whichColor"
    >
        <v-icon :color="whichColor">{{whichIcon}}</v-icon>
        {{whichText}}
    </v-btn>
    <div class="ml-2 pl-2 white"><canvas ref="volume" id="canvas" width="10" height="20"></canvas></div>
    
    </div>

</template>
<script>
// import WaveSurfer from 'wavesurfer.js'
// import * as MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
import Recorder    from '@/lib/recorder'
import { convertTimeMMSS }  from '@/lib/utils'
export default {
    name: "RecordBtn",
    props: {
        line: { type: String },
        time     : { type: Number },
        bitRate    : { type: Number, default: 128   },
        sampleRate : { type: Number, default: 44100 },
        micFailed        : { type: Function },
        beforeRecording  : { type: Function },
        pauseRecording   : { type: Function },
        afterRecording   : { type: Function },
        micCaptured : {type: Function},
        format    : { type: String, default: 'mp3' },
        selectedDeviceId : { type: String, default: undefined},
        gain: {type: Number},
        size: { type: String},
        buttonText: { type: String},
    },
    
    computed: {
        mySize() {
            var answer = {}
            switch (this.size) {
                case 'x-small':
                     answer = {'x-small': true}
                    break;
                case 'small':
                    answer =  {'small': true}
                    break;
                case 'large':
                    answer =  {'large': true}
                    break;
                case 'x-large':
                    answer =  {'x-large': true}
                    break;
            }
            return answer
        },
        recordingTime() {
            if (this.recorder == null) return '--:--'
            return this.recorder.duration
        },  
        recordLine() {
            return this.$store.getters.getRecordingLine
        },
        whichIcon() {
            if (this.recorder == null) return 'mdi-record'
            return this.isRecording && this.hasDuration ? 'mdi-stop' : 'mdi-record'
        },
        whichColor() {
            if (this.recorder == null) return 'red'
            return this.isStandby ? 'orange' : this.isRecording && this.hasDuration ? 'green' : 'red'
        },
        whichText() {
            if (this.isStandby) {
                return 'STBY'
            }
            if (this.isRecording && this.hasDuration) {
                return 'REC'
            }
            return ''
        },
        isPause () {
            if (this.recorder == null) return false
            return this.recorder.isPause
        },
        isRecording () {
            if (this.recorder == null) return false
            //console.log('test is recording',this.recorder.isRecording)
            return this.recorder.isRecording
        },
        hasDuration() {
            if (this.recorder == null) return false
            return this.recorder.duration > 0
        },
        recordedTime () {
            if (this.recorder == null) return 0
            return convertTimeMMSS(this.recorder.duration)
        },
        volume () {
            if (this.recorder == null) return 0
            return parseFloat(this.recorder.volume)
        }
    },
    mounted() {
        
    },
    watch: {
        gain(newVal) {
            this.recorder.gain(parseFloat(newVal) / 10)
        },
        recordingTime(newVal) {
            if (newVal > 0 && !this.startEmitted) {
                this.startEmitted = true
                this.$emit('recordingStarted',true)
                this.isStandby = false
            }
        },
        selectedDeviceId(newVal) {
            this.recorder.deviceId = newVal
        },
        volume(newVal) {
            if (!this.isRecording) {
                return
            }
            // console.log(newVal,'vol')
            newVal *= 2
            let canvasContext = this.$refs.volume.getContext("2d");
            canvasContext.fillStyle = '#FF0000'; //red
            canvasContext.fillRect(0, 0, 10, 4);
            canvasContext.fillStyle = '#FFFF00'; //yellow
            canvasContext.fillRect(0, 4, 10, 4);
            canvasContext.fillStyle = '#00FF00'; //green
            canvasContext.fillRect(0, 8, 10, 12);
            canvasContext.clearRect(0, 0, 10, 20 - (20 * newVal) );
            
            
        },
        recordLine(newVal) {
            //console.log('recording line value changed',newVal,this.recordLine)
            if (newVal == this.line) {
                this.toggleRecorder()
            } else if (this.isRecording) {
                //console.log('stoppinig recorder')
                this.stopRecorder()
            }
        }
    },
    data() {
        return {
            recorder      : null,//this._initRecorder(),
            startEmitted : false,
            wavesurfer : null,
            isStandby: false,

        }
    },
    beforeDestroy () {
      this.stopRecorder()
    },
    methods: {
        setRecordingLine() {
            var id = this.isRecording ? '' : this.line
            this.isStandby = !this.isRecording
            this.$store.commit('setRecordingLine',id)
        },
        toggleRecorder () {
            if (this.recorder == null) {
                this.recorder = this._initRecorder()
            }
            if (!this.isRecording || (this.isRecording && this.isPause)) {
                this.recorder.start()
            } else {
                this.recorder.stop()
            }
        },
        stopRecorder () {
            if (!this.isRecording) {
                return
            }
            let canvasContext = document.getElementById("canvas").getContext("2d");
            canvasContext.clearRect(0, 0, 5, 20);
            
            this.recorder.stop()
            this.startEmitted = false
            this.$emit('recordingStarted',false)
            
        },
      _initRecorder () {
        return new Recorder({
          beforeRecording : this.beforeRecording,
          afterRecording  : this.afterRecording,
          pauseRecording  : this.pauseRecording,
          micFailed       : this.micFailed,
          bitRate         : this.bitRate,
          sampleRate      : this.sampleRate,
          format          : this.format,
          micCaptured :     this.micCaptured,
          gain : this.$store.getters.getSetting('gainValue'),
          deviceId : this.selectedDeviceId
        })
      },
    //   _initWaveform() {

    //         if (this.wavesurfer == null) {
    //             this.wavesurfer = WaveSurfer.create({
    //                 container: '#' + this.mykey,
    //                 waveColor: 'violet',
    //                 progressColor: 'purple',
    //                 height: this.height || 60,
    //                 audioRate: 1,
    //                 plugins: [ MicrophonePlugin.create({
    //                     //maxRegions: 1
    //                 })]
    //             })
    //         }

    //         //this.loadAudio()
            //var _this = this
            // this.wavesurfer.on('finish',function() {
            //     _this.$emit('finishedPlaying',_this.id)
            //     _this.wavesurfer.seekTo(0)
            // })
            // this.wavesurfer.on('region-out',function() {
            //     _this.$emit('finishedPlaying',_this.id)
            //     _this.wavesurfer.seekTo(0)
            // })
            // this.wavesurfer.on('region-created',function() {
            //     if (_this.hasRegion) {
            //         //only allow one region at a time, but create a new one on the new drag
            //         _this.wavesurfer.clearRegions()
            //     }
            //     _this.hasRegion = true
            //     _this.$store.commit('setHasWavesurferRegion',true)
            // })

        //     this.wavesurfer.on('ready',function() {
        //         console.log('wavesurfer ready')
        //         //_this.wavesurfer.enableDragSelection({});
        //     })
        //     this.wavesurfer.on('error',function(e) {
        //         console.log('wavesurfer error',e)
        //     })
        // },
      
        
    }
}
</script>