<template>
<v-card dense class="black--text">
    <v-card-title>Great! You've uploaded the PDF!</v-card-title>
    <v-card-subtitle>To start working with your script, you have to import it first!</v-card-subtitle>
    <v-card-text class="black--text">
        <ol>
            <li>Highlight <u>one</u> of <em>YOUR character's</em> lines.
                <ul>
                    <li>
                        <strong>Drag a box around one of YOUR character's lines (including the character name).</strong>
                    </li>
                </ul>
            </li>
            <li>Make sure the dialogue and action are correctly recognized.</li>
            <li>Add some details to your script (deadline, instructions, role description, casting director, etc)</li>
            <li>Click Import!</li>
        </ol>
    <p></p>
    <p>It doesn't have to be the first line but it should be a "normal" line (as opposed to dual dialogue where it is on the side of the page).</p>
    </v-card-text>
</v-card>
</template>

<script>

export default {
    name: "HelpImport2",
    props: {},
    computed: {
        
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>