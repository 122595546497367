export default 
{
    "title": "Tutorial 4: Editing Scripts",
    "created": 1681310785533,
    "id": "ff6d7333-58c7-4882-8d56-868a9e9ab96c",
    "rawLines": [],
    "pageinfo": {
      "numPages": 1,
      "x": 600,
      "y": 1000
    },
    "blocks": [
      {
        "type": "action",
        "texts": [
          "INT. A DINING ROOM"
        ],
        "segments": [
          "INT. A DINING ROOM"
        ],
        "indentpct": 1,
        "page": 1,
        "id": "d1ad3fda-dd5c-4603-aff7-741a0dfb31ec"
      },
      {
        "type": "action",
        "texts": [
          "Working on your script, you realize that you want to change some of the text. Oh no!"
        ],
        "segments": [
          "Working on your script, you realize that you want to change some of the text. Oh no!"
        ],
        "indentpct": 1,
        "page": 1,
        "id": "e0b9f208-0d08-477c-a3bc-4e220efdb447"
      },
      {
        "type": "dialogue",
        "character": "Me",
        "charName": "MY CHARACTER",
        "texts": [
          "Am I supposed to just live with this text that I’ve imported? Or do I need to import it again?"
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY CHARACTER",
          "Am I supposed to just live with this text that I’ve imported? Or do I need to import it again?"
        ],
        "id": "93fb82d6-4989-4df9-9d76-b4cf84d9db78",
        "lang": ""
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "Of course not! MyLine lets you edit any action or dialogue. The most basic way of editing is to double click on the text you want to edit. Once you click away from the text box, your changes are saved."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "Of course not! MyLine lets you edit any action or dialogue. The most basic way of editing is to double click on the text you want to edit. Once you click away from the text box, your changes are saved."
        ],
        "id": "cbe2b8b7-fca4-484a-b89a-8a26f8932af8",
        "lang": ""
      },
      {
        "type": "action",
        "texts": [
          "Edit this action by double clicking on it. REMOVE THIS TEXT."
        ],
        "segments": [
          "Edit this action by double clicking on it. REMOVE THIS TEXT."
        ],
        "indentpct": 1,
        "page": 1,
        "id": "ff9c7431-32c8-487b-8f70-4038502a0b4b"
      },
      {
        "type": "dialogue",
        "character": "Me",
        "charName": "MY CHARACTER",
        "texts": [
          "Oh that’s cool."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY CHARACTER",
          "Oh that’s cool."
        ],
        "id": "b20e3db3-aa5b-4661-a96f-e3e13ceb9b60",
        "lang": ""
      },
      {
        "type": "action",
        "texts": [
          "Completely delete this action by double clicking on it and clicking the trash icon."
        ],
        "segments": [
          "Completely delete this action by double clicking on it and clicking the trash icon."
        ],
        "indentpct": 1,
        "page": 1,
        "id": "8e8c3032-117c-46ca-8dd2-a8f1c5fc7daf"
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "Editing dialogue is basically the same thing. By double clicking on the line you open the inline edit. Here you can change the character name (for that particular line) or the text."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "Editing dialogue is basically the same thing. By double clicking on the line you open the inline edit. Here you can change the character name (for that particular line) or the text."
        ],
        "id": "b518f69a-0e15-4932-8c28-422c830f6617",
        "lang": ""
      },
      {
        "type": "action",
        "texts": [
          "NOTE: Line breaks from the original script are preserved. Any text in parentheses are treated as parentheticals."
        ],
        "segments": [
          "NOTE: Line breaks from the original script are preserved. Any text in parentheses are treated as parentheticals."
        ],
        "indentpct": 1,
        "page": 1,
        "id": "ce4184af-9ae3-4be1-b5e2-285a1cccc91a"
      },
      {
        "type": "action",
        "texts": [
          "Edit the dialogue above by double clicking on it."
        ],
        "segments": [
          "Edit the dialogue above by double clicking on it."
        ],
        "indentpct": 1,
        "page": 1,
        "id": "00c908b1-1495-4780-a264-647486af1002"
      },
      {
        "type": "dialogue",
        "character": "Me",
        "charName": "MY CHARACTER",
        "texts": [
          "What about adding action or dialogue to a script?"
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY CHARACTER",
          "What about adding action or dialogue to a script?"
        ],
        "id": "f669feb9-8a1f-4d35-b4e8-4629b68c51d9",
        "lang": ""
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "You can add dialogue or action to a script in the EDIT menu at the top of the screen.",
          "(more)",
          "Adding new action is simply putting in the new text and choosing where you want it to be inserted.",
          "(more)",
          "Dialogue is more complicated as you can add multiple lines at once. Check the ? help menu once you click ADD DIALOGUE for more details on how to add dialogue to a script."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "You can add dialogue or action to a script in the EDIT menu at the top of the screen.",
          "(more)",
          "Adding new action is simply putting in the new text and choosing where you want it to be inserted.",
          "(more)",
          "Dialogue is more complicated as you can add multiple lines at once. Check the ? help menu once you click ADD DIALOGUE for more details on how to add dialogue to a script."
        ],
        "id": "c6acf94a-703f-43c5-aff9-49033eebb3fe",
        "lang": ""
      },
      {
        "type": "action",
        "texts": [
          "Try to add some action to the script by clicking EDIT, then “Add action”."
        ],
        "segments": [
          "Try to add some action to the script by clicking EDIT, then “Add action”."
        ],
        "indentpct": 1,
        "page": 1,
        "id": "3f2b772a-d447-41a3-873f-e5804d57c184"
      },
      {
        "type": "action",
        "texts": [
          "Try to add some dialogue to the script by clicking EDIT, then “Add Dialogue”"
        ],
        "segments": [
          "Try to add some dialogue to the script by clicking EDIT, then “Add Dialogue”"
        ],
        "indentpct": 1,
        "page": 1,
        "id": "1fd94bf9-85e6-4565-9886-1d1ec20ad8d3"
      },
      {
        "type": "dialogue",
        "character": "Me",
        "charName": "MY CHARACTER",
        "texts": [
          "But what if I want to change a line from my line to another character’s line?"
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY CHARACTER",
          "But what if I want to change a line from my line to another character’s line?"
        ],
        "id": "b5c3ea4e-e29a-4554-a3b3-39aa5d993d75",
        "lang": ""
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "To get more edit options, click the pencil icon in the inline edit."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "To get more edit options, click the pencil icon in the inline edit."
        ],
        "id": "5dcff7db-cccd-471a-a5c5-174729cddbb8",
        "lang": ""
      },
      {
        "type": "action",
        "texts": [
          "Double click on the line above, then click the pencil icon."
        ],
        "segments": [
          "Double click on the line above, then click the pencil icon."
        ],
        "indentpct": 1,
        "page": 1,
        "id": "29c525cc-73e4-4fe7-84de-7dac084493f9"
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "In addition to the character name and text, every line in the script is assigned to either you (it’s your character) or not you. You can set whether individual lines are assigned to you or not.",
          "(more)",
          "You can also change the language that the line is in, whether to delay playing of the line (when running lines)  as well as delete the line from the script."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "In addition to the character name and text, every line in the script is assigned to either you (it’s your character) or not you. You can set whether individual lines are assigned to you or not.",
          "(more)",
          "You can also change the language that the line is in, whether to delay playing of the line (when running lines)  as well as delete the line from the script."
        ],
        "id": "cd8a9156-64d2-42e4-a894-0eb328302587",
        "lang": ""
      }
    ],
    "sections": [],
    "characters": [
      {
        "name": "MY CHARACTER",
        "type": "Me",
        "photo": "",
        "voice": "",
        "eyeline": "",
        "lang": ""
      },
      {
        "name": "MY FRIEND",
        "type": "Them",
        "photo": "",
        "voice": "",
        "eyeline": "",
        "lang": ""
      }
    ],
    "notes": [],
    "videos": [],
    "inCloud": false,
    "updated": 1681310785533,
    "lastOpened": 1681310785757,
    "details": {
      "director": "",
      "production": "",
      "deadline": "",
      "castingDir": "",
      "lang": "",
      "tags": [
        "tutorial"
      ],
      "color": "",
      "description": "This tutorial walks you through how to add and edit dialogue and action.",
      "instructions": "",
      "customFields": ""
    },
    "myCharName": "MY CHARACTER",
    "dialogueIndent": 20
  }