import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'

import { auth } from "@/firebase/firebaseClient";
import { onAuthStateChanged } from 'firebase/auth'
import 'vue-swatches/dist/vue-swatches.css'
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
Vue.use(VueMeta);

import './registerServiceWorker'

Vue.config.productionTip = false
Vue.filter('deltaSince', function (value) {
  if (!value) return '--'

  let myDate = new Date(value)
  //console.log('mydate',myDate.getDate(),myDate.getMo)
  let nowDate = new Date()
  let timeTo = ''
  if (nowDate.getFullYear() == myDate.getFullYear() && nowDate.getMonth() == myDate.getMonth() && nowDate.getDate() == myDate.getDate()) {
    let minutes = myDate.getMinutes()
    if (minutes < 10) minutes = '0' + minutes
    timeTo = 'Today @ ' + myDate.getHours() + ':' + minutes
  } else {
    timeTo = myDate.getDate() + '/' + (parseInt(myDate.getMonth()) + 1) + '/' + myDate.getFullYear()
  }
  return timeTo

})
Vue.filter('formatDate', function (value) {
  if (!value) return '--'
  const theDate = new Date(value)
  const locale = store.getters.getSetting('defaultLang') || 'en-US'
  return theDate.toLocaleString(locale).replace(/:\d{2}$/, '')
})

Vue.filter('parenthetical', function (value) {
  value = value.split('\n')
  let texts = []
  value.forEach(v => {
    if (v.match(/^\s*\([^)]+\)\s*$/)) {
      texts.push('<span style="margin-left: 5%">' + v + '</span>')
    } else {
      texts.push(v)
    }
  })
  return texts.join('\n')
})

const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
})

onAuthStateChanged(auth, async (user) => {
  if (user) {
    //console.log('setting user on auth state change',user)
    await store.dispatch('setAuthUser', user);
    //console.log('loading data')
    await store.dispatch('load')
  }
  vue.$mount('#app')
});
