<template>
    <span>
    <v-menu offset-y>
        
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            text
            min-width="0"
            class="px-1"
            v-bind="attrs"
            v-on="on"
            >
            <slot></slot>
            </v-btn>
        </template>
        <v-list dense class="pr-2">
            <v-list-item class="pa-0" v-for="(item,fm) in fileMenu" :key="`fm-${fm}`" :title="item.title" @click="item.click">
                <v-list-item-avatar class="ma-0">
                    <v-icon>{{item.icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content v-text="item.label"></v-list-item-content>
            </v-list-item>
        </v-list>
        </v-menu>
        <v-dialog v-model="showArchive" width="500">
            <archive-script :script="toArchive" @close="showArchive = false"></archive-script>
        </v-dialog>
    </span>

</template>

<script>

import ArchiveScript from '@/components/scriptElems/ArchiveScript.vue'
export default {
    name: "ScriptMenu",
    props: ['scriptId'],
    components: {
        ArchiveScript
    },
    computed: {
        fileMenu() {
            return [
                {
                    click: () => this.$store.commit('setEditScriptDetails',true),
                    icon: 'mdi-pencil',
                    label: 'Edit Script Details',
                    title: '',
                },
                {
                    click: this.handleCloud,
                    icon: !this.inCloud ? 'mdi-cloud-upload-outline' : 'mdi-cloud-sync',
                    label: !this.inCloud ? 'Save to cloud' : 'Sync to cloud',
                    title: 'toggle cloud syncing. currently ' +  (this.inCloud ? 'on' : 'off'),
                },
                {
                    click: () => this.$store.commit('showShareScript',true),
                    icon: 'mdi-share-variant-outline',
                    label: 'Share script',
                    title: 'Allow friends to collaborate on script',
                },
                // {
                //     click: () => this.$store.commit('setShowAddLines',true),
                //     icon: 'mdi-chat-plus',
                //     label: 'Add Dialogue',
                //     title: 'Add lines to script',
                // },
                // {
                //     click: () => this.$store.commit('setShowAddAction',true),
                //     icon: 'mdi-text-box-plus',
                //     label: 'Add Action',
                //     title: 'Add action to script',
                // },
                // {
                //     click: () => this.openImport(),
                //     icon: 'mdi-upload',
                //     label: 'Bulk audio import',
                //     title: 'Upload / Record audio in bulk',
                // },
                // {
                //     click: () => this.$router.push({name: 'tape',params: {id: this.scriptId}}),
                //     icon: 'mdi-video-vintage',
                //     label: 'Selftape center',
                //     title: '',
                // },
                {
                    click: () => this.$store.commit('showCharacters',true),
                    icon: 'mdi-account-group',
                    label: 'Character list',
                    title: '',
                },
                {
                    click: this.removeScript,
                    icon: 'mdi-delete',
                    label: 'Archive/Delete',
                    title: '',
                },
                
            ]
        },
        
        inCloud() {
            return this.$store.getters.getScript(this.scriptId)?.inCloud
        }

    },
    data() {
        return {
            showArchive: false,
            toArchive: '',
        }
    },
    methods: {
        removeScript() {
            this.showArchive = true
            this.toArchive = this.scriptId
            // if (confirm('Do you really want to delete this script?')) {
            //     this.$store.dispatch('removeScript',this.scriptId)
            //     this.$router.push({name: 'scripts'})
            // }
        },
        openImport() {
            this.$store.commit('showImport',true)
            this.$store.commit('setHelpComponent','HelpImportAudio')
        },
        handleCloud() {
            if (this.inCloud) {
                this.$store.commit('syncFromCloud',true)
            } else {
                this.$store.dispatch('initialScriptUpload',this.scriptId)
            }
        },
        save() {
            this.$store.commit('saveScriptMenu',{
                doRemoveScript: this.doRemoveScript,
                openImport: this.openImport,
                showShareScript: this.showShareScript,
                showAddLines: this.showAddLines,
                editScript: this.editScript
            })
        },
    }
}
</script>