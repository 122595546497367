<template>
    <nav v-if="routeName != 'InfoPage'">
        <v-app-bar app dense extension-height="30" :collapse="doCollapse">
            <v-app-bar-nav-icon class="grey--text left-nav-button" @click="drawer = !drawer" v-if="userIsLogged"></v-app-bar-nav-icon>
            
            
            <v-toolbar-title>
                <span class="font-weight-light">
                    {{title}}
                </span>
                <v-icon v-if="icon">{{ icon }}</v-icon>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="$vuetify.breakpoint.mdAndUp">
            <v-btn
        v-for="(item, index) in $store.state.homeLinks"
        :key="index"
        text
        @click="$emit('scroll-to-home',item.id)"
      >
        {{ item.title }}
      </v-btn>
    </div>
      <v-menu v-else offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
          
          </v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item
          v-for="(item, index) in $store.state.homeLinks"
            :key="'hamburger-' + index"
            @click="$emit('scroll-to-home',item.id)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
            
            <v-btn icon class="grey--text" v-if="helpComponent" @click="help = !help"><v-icon>mdi-help</v-icon></v-btn>
            <template v-slot:extension v-if="hasMenu">
                <div width="100%" v-if="routeName == 'script'" class="px-0">
                    <script-menu :scriptId="scriptId" id="scriptMenu">Script</script-menu>
                    <edit-menu :scriptId="scriptId" id="editMenu">Edit</edit-menu>
                    <view-menu :scriptId="scriptId" id="viewMenu">View</view-menu>
                    <!-- <section-list :scriptId="scriptId"></section-list> -->
                    <run-menu :scriptId="scriptId" id="runMenu">Options</run-menu>
                </div>
                <div v-else-if="routeName == 'flashcard'">
                    <view-menu :scriptId="scriptId">View</view-menu>
                    <run-menu :scriptId="scriptId">Options</run-menu>
                    <!-- <flashcard-menu :scriptId="scriptId">options</flashcard-menu> -->
                </div>
                <div v-else-if="routeName == 'autocue'">
                    <view-menu :scriptId="scriptId">View</view-menu>
                    <run-menu :scriptId="scriptId">Options</run-menu>
                    <!-- <flashcard-menu :scriptId="scriptId">options</flashcard-menu> -->
                </div>
                <div v-else-if="routeName == 'tape'">
                    <tape-menu :scriptId="scriptId">Options</tape-menu>
                </div>
                <div v-else-if="routeName == 'videos'">
                    <videos-menu :scriptId="scriptId">Options</videos-menu>
                </div>
                <div v-else-if="routeName == 'exports'">
                    <exports-menu :scriptId="scriptId">Options</exports-menu>
                </div>
            </template>
            
        </v-app-bar>
        <v-navigation-drawer v-if="helpComponent" v-model="help" app right>
            <component :is="helpComponent"></component>
        </v-navigation-drawer>
        <v-navigation-drawer v-if="userIsLogged" v-model="drawer" app class="primary">

            <v-list shaped>
                <v-list-item :to="{name: 'scripts'}" exact>
                    <v-list-item-content>
                        <v-list-item-title class="white--text">
                            My Scripts ({{ scriptCount }})
                        </v-list-item-title>

                    </v-list-item-content>
                    <v-list-item-action>
                    <v-btn small color="secondary" rounded to="/new">
                        <v-icon>mdi-plus</v-icon> new
                    </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item two-line v-for="script in scripts" :key="script.id" router :to="{name: 'script',params: {id:script.id}}">
                    <v-list-item-content>
                        <v-list-item-title class="ml-4 white--text">
                            {{ script.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="ml-4 white--text">
                                Last opened: {{ script.lastOpened | deltaSince }}
                        </v-list-item-subtitle>
                    </v-list-item-content>  
                    
                </v-list-item>
                <v-list-item v-if="scripts.length < scriptCount" :to="{name: 'scripts'}" exact>
                    <v-list-item-content>
                        <v-list-item-title class="white--text">
                            ... and {{ scriptCount - scripts.length }} more
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{name: 'scripts',hash: '#shared'}" exact>
                    <v-list-item-content>
                        <v-list-item-title class="white--text">
                            Friend's scripts
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider width="80%" class="mx-auto" color="white"></v-divider>
                <v-list-item :to="{name: 'profile'}" exact>
                    <v-list-item-content>
                        <v-list-item-title class="white--text">
                            Profile
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{name: 'profile',hash: '#friends'}" exact>
                    <v-list-item-content>
                        <v-list-item-title class="white--text">
                            <v-badge v-if="requests.length > 0" color="info" :value="requests.length > 0" :content="requests.length">
                                Friends
                            </v-badge>
                            <span v-else>Friends</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="dialog = true">
                    <v-list-item-content>
                        <v-list-item-title class="white--text">Settings</v-list-item-title>
                        <v-dialog
                        v-model="dialog"
                        width="600">
                            <recording-settings @close="dialog = false"></recording-settings>
                        </v-dialog>
  
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="isAdmin" :to="{name: 'editEmailTemplates'}">
                    <v-list-item-content>
                        <v-list-item-title class="white--text">Emails</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            
            <v-container rounded class="ma-1 pl-2 white--text">
                <v-divider color="white"></v-divider>
                <blockquote><v-icon color="info" x-large style="vertical-align:unset">mdi-format-quote-open</v-icon> {{ affirmation[0] }}</blockquote>
                <div class="text-right" v-if="affirmation[1]"><em>&mdash; {{ affirmation[1] }}</em></div>
            </v-container>
        </v-navigation-drawer>
        
    </nav>
</template>

<script>
import RecordingSettings from "@/components/RecordingSettings.vue";
import ScriptHelp from "./help/ScriptHelp.vue";
import HelpImport2 from "./help/HelpImport2.vue";
import HelpImport3 from "./help/HelpImport3.vue";
import HelpImportAudio from "./help/HelpImportAudio.vue";
import RunMenu from "./menus/RunMenu.vue";
import EditMenu from "./menus/EditMenu.vue";
import ScriptMenu from "./menus/ScriptMenu.vue";
import ViewMenu from "./menus/ViewMenu.vue";
import SectionList from "./menus/SectionList.vue";
import FlashcardMenu from "./menus/FlashcardMenu.vue";
import TapeMenu from "./menus/TapeMenu.vue";
import VideosMenu from "./menus/VideosMenu.vue";
import ExportsMenu from "./menus/ExportsMenu.vue";
import affirmations from "@/lib/quotes";

export default {
  components: { 
    RecordingSettings,
    ScriptHelp,
    HelpImport3,
    HelpImport2, 
    HelpImportAudio,
    RunMenu,
    ScriptMenu,
    ViewMenu,
    SectionList,
    FlashcardMenu,
    TapeMenu,
    VideosMenu,
    ExportsMenu,
    EditMenu,
},
    name: 'navBar',
    data() {
        
        return {
            menuPages: ['script','flashcard','tape','exports','videos','autocue'],
            dialog: false,
            drawer: true,
            help: false,
            doCollapse: false,
            affirmation: '',
            links: [
                //{icon: 'dashboard',test: 'Dashboard',route: '/'},
                //{icon: 'account_balance',test: 'Budgets',route: '/budgets'},
               //{icon: 'receipt',test: 'Invoices',route: '/invoices'},
               //{icon: 'visibility',test: 'Tracker',route: '/tracker'}
                
            ]
        }
    },
    watch: {
        user() {
            if (!this.userIsLogged) 
                this.drawer = false
        },
    },
    mounted() {
       if (!this.userIsLogged || this.mobile) {
        console.log('closing drawer')
        this.drawer = false
       }
       this.setAffirmation()
       setInterval(this.setAffirmation,1000 * 60)
       
    },
    computed: {
        icon() {
            switch (this.routeName) {
                case 'script':
                    return 'mdi-script-text'
                case 'tape':
                    return 'mdi-video-vintage'
                case 'exports':
                    return 'mdi-movie-open-star'
                case 'videos':
                    return 'mdi-filmstrip-box-multiple'
                case 'flashcard':
                    return 'mdi-flash'
                
            }
            return false
        },
        route() {
            return this.$route
        },
        isPlaying() {
            return this.$store.getters.getIsPlaying
        },
        playAllProp() {
            return this.$store.getters.getPlayAll
        },
        scriptId() {
            if (this.menuPages.includes(this.route.name)) {
                return this.route.params.id
            }
            return null
        },
        routeName() {
            return this.route.name
        },
        hasMenu() {
            return this.menuPages.includes(this.routeName)
        },
        isAdmin() {
            return this.$store.getters.getUser.isAdmin
        },
        title() {
            let title = 'MyLine'
             switch(this.routeName) {
                case 'script':
                case 'flashcard':
                case 'tape':
                case 'exports':
                case 'videos':
                case 'autocue':
                    title = this.$store.getters.getScript(this.route.params.id).title
                    break;
                case 'shared':
                    title = this.$store.getters.getSharedScript(this.route.params.shared).title
                    break;
                case 'about':
                    title = 'Import new script'
                    break;
                case 'profile':
                    title = 'My Profile'
                    break;
                case 'scripts':
                    title = 'Script List'
                    break;
             }
             return title
        },
        mobile() {
            return this.$vuetify.breakpoint.sm
        },
        helpComponent() {
            return this.$store.getters.getHelpComponent
        },
        scripts() {
            let scripts = [...this.$store.getters.getScripts.filter(s => !s.isArchived)]
            //console.log(scripts)
            scripts.sort((a,b) => {
                a.lastOpened = a.lastOpened || 0
                b.lastOpened = b.lastOpened || 0
                let res = b.lastOpened - a.lastOpened
                //console.log(a.title,b.title,parseInt(a.lastOpened),parseInt(b.lastOpened))
                return res
            })
            //console.log(scripts)
            return scripts.slice(0,5)
        },
        scriptCount() {
            return this.$store.getters.getScripts.filter(s => !s.isArchived).length
        },
        userIsLogged() {
            return this.user != null;
        },
        user() {
            return this.$store.getters.getUser
        },
        requests() {
            return this.$store.getters.getRequests.filter(r => r.to_email == this.user.email)
        },
        // logs() {
        //     return this.$store.getters.getLogs
        // },
        
    },
    methods: {
        currentUser() {
            this.$store.dispatch('setCurrentUser')
        },
        setAffirmation() {
            this.affirmation = affirmations[Math.floor(Math.random() * affirmations.length)].split('**');
        },
        scrollToHomeElement(id) {

      this.$router.push({ path: '/', hash: '#' + id });
    },
        
    }
}
</script>
