<template>
    <v-row no-gutters>
        <v-col align-self="center" cols="12">
            <v-btn title="play/pause waveform" icon @click="playAudio"><v-icon>mdi-{{isPlaying ? 'pause' : 'play'}}</v-icon></v-btn>
            <v-btn title="stop playing waveform" v-if="isPlaying" icon @click="stopAudio"><v-icon>mdi-stop</v-icon></v-btn>
         
                <v-divider v-if="hasRegion" vertical></v-divider>
                <v-btn title="clear highlight" v-if="hasRegion" icon @click="removeRegion"><v-icon>mdi-close</v-icon></v-btn>
                <v-btn title="save highlight as new audio for line" v-if="hasRegion" icon @click="trimLocalAudio"><v-icon small>mdi-content-cut</v-icon></v-btn>
                <v-divider vertical></v-divider>
                <v-btn icon small title="remove line audio" @click="$emit('removeAudio')"><v-icon>mdi-microphone-minus</v-icon></v-btn>
                <v-btn title="Download MP3" small icon @click.stop.prevent="$emit('downloadAudio')"><v-icon>mdi-download</v-icon></v-btn>
                
        </v-col>
        <v-col dense col="12">
            <div style="border: 1px solid black" :id="mykey"></div>
        </v-col>
    </v-row>
</template>
<script>

import WaveSurfer from 'wavesurfer.js'
import * as RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.js";
import Mp3Encoder from '@/lib/mp3-encoder'
//import WavEncoder from '@/lib/wav-encoder'
export default {
    name: "WaveformElem",
    props: {
        id: {type: String},
        configs: {type: Object},
        seen: {type: Boolean},
        character: {type: String},
        hideMe: {type: Boolean, default: false},
        height: {type: Number, default: 60},
        blobFileURL: {default: ''},
        showControls: {type: Boolean, default: false},
        afterTrim: {default: ''},
        trimConfirmMsg: {type: String, default: 'Are you sure you want to trim this audio? This will replace the current audio and cannot be undone'},
    },
    
    computed: {
        trimToLine() {
            return this.$store.getters.getWavesurferTrimToLine
        },
        isPlaying() {
            if (!this.wavesurfer) return false
            return this.wavesurfer.isPlaying()
        },
        play() {
            return this.$store.getters.getPlaying
        },
        mykey() {
            //console.log('mkey id',this.id)
            if (this.id) {
                return 'waveform-' + this.id.replace(/[^a-zA-Z0-9]/,'')
            } else {
                return 'waveform'
            }
        },
        lineAudio() {
            let audio
            if (this.id) {
                audio = this.$store.getters.getAudio(this.id)
                console.log('this is the audio',audio)
            } else {
                audio = this.blobFileURL
            }
            return audio
        }
    },
    watch: {
        async trimToLine(updateAudioFn) { //updateAudioFn is the updateAudio method from the block
            if (updateAudioFn && this.hasRegion) {
                this.trimAudio().then(mp3 => {
                    //this.$store.dispatch('updateAudio',{line: updateAudioFn,audio: mp3.blob})
                    updateAudioFn(mp3)
                    this.$store.commit('setWavesurferTrimToLine',false)
                })
            }
        },
        // blobFileURL(newVal) {
        //     this.lineAudio = newVal
        //     this.wavesurfer.load(window.URL.createObjectURL(newVal))
        // },
        hideMe(newVal) {
            if (this.character != 'Me') {
                return
            }
            this.wavesurfer.setMute(newVal)
            
        },
        seen(newVal) {
            if (newVal) {
                this.loadAudio()
            }
        },
        lineAudio() {
            //console.log('loadiing audio',this.id)
            this.loadAudio()
        },
        
    },
    mounted() {
        this.initWave()
        //console.log(this.id)
    },
    data: () => ({
        wavesurfer: null,
        localAudio: null,
        localDuration: null,
        hasRegion: false,
        audioBuffer: null,
        audio: null
    }),
    methods: {
        removeRegion() {
            this.wavesurfer.clearRegions()
            this.hasRegion = false
            this.$store.commit('setHasWavesurferRegion',false)
        },
        trimLocalAudio() {
            if (!confirm(this.trimConfirmMsg)) {
                return
            }
            this.trimAudio().then(mp3 => {
                if (this.afterTrim) {
                    this.afterTrim(mp3)
                }
            })
        },
        async trimAudio() {
            // 
            await this.readAndDecodeAudio(this.lineAudio)
            let region = Object.values(this.wavesurfer.regions.list)[0];
            let totalAudioDuration = this.wavesurfer.getDuration()
            let audioBuffer = this.audioBuffer
            //Create empty buffer and then put the slice of audioBuffer i.e wanted part
            //var regionDuration = region.end - region.start;
            var startPoint = Math.floor((region.start*audioBuffer.length)/totalAudioDuration);
            var endPoint = Math.ceil((region.end*audioBuffer.length)/totalAudioDuration);
            var audioLength = endPoint - startPoint;

            var trimmedAudio = new AudioContext().createBuffer(
                audioBuffer.numberOfChannels,
                audioLength,
                audioBuffer.sampleRate
            );

            for(var i=0;i<audioBuffer.numberOfChannels;i++){
                trimmedAudio.copyToChannel(audioBuffer.getChannelData(i).slice(startPoint,endPoint),i);
            }

            var audioData = {
                channels: Array.apply(null,{length: trimmedAudio.numberOfChannels})
                            .map(function(currentElement, index) {
                                return trimmedAudio.getChannelData(index);
                            }),
                sampleRate: trimmedAudio.sampleRate,
                length: trimmedAudio.length,
            }
            let mp3 = this.encodeAudioBuffer(audioData)
            mp3.duration = trimmedAudio.duration.toFixed(2) + ''
            //this.$emit('afterTrim',mp3)
            console.log(mp3)
            return mp3
           //var temp = null;
            // await encodeAudioBufferLame(audioData)
            //     .then((res) => {
            //         console.log(res);
            //         downloadAudio();
            //     })
            //     .catch((c) => {
            //         console.log(c);
            //     });
        },
        encodeAudioBuffer(data) {
           //console.log(data)
        //    bitRate    : { type: Number, default: 128   },
        // sampleRate : { type: Number, default: 44100 },
        
            let mp3enc = new Mp3Encoder({
                bitRate: 128,
                sampleRate: data.sampleRate
            })
            // let wavenc = new WavEncoder({
            //     sampleRate: data.sampleRate,
            //     samples: data
            // })
            mp3enc.encode(data.channels[0])
            let result = mp3enc.finish()
            return result
            // let wav = wavenc.finish()
           
        },
        initWave() {
            if (this.wavesurfer == null) {
                this.wavesurfer = WaveSurfer.create({
                    container: '#' + this.mykey,
                    waveColor: 'violet',
                    progressColor: 'purple',
                    height: this.height || 60,
                    audioRate: 1,
                    plugins: [ RegionsPlugin.create({
                        //maxRegions: 1
                    })]
                })
            }

            this.loadAudio()
            var _this = this
            this.wavesurfer.on('finish',function() {
                _this.$emit('finishedPlaying',_this.id)
                _this.wavesurfer.seekTo(0)
            })
            this.wavesurfer.on('region-out',function() {
                _this.$emit('finishedPlaying',_this.id)
                _this.wavesurfer.seekTo(0)
            })
            this.wavesurfer.on('region-created',function() {
                if (_this.hasRegion) {
                    //only allow one region at a time, but create a new one on the new drag
                    _this.wavesurfer.clearRegions()
                }
                _this.hasRegion = true
                if (!_this.afterTrim)
                    _this.$store.commit('setHasWavesurferRegion',true)
            })

            this.wavesurfer.on('ready',function() {
                console.log('wavesurfer ready')
                _this.wavesurfer.enableDragSelection({});
            })
            this.wavesurfer.on('error',function(e) {
                console.log('wavesurfer error',e)
            })
        },
        
        loadAudio() {
            if (this.lineAudio instanceof Blob && this.wavesurfer instanceof WaveSurfer) {
                console.log('loadiing audio',this.id,this.lineAudio)
                this.wavesurfer.load(window.URL.createObjectURL(this.lineAudio));
                this.removeRegion()
            } else {
                console.log('audio or wavesurfer not correct',this.id,this.lineAudio,this.wavesurfer)
            }
        },
        
        playAudio() {
            if (this.lineAudio instanceof Blob && this.wavesurfer instanceof WaveSurfer) {
                //test to see if there is a region
                
                console.log(Object.keys(this.wavesurfer.regions.list).length)
                if (Object.keys(this.wavesurfer.regions.list).length >  0) {
                    console.log('has region')
                    let region = Object.values(this.wavesurfer.regions.list)[0];
                    region.play();
                } else {
                    this.wavesurfer.playPause()
                }
            } else {
                this.$emit('finishedPlaying',this.id)
            }
        },
        stopAudio() {
            if (this.wavesurfer instanceof WaveSurfer) {
                this.wavesurfer.stop()
            }
        },
        async readAndDecodeAudio(audio) {
            var arrBuffer = null;
            this.audioBuffer = null;
            var _this = this
            //Read the original Audio
            await this.readAudio(audio)
                    .then((results) => {
                        arrBuffer = results.result;
                    })
                    .catch((error) => {
                        window.alert("Some Error occured" + error);
                        return;
                    }); 

            //Decode the original Audio into audioBuffer
            await new AudioContext().decodeAudioData(arrBuffer)
                        .then((res) => {
                            _this.audioBuffer = res;
                            console.log(_this.audioBuffer);
                        })
                        .catch((err) => {
                            window.alert("Can't decode Audio" + err);
                            return;
                        });
        },
        readAudio(audio) {

                return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsArrayBuffer(audio);

                //Resolve if audio gets loaded
                reader.onload = function() {
                    console.log("Audio Loaded");
                    resolve(reader);
                }

                reader.onerror = function(error){
                    console.log("Error while reading audio");
                    reject(error);
                }

                reader.onabort = function(abort){
                    console.log("Aborted");
                    console.log(abort);
                    reject(abort);
                }

            })
        }
    }
}
</script>