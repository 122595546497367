<template>
<v-row no-gutters>
    <v-col cols="12">
        <v-card-title class="pa-0">Save Scripts to Cloud<v-spacer></v-spacer>
            <v-btn icon small @click="showHelp = !showHelp"><v-icon>mdi-help-circle</v-icon></v-btn>
        </v-card-title>
        
        Saving scripts to the cloud lets you open the same script on multiple devices and lets you collaborate with your friends. 
        
        <v-expand-transition>
            <div v-if="showHelp">
                By default, scripts stay on the device they are created on and are not available on other devices. 
                If you select save scripts to the cloud by default, new scripts will be set to save to the cloud.
                You can over-ride this setting on a script-by-script basis.
            </div>
        </v-expand-transition>
        
        <v-switch v-model="defaultCloudSave" label="Save scripts to cloud by default"></v-switch>
    </v-col>
</v-row>
</template>

<script>

export default {
    name: "CloudSetting",
    props: ['noUpdate'],
    mounted() {
        
    },
    watch: {
        defaultCloudSave() {
            if (!this.noUpdate) {
                this.$store.dispatch('saveSettings',{
                    defaultCloudSave: this.defaultCloudSave,
                })
            }
        },
    },
    components: {
        
    },
    computed: {
        
    },
    data() {
        return {
            showHelp: false,
            defaultCloudSave: this.$store.getters.getSetting('defaultCloudSave') || false,
        }
    },
    methods: {
        
    }
}
</script>