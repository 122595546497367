export default 
{
    "title": "Tutorial 3: Working with audio",
    "created": 1681310638645,
    "id": "dae9bb92-3e7d-4986-b401-5ef4753b9722",
    "rawLines": [],
    "pageinfo": {
      "numPages": 1,
      "x": 600,
      "y": 1000
    },
    "blocks": [
      {
        "type": "action",
        "texts": [
          "INT. KITCHEN"
        ],
        "segments": [
          "INT. KITCHEN"
        ],
        "indentpct": 1,
        "page": 1,
        "id": "a79fdb3f-b245-4fdf-94ef-e6a51775b30f"
      },
      {
        "type": "dialogue",
        "character": "Me",
        "charName": "MY CHARACTER",
        "texts": [
          "That’s cool that I can start running scripts right away, but I’d rather have a real person say the other characters’ lines, not this computer voice!"
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY CHARACTER",
          "That’s cool that I can start running scripts right away, but I’d rather have a real person say the other characters’ lines, not this computer voice!"
        ],
        "id": "b1ec4370-27d9-478f-a5f9-02186b0e1ac9",
        "lang": ""
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "I totally get it! The easiest way to add audio to a script is to click the red record button on each dialogue block. The first time, you’ll have to allow MyLine to access your microphone."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "I totally get it! The easiest way to add audio to a script is to click the red record button on each dialogue block. The first time, you’ll have to allow MyLine to access your microphone."
        ],
        "id": "a0b1ce92-b709-4fd0-888b-4e61ee1d2c73",
        "lang": ""
      },
      {
        "type": "action",
        "texts": [
          "Click the record button on a line and record some audio"
        ],
        "segments": [
          "Click the record button on a line and record some audio"
        ],
        "indentpct": 1,
        "page": 1,
        "id": "69ea0700-739d-457b-a49d-037d82d21b2d"
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "Once you record some audio, you can listen back to it by clicking the blue PLAY button on that line."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "Once you record some audio, you can listen back to it by clicking the blue PLAY button on that line."
        ],
        "id": "92ba2745-b955-4f6d-a656-dc47b68043c3",
        "lang": ""
      },
      {
        "type": "dialogue",
        "character": "Me",
        "charName": "MY CHARACTER",
        "texts": [
          "What if I mess up?"
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY CHARACTER",
          "What if I mess up?"
        ],
        "id": "9f8f36b5-9d47-4044-897a-51398e25e2fc",
        "lang": ""
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "You can re-record the line as much as you want in order to get it right! Any line that has a recording will play that recording (rather than the computer generated voice)"
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "You can re-record the line as much as you want in order to get it right! Any line that has a recording will play that recording (rather than the computer generated voice)"
        ],
        "id": "c258a1fa-3ba9-42bb-9b43-2eb3d9a47c7b",
        "lang": ""
      },
      {
        "type": "action",
        "texts": [
          "Record the line again."
        ],
        "segments": [
          "Record the line again."
        ],
        "indentpct": 1,
        "page": 1,
        "id": "acea18d4-e412-4b10-9e70-e33d80c47ac1"
      },
      {
        "type": "dialogue",
        "character": "Me",
        "charName": "MY CHARACTER",
        "texts": [
          "What about trimming or removing the audio from the line once I’ve recorded it?"
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY CHARACTER",
          "What about trimming or removing the audio from the line once I’ve recorded it?"
        ],
        "id": "44d1db13-db29-4f1f-ac60-682ccddd76f8",
        "lang": ""
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "You can trim audio by double clicking on the line you’d like to work with, then clicking on the wave-form icon.",
          "(more)",
          "This opens up the wave-form editor. To trim audio, drag on the waveform to select the portion of the audio you want to keep. Click the scissors icon to trim away any audio that is not selected. Click the X icon to remove the selection.",
          "(more)",
          "In the audio edit, you can also completely remove the audio or download an mp3 of that line."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "You can trim audio by double clicking on the line you’d like to work with, then clicking on the wave-form icon.",
          "(more)",
          "This opens up the wave-form editor. To trim audio, drag on the waveform to select the portion of the audio you want to keep. Click the scissors icon to trim away any audio that is not selected. Click the X icon to remove the selection.",
          "(more)",
          "In the audio edit, you can also completely remove the audio or download an mp3 of that line."
        ],
        "id": "2baf595f-cab2-494e-8dbf-bace8a46fe63",
        "lang": ""
      },
      {
        "type": "action",
        "texts": [
          "Double click on a line where you have recorded audio. Then click the wave-form icon. Select a portion of the wave-form and click the scissors icon."
        ],
        "segments": [
          "Double click on a line where you have recorded audio. Then click the wave-form icon. Select a portion of the wave-form and click the scissors icon."
        ],
        "indentpct": 1,
        "page": 1,
        "id": "2881526d-d688-47e6-90af-82c9679f2c25"
      },
      {
        "type": "dialogue",
        "character": "Me",
        "charName": "MY CHARACTER",
        "texts": [
          "What if I want to record all the lines at once?"
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY CHARACTER",
          "What if I want to record all the lines at once?"
        ],
        "id": "3afc4383-9fec-4fcc-9f36-acc3c46f8657",
        "lang": ""
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "You can record multiple lines (or import a recording you made outside of the app) by going to the EDIT menu at the top of the screen and selecting “Bulk Import Audio”",
          "(more)",
          "Once you record or upload your audio, select the portion you want to assign to a particular line and click the plus icon that appears next to the line."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "You can record multiple lines (or import a recording you made outside of the app) by going to the EDIT menu at the top of the screen and selecting “Bulk Import Audio”",
          "(more)",
          "Once you record or upload your audio, select the portion you want to assign to a particular line and click the plus icon that appears next to the line."
        ],
        "id": "f167109a-64b8-47ff-aaba-d330ca93f0aa",
        "lang": ""
      },
      {
        "type": "dialogue",
        "character": "Me",
        "charName": "MY CHARACTER",
        "texts": [
          "But what if I want it to be not just my voice?"
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY CHARACTER",
          "But what if I want it to be not just my voice?"
        ],
        "id": "03d4e82a-bf87-47c0-bd2f-ea90b5bd8434",
        "lang": ""
      },
      {
        "type": "dialogue",
        "character": "Them",
        "charName": "MY FRIEND",
        "texts": [
          "Glad you asked! MyLine was built for collaborating with your friends. You can find more about how to have your friends collaborate on your scripts in Tutorial 5."
        ],
        "page": 1,
        "indentpct": 20,
        "segments": [
          "MY FRIEND",
          "Glad you asked! MyLine was built for collaborating with your friends. You can find more about how to have your friends collaborate on your scripts in Tutorial 5."
        ],
        "id": "77790234-1693-4062-a526-5a0ef54ad876",
        "lang": ""
      }
    ],
    "sections": [],
    "characters": [
      {
        "name": "MY CHARACTER",
        "type": "Me",
        "photo": "",
        "voice": "",
        "eyeline": "",
        "lang": ""
      },
      {
        "name": "MY FRIEND",
        "type": "Them",
        "photo": "",
        "voice": "",
        "eyeline": "",
        "lang": ""
      }
    ],
    "notes": [],
    "videos": [],
    "inCloud": false,
    "updated": 1681310638645,
    "lastOpened": 1681310638871,
    "details": {
      "director": "",
      "production": "",
      "deadline": "",
      "castingDir": "",
      "lang": "",
      "tags": [
        "tutorial"
      ],
      "color": "",
      "description": "This tutorial will get you used to recording and working with audio for your lines. It makes a big difference listening to a human voice rather than the computer generated one!",
      "instructions": "",
      "customFields": ""
    },
    "myCharName": "MY CHARACTER",
    "dialogueIndent": 20
  }
//   {
//     "title": "Tutorial 3: Collaborating",
//     "created": 1680850203474,
//     "id": "64a55681-e656-4513-b6b1-31a62eceb15d",
//     "rawLines": [],
//     "pageinfo": {
//       "numPages": 1,
//       "x": 600,
//       "y": 1000
//     },
//     "blocks": [
//       {
//         "type": "action",
//         "texts": [
//           "ADDING BULK AUDIO & COLLABORATING WITH FRIENDS"
//         ],
//         "segments": [
//           "ADDING BULK AUDIO & COLLABORATING WITH FRIENDS"
//         ],
//         "indentpct": 1,
//         "id": "7c3600ad-20cf-4e3f-b788-a4da2dc05cc2"
//       },
//       {
//         "type": "dialogue",
//         "character": "Me",
//         "charName": "MY CHARACTER",
//         "texts": [
//           "But I don’t just want to listen to my own voice! How do I get my friends involved?"
//         ],
//         "indentpct": 20,
//         "segments": [
//           "MY CHARACTER",
//           "But I don’t just want to listen to my own voice! How do I get my friends involved?"
//         ],
//         "id": "64316364-8677-4e3b-99a9-e969bb5b9792",
//         "lang": "en-US"
//       },
//       {
//         "type": "dialogue",
//         "character": "Them",
//         "charName": "YOUR FRIEND",
//         "texts": [
//           "There are a few ways of getting audio from your friends into your script."
//         ],
//         "indentpct": 20,
//         "segments": [
//           "YOUR FRIEND",
//           "There are a few ways of getting audio from your friends into your script."
//         ],
//         "id": "8991aa68-59e0-4877-9713-3ca897e7fd89",
//         "lang": "en-US"
//       },
//       {
//         "type": "dialogue",
//         "character": "Them",
//         "charName": "YOUR OTHER FRIEND",
//         "texts": [
//           "The easiest way is to share your script with them and have them record dialogue right into your script."
//         ],
//         "indentpct": 20,
//         "segments": [
//           "YOUR OTHER FRIEND",
//           "The easiest way is to share your script with them and have them record dialogue right into your script."
//         ],
//         "id": "4be6c35a-c11a-49ce-837c-c7bc94403ca1",
//         "lang": "en-US"
//       },
//       {
//         "type": "dialogue",
//         "character": "Them",
//         "charName": "YOUR FRIEND",
//         "texts": [
//           "Open the SHARE SCRIPT box from the SCRIPT menu at the top of the page. Enter an email address in the SHARE WITH box. Select which character you want your friend to read. You can also include a deadline (so your friend knows they can’t take forever to get it back to you), a character description and a personal message. Then click share!"
//         ],
//         "indentpct": 20,
//         "segments": [
//           "YOUR FRIEND",
//           "Open the SHARE SCRIPT box from the SCRIPT menu at the top of the page. Enter an email address in the SHARE WITH box. Select which character you want your friend to read. You can also include a deadline (so your friend knows they can’t take forever to get it back to you), a character description and a personal message. Then click share!"
//         ],
//         "id": "43d2b20e-c861-4fb0-bfc6-32a733515d80",
//         "lang": "en-US"
//       },
//       {
//         "type": "dialogue",
//         "character": "Them",
//         "charName": "YOUR OTHER FRIEND",
//         "texts": [
//           "If you’ve already connected with friends on the app, they will show up as options in the SHARE WITH box. But you can always enter a different email."
//         ],
//         "indentpct": 20,
//         "segments": [
//           "YOUR OTHER FRIEND",
//           "If you’ve already connected with friends on the app, they will show up as options in the SHARE WITH box. But you can always enter a different email."
//         ],
//         "id": "5f95cde1-071c-46fa-92be-bcf9a1df1226",
//         "lang": "en-US"
//       },
//       {
//         "type": "action",
//         "texts": [
//           "Your friend will receive an email with the details you entered in the SHARE SCRIPT box along with instructions on how to accept the share request. Your friend will need to log in to MyLine and accept your friend request before they can help you with your script."
//         ],
//         "segments": [
//           "Your friend will receive an email with the details you entered in the SHARE SCRIPT box along with instructions on how to accept the share request. Your friend will need to log in to MyLine and accept your friend request before they can help you with your script."
//         ],
//         "indentpct": 1,
//         "id": "f0ad27a0-fd9e-4899-b6cf-b45d9e48a2d0"
//       },
//       {
//         "type": "action",
//         "texts": [
//           "Your friend will be able to see your entire script but can only record the lines for the characters you’ve shared (and they can’t edit any of the text). Once they record their dialogue, you will receive an email that they’ve updated their lines. You can also see whether they have opened the script as their status will change from “invited” to “accepted”."
//         ],
//         "segments": [
//           "Your friend will be able to see your entire script but can only record the lines for the characters you’ve shared (and they can’t edit any of the text). Once they record their dialogue, you will receive an email that they’ve updated their lines. You can also see whether they have opened the script as their status will change from “invited” to “accepted”."
//         ],
//         "indentpct": 1,
//         "id": "ba3ce4d0-ecec-4580-b90a-a106410afbbf"
//       },
//       {
//         "type": "action",
//         "texts": [
//           "You can stop sharing your script with them at any time (any audio they upload will stay in the script)."
//         ],
//         "segments": [
//           "You can stop sharing your script with them at any time (any audio they upload will stay in the script)."
//         ],
//         "indentpct": 1,
//         "id": "d721fac8-7372-42cb-a18b-8e875e6235d6"
//       },
//       {
//         "type": "dialogue",
//         "character": "Them",
//         "charName": "YOUR FRIEND",
//         "texts": [
//           "You can also upload or record audio for multiple lines by clicking on the BULK AUDIO option in the EDIT menu at the top of the page."
//         ],
//         "indentpct": 20,
//         "segments": [
//           "YOUR FRIEND",
//           "You can also upload or record audio for multiple lines by clicking on the BULK AUDIO option in the EDIT menu at the top of the page."
//         ],
//         "id": "2415d991-7270-465c-a2bb-9243e6203952",
//         "lang": "en-US"
//       },
//       {
//         "type": "dialogue",
//         "character": "Them",
//         "charName": "YOUR OTHER FRIEND",
//         "texts": [
//           "This will allow you to record your dialogue (or take an mp3 from another source) in one go. Then you can highlight the portion of audio you want to assign to a specific line."
//         ],
//         "indentpct": 20,
//         "segments": [
//           "YOUR OTHER FRIEND",
//           "This will allow you to record your dialogue (or take an mp3 from another source) in one go. Then you can highlight the portion of audio you want to assign to a specific line."
//         ],
//         "id": "5d0cbd99-bf83-4d54-9a07-5d18d766c4c9",
//         "lang": "en-US"
//       }
//     ],
//     "sections": [],
//     "characters": [
//       {
//         "name": "MY CHARACTER",
//         "type": "Me",
//         "photo": "",
//         "voice": "",
//         "eyeline": "",
//         "lang": "en-US"
//       },
//       {
//         "name": "YOUR FRIEND",
//         "type": "Them",
//         "photo": "",
//         "voice": "",
//         "eyeline": "",
//         "lang": "en-US"
//       },
//       {
//         "name": "YOUR OTHER FRIEND",
//         "type": "Them",
//         "photo": "",
//         "voice": "",
//         "eyeline": "",
//         "lang": "en-US"
//       }
//     ],
//     "notes": [],
//     "videos": [],
//     "inCloud": false,
//     "updated": 1680850203477,
//     "lastOpened": 1680850203822,
//     "details": {
//       "director": "",
//       "production": "",
//       "deadline": "",
//       "castingDir": "",
//       "lang": "en-US",
//       "tags": [
//         "tutorial"
//       ],
//       "color": "",
//       "description": "",
//       "instructions": "",
//       "customFields": ""
//     },
//     "myCharName": "MY CHARACTER",
//     "dialogueIndent": 20
//   }