import Vue from 'vue'
import Vuex from 'vuex'
import localforage from 'localforage'
import auth from './modules/auth'
import cloud from './modules/cloud'
import social from './modules/social'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    db: null,
    storage: null,
    status: '',
    error: '',
    hasLoadedOnce: false,
    helpComponent: null,
    elementClicked: '',
    blockClicked: '',
    exportFilename: '',
    containerWidth: 0,
    homeLinks: [
      {
        id: 'features',
        title: 'Features',
      },

      {
        id: 'testimonials',
        title: 'What users are saying',
      },
      {
        id: 'who',
        title: 'About the developer',
      },
      {
        id: 'callToAction',
        title: 'Get started!',
      },
    ],
    loadables: [
      'scripts',
      'settings',
      'slates',
      'titleCards',
      'scriptListHeaders',
      'defaultSharedListHeaders',
      'exportFilename',
      'listsortby',
      'listsortdesc',
      'scriptFontSizes',
      'infoPages',

    ],
    visualPrompts: [
      { label: 'visible' },
      { label: 'first word' },
      { label: 'first letter' },
      { label: 'hidden' }
    ],
    languageOpts: [
      { text: 'Arabic (Saudi Arabia)', value: 'ar-SA' },
      { text: 'Bangla (India)', value: 'bn-IN' },
      { text: 'Czech', value: 'cs-CZ' },
      { text: 'Danish', value: 'da-DK' },
      { text: 'Austrian German', value: 'de-AT' },
      { text: 'Standard German', value: 'de-DE' },
      { text: 'Australian English', value: 'en-AU' },
      { text: 'British English', value: 'en-GB' },
      { text: 'Irish English', value: 'en-IE' },
      { text: 'Indian English', value: 'en-IN' },
      { text: 'American English', value: 'en-US' },
      { text: 'French', value: 'fr-FR' },
      { text: 'Hindi', value: 'hi-IN' },
      { text: 'Hungarian', value: 'hu-HU' },
      { text: 'Italian', value: 'it-IT' },
    ],
    headerTitle: null,
    headerScriptId: '',
    recordingLine: '',
    sounds: [],
    files: [],
    scripts: [],
    //sharedScripts: [],
    slates: [],
    titleCards: [],
    lastCharacter: '',
    playing: '',
    preTimerPlayingLine: '',
    recorder: null,
    rawScript: null,
    hasWavesurferRegion: false,
    wavesurferTrimToLine: '',
    startPoint: null,
    endPoint: null,
    rawSpeechResults: [],
    scriptListHeaders: [],
    defaultSharedListHeaders: [],

    //script menu
    editScriptDetails: false,
    showAddLines: false,
    showAddAction: false,
    showShareScript: false,
    showImport: false,
    syncFromCloud: false,
    showAction: true,
    showCharacters: false,
    showEditBlocks: false,
    showInstructions: false,
    showDescription: false,
    filterSection: null,
    editSection: null,
    isPlaying: false,
    playAll: false,
    flashcardFontSize: 1.5,
    scriptFontSizes: {},
    loopSection: null,
    playCueAudio: true,
    offlineclass: '',
    listsortby: '',
    listsortdesc: '',

    scannedText: '',

    settings: {
      gainValue: 1,
      audioBlobFormat: 'mp3',
      videoBlobFormat: 'mp4',
      audioDevice: null,
      videoDevice: null,
      myHighlight: '',
      theirHighlight: '',
      defaultLang: 'en-US',
      countdown: {
        seconds: 0,
        format: 'numbers',
        beeps: '0'
      },
      runOpts: {
        muteMe: 0,
        loop: false,
        hasCountdown: false,
        showBigButtons: false,
        hideMyLines: false,
      }
    }
  },
  getters: {
    getScannedText: state => state.scannedText,
    getContainerWidth: state => state.containerWidth,
    getBlankScriptDetails: state => state.blankDetails,
    getEditScriptDetails: state => state.editScriptDetails,
    getShowAddLines: state => state.showAddLines,
    getShowAddAction: state => state.showAddAction,
    showShareScript: state => state.showShareScript,
    showImport: state => state.showImport,
    syncFromCloud: state => state.syncFromCloud,
    showCharacters: state => state.showCharacters,
    getShowEditBlocks: state => state.showEditBlocks,
    showAction: state => state.showAction,
    showInstructions: state => state.showInstructions,
    showDescription: state => state.showDescription,
    filterSection: state => state.filterSection,
    getEditSection: state => state.editSection,
    getIsPlaying: state => state.isPlaying,
    getPlayAll: state => state.playAll,
    getFlashcardFontSize: state => state.flashcardFontSize,
    getScriptFontSize: state => scriptId => {
      if (Array.isArray(state.scriptFontSizes)) {
        let size = state.scriptFontSizes.find(s => s.scriptId == scriptId) || { size: 1 }
        return size.size
      } else if (scriptId in state.scriptFontSizes) {
        return state.scriptFontSizes[scriptId]
      } else {
        return 1
      }

    },
    getLoopSection: state => state.loopSection,
    getPlayCueAudio: state => state.playCueAudio,

    getListSortBy: state => state.listsortby,
    getListSortDesc: state => state.listsortdesc,

    getScriptTagList: state => {
      let tags = []
      state.scripts.forEach(s => {
        for (var i in s.details.tags) {
          let tag = s.details.tags[i]
          if (!tags.includes(tag)) {
            tags.push(tag)
          }
        }
      })
      return tags
    },
    getScriptsWithTag: state => tag => state.scripts.filter(s => s.details?.tags?.includes(tag)),
    getSlates: state => state.slates,
    getTitleCardTemplates: state => state.titleCards,
    getLanguageOpts: state => state.languageOpts,
    getHeaderTitle: state => state.headerTitle,
    getHeaderScriptId: state => state.headerScriptId,
    getHelpComponent: state => state.helpComponent,
    getStorage: state => state.storage,
    getDb: state => state.db,
    getVisualPrompts: state => state.visualPrompts,
    getRawSpeechResults: state => state.rawSpeechResults,
    getSetting: state => which => state.settings[which] || false,
    getElementClicked: state => state.elementClicked,
    getBlockClicked: state => state.blockClicked,
    getScriptForBlock: state => blockId => {
      return state.scripts.find(s => s.blocks?.find(l => l.id == blockId))
    },
    getExportFilename: state => state.exportFilename,
    getScriptListHeaders: state => state.scriptListHeaders,
    getDefaultSharedListHeaders: state => state.defaultSharedListHeaders,
    // getBlockAudio: state => id => {
    //   /* files [
    //     {script: sid,audio: [{bid: bid,audio: blob,duration}]}
    //   ]
    //   */
    //   const script = state.scripts.find(s => s.blocks?.find(l => l.id == id))
    //   state.files.find(a => a.script == script.id)?.filesfind(a)
    // },
    getAudio: state => id => {
      let script = state.scripts.find(s => s.blocks?.find(l => l.id == id))
      let block = script.blocks.find((b) => b.id == id)
      if (block) {
        return block.audio
      } else {
        return null
      }
    },
    getPlaying: state => state.playing,
    getPreTimerPlayingLine: state => state.preTimerPlayingLine,
    getRecordingLine: state => state.recordingLine,
    getExistingPDF: state => pdf => {
      const script = state.scripts.find(s => s.pdf && s.pdf.name == pdf)
      return script
    },

    getScript: state => id => {
      let script = state.scripts?.find(s => s.id == id)
      if (!script) {
        return false
      }
      return script
    },
    //getSharedScript: state => id => state.sharedScripts.find(s => s.id == id),
    getScripts: state => state.scripts,
    getFirstLineInScene: state => scene => {
      console.log(state, scene)
    },
    getCastingDirs: state => state.scripts.map(s => { if (s.details?.castingDir) return s.details?.castingDir }),
    getStartPoint: state => state.startPoint,
    getEndPoint: state => state.endPoint,


    getHasWavesurferRegion: state => state.hasWavesurferRegion,
    getWavesurferTrimToLine: state => state.wavesurferTrimToLine,
    getScriptsSharedWithUser: state => fid => state.scripts.filter(s => {
      for (var i in s.shares) {
        if (s.shares[i].fid == fid) {
          return true
        }
      }
      return false
    })

  },
  mutations: {
    saveScannedText(state, payload) {
      state.scannedText = payload
    },
    setscriptsloadable(state, payload) {
      state.scripts = payload
    },
    setsettingsloadable(state, payload) {
      state.settings = payload
    },
    setslatesloadable(state, payload) {
      state.slates = payload
    },
    settitleCardsloadable(state, payload) {
      state.titleCards = payload
    },
    setscriptListHeadersloadable(state, payload) {
      state.scriptListHeaders = payload
    },
    setdefaultSharedListHeadersloadable(state, payload) {
      state.defaultSharedListHeaders = payload
    },
    setexportFilenameloadable(state, payload) {
      state.exportFilename = payload
    },
    setlistsortbyloadable(state, payload) {
      state.listsortby = payload
    },
    setlistsortdescloadable(state, payload) {
      state.listsortdesc = payload
    },

    setscriptFontSizesloadable(state, payload) {
      state.scriptFontSizes = payload
    },
    setContainerWidth(state, payload) {
      state.containerWidth = payload
    },
    setListSortBy(state, payload) {
      state.listsortby = payload

    },
    setListSortDesc(state, payload) {
      state.listsortdesc = payload
    },
    showInstructions(state, payload) {
      state.showInstructions = payload
    },
    showDescription(state, payload) {
      state.showDescription = payload
    },
    setPlayCueAudio(state, payload) {
      state.playCueAudio = payload
    },
    setLoopSection(state, payload) {
      state.loopSection = payload
    },
    setPreTimerPlayingLine(state, payload) {
      state.preTimerPlayingLine = payload
    },
    adjustFlaschardFont(state, payload) {
      state.flashcardFontSize += payload == 'up' ? .1 : -.1
    },

    setEditSection(state, payload) {
      state.editSection = payload
    },
    setEditScriptDetails(state, payload) {
      state.editScriptDetails = payload
    },
    setShowAddLines(state, payload) {
      state.showAddLines = payload
    },
    setShowAddAction(state, payload) {
      state.showAddAction = payload
    },
    showImport(state, payload) {
      state.showImport = payload
    },
    showAction(state, payload) {
      state.showAction = payload
    },
    showCharacters(state, payload) {
      state.showCharacters = payload
    },
    syncFromCloud(state, payload) {
      state.syncFromCloud = payload
    },
    showShareScript(state, payload) {
      state.showShareScript = payload
    },
    filterSection(state, payload) {
      state.filterSection = payload
    },
    setIsPlaying(state, payload) {
      state.isPlaying = payload
    },
    setPlayAll(state, payload) {
      state.playAll = payload
    },
    setElementClicked(state, payload) {
      state.elementClicked = payload
    },
    setBlockClicked(state, payload) {
      state.blockClicked = payload
    },
    setExportFilename(state, payload) {
      state.exportFilename = payload
    },

    saveTitleCardTemplate(state, payload) {
      state.titleCards.push(payload)
    },
    setShowEditBlocks(state, payload) {
      state.showEditBlocks = payload
    },
    addSharedScript(state, payload) {
      state.sharedScripts.push(payload)
    },
    setHeaderTitle(state, payload) {
      state.headerTitle = payload
    },
    setHeaderScriptId(state, payload) {
      state.headerScriptId = payload
    },
    setHelpComponent(state, payload) {
      state.helpComponent = payload
    },
    setLoading(state) {
      //console.log('doing loading')
      state.status = 'loading';
      //console.log('after loading')
    },
    setStatus(state, payload) {
      state.status = payload
    },
    clearStatus(state) {
      state.status = ''
    },
    setError(state, payload) {
      state.error = payload;
    },
    clearError(state) {
      state.error = null
    },
    setStartPoint(state, payload) {
      state.startPoint = payload
    },
    setEndPoint(state, payload) {
      state.endPoint = payload
    },
    setWavesurferTrimToLine(state, payload) {
      state.wavesurferTrimToLine = payload
    },
    setHasWavesurferRegion(state, payload) {
      state.hasWavesurferRegion = payload
    },
    setRecordingLine(state, payload) {
      state.recordingLine = payload
    },
    saveLineMutation(state, payload) {
      let myLine = state.lines.find(p => p.id == payload.id)
      if (myLine) {
        Object.assign(myLine, payload)
      } else {
        state.lines.push(payload)
      }
    },
    saveAudioMutation(state, payload) {
      state.audio = payload
    },
    setPlayingMut(state, payload) {
      state.playing = payload
    },
    updateRunOpts(state, payload) {
      if (!state.settings.runOpts) state.settings.runOpts = {}
      Object.assign(state.settings.runOpts, payload)
    },
    saveSlate(state, video) {
      state.slates.push(video)
    },
    resetState(state) {
      state.scripts = []
      state.storage = null
      state.slates = []
      state.friends = []
      state.shared = []
      state.titleCards = []
      state.settings = {}
    },

    saveScriptListHeaders(state, payload) {
      state.scriptListHeaders = payload
    },
    saveDefaultSharedListHeaders(state, payload) {
      state.defaultSharedListHeaders = payload
    },

    setStorage(state, payload) {
      if (state.storage?.storeName != payload) {
        state.storage = localforage.createInstance({
          name: 'myline',
          storeName: payload
        })
        //import any previous data into the new storage
        localforage.keys().then(async keys => {
          for (var i in keys) {
            let k = keys[i]
            let val = await localforage.getItem(k)
            await state.storage.setItem(k, val)
          }
          await localforage.clear()
        })
      }
    }

  },



  actions: {
    adjustScriptFont(context, payload) {
      if (!context.state.scriptFontSizes || !Array.isArray(context.state.scriptFontSizes)) context.state.scriptFontSizes = []
      let fontSize = isNaN(context.getters.getScriptFontSize(payload.scriptId)) ? 1 : context.getters.getScriptFontSize(payload.scriptId)

      fontSize = (parseFloat(fontSize) + (payload.dir == 'up' ? .1 : -.1)).toFixed(1)
      let index = context.state.scriptFontSizes.findIndex(s => s.scriptId == payload.scriptId)
      let toInsert = {
        scriptId: payload.scriptId,
        size: fontSize
      }
      if (index >= 0) {
        context.state.scriptFontSizes.splice(index, 1, toInsert)
      } else {
        context.state.scriptFontSizes.push(toInsert)
      }

      context.getters.getStorage.setItem('scriptFontSizes', context.state.scriptFontSizes)
    },
    setBlock(context, block) {
      let script = context.getters.getScript(block.scriptId)
      if (script) {
        let blockIndex = script.blocks.findIndex(b => b.id == block.id)
        script.blocks.splice(blockIndex, 1, block)
      } else {
        script = context.getters.getSharedScript(block.scriptId)
        if (script) {
          let blockIndex = script.blocks.findIndex(b => b.id == block.id)
          script.blocks.splice(blockIndex, 1, block)
        }
      }
    },
    saveScriptListHeaders(context, payload) {
      context.commit('saveScriptListHeaders', payload)
      context.getters.getStorage.setItem('scriptListHeaders', context.state.scriptListHeaders)
    },
    saveDefaultSharedListHeaders(context, payload) {
      context.commit('saveDefaultSharedListHeaders', payload)
      context.getters.getStorage.setItem('defaultSharedListHeaders', context.state.defaultSharedListHeaders)
    },
    setListSortBy(context, payload) {
      context.commit('setListSortBy', payload)
      context.getters.getStorage.setItem('listsortby', payload)
    },
    setListSortDesc(context, payload) {
      context.commit('setListSortDesc', payload)
      context.getters.getStorage.setItem('listsortdesc', payload)
    },
    // updateAudioFile(context,payload) {
    //   // files [
    //   //   {script: sid,audio: [{bid: bid,audio: blob,duration}]}
    //   // ]
    //   const script = context.getters.getScriptForBlock(payload.blockId)
    //   //if the script is in the cloud, then the file needs to be removed from the cloud as well
    //   let scriptFiles = context.state.files.find(f => f.script == script.id)
    //   if (!scriptFiles) {

    //   }
    // },
    // removeAudioFile(context,payload) {

    // },
    saveDefaultExportFilename(context, payload) {
      context.commit('setExportFilename', payload)
      context.getters.getStorage.setItem('exportFilename', payload)
    },
    saveCardTemplate(context, card) {
      card.created = Date.now()
      context.commit('saveTitleCardTemplate', card)
      context.getters.getStorage.setItem('titleCards', context.state.titleCards)
    },
    saveSlate(context, video) {
      context.commit('saveSlate', video)
      context.getters.getStorage.setItem('slates', context.state.slates)
    },
    deleteVideo(context, { id, video }) {
      let script = context.getters.getScript(id)
      script.videos = script.videos.filter(v => v.created != video.created)
      context.dispatch('saveScript', script)
    },
    saveSpeechResults(context, payload) {
      context.state.rawSpeechResults = payload
      context.getters.getStorage.setItem('rawSpeechResults', context.state.rawSpeechResults)
    },
    saveSettings(context, payload) {
      Object.assign(context.state.settings, payload)
      context.getters.getStorage.setItem('settings', context.state.settings)
    },
    saveRunOpts(context, payload) {
      context.commit('updateRunOpts', payload)
      context.getters.getStorage.setItem('settings', context.state.settings)
    },
    removeScript(context, payload) {
      const scriptIndex = context.state.scripts.findIndex(s => s.id == payload)
      if (scriptIndex >= 0) {
        context.state.scripts.splice(scriptIndex, 1)
        context.getters.getStorage.setItem('scripts', context.state.scripts)
      }
    },
    async completelyRemoveScript(context, payload) {
      let script = context.getters.getScript(payload)
      if (!script) {
        await context.dispatch('downloadScript', payload)
        script = context.getters.getScript(payload)
      }
      if (script.inCloud) {
        context.dispatch('removeCloudScript', payload)
      } else {
        context.dispatch('removeScript', payload)
      }
    },
    async removeScriptFiles(context, payload) {
      let script = context.getters.getScript(payload)
      //remove all audio files from server
      for (var i in script.blocks) {
        let block = script.blocks[i]
        if (block.uploaded) {
          await context.dispatch('removeCloudFile', block.owner_id + '/' + block.scriptId + '/' + block.id)
        }
        block.audio = null
        block.uploaded = null
      }
      //remove all video files (except exported videos)
      for (var v in script.videos) {
        let video = script.videos[v]
        if (video.uploaded) {
          await context.dispatch('removeCloudFile', script.owner_id + '/' + script.id + '/' + video.created + '.webm')
        }
      }
    },

    async unArchiveScript(context, payload) {
      let script = context.getters.getScript(payload)
      script.isArchived = false
      context.dispatch('saveScript', script)
      if (script.inCloud) {
        await context.dispatch('uploadCloudScript', script)
      }
    },

    async archiveScript(context, payload) {

      await context.dispatch('removeScriptFiles', payload)
      let script = context.getters.getScript(payload)
      if (!script) {
        await context.dispatch('downloadScript', payload)
        script = context.getters.getScript(payload)
      }
      script.videos = []

      //remove sharing
      await context.dispatch('removeScriptShares', { script, 'fid': 'all' })
      script.shares = []

      script.isArchived = true

      context.dispatch('saveScript', script)
      if (script.inCloud) {
        await context.dispatch('uploadCloudScript', script)
      }
    },
    saveScripts(context) {
      context.getters.getStorage.setItem('scripts', context.state.scripts)
    },

    saveScriptNoUpdate({ getters, state }, payload) {
      if (!payload.videos) payload.videos = []
      //let script = getters.getScript(payload.id)
      const index = state.scripts.findIndex(s => s.id == payload.id)
      if (index >= 0) {
        state.scripts.splice(index, 1, payload)
        //Object.assign(script,payload)
      } else {
        state.scripts.push(payload)
      }

      console.log('saving local script', payload)
      getters.getStorage.setItem('scripts', state.scripts)
    },

    saveScript({ dispatch }, payload) {
      payload.updated = Date.now()
      dispatch('saveScriptNoUpdate', payload)
    },

    initialScriptUpload({ dispatch, getters }, script) {
      if (typeof script == 'string') {
        script = getters.getScript(script)
      }
      script.inCloud = true

      dispatch('uploadCloudScript', script)
      dispatch('saveScript', script)

    },

    setPlaying({ commit }, payload) {
      commit('setPlayingMut', payload)
    },

    async load(context) {

      console.log('loading scripts')
      for (var i in context.state.loadables) {
        const loadable = context.state.loadables[i]
        //console.log('getting loadable',loadable)
        let result = await context.getters.getStorage.getItem(loadable)
        if (result) {
          // result.forEach(script => {
          //   if (script.inCloud) {
          //     console.log('getting script from cloud')
          //     //context.dispatch('syncCloudScript',script)
          //   }
          // })
          //console.log(result)
          context.commit('set' + loadable + 'loadable', result)
        } else {
          console.log('no result for loadable', loadable)
          //context.state[loadable] = null
        }

      }

      await context.dispatch('addCloudListeners')

    },

    loadAudio(context) {
      context.getters.getStorage.getItem('sound').then(result => {
        context.state.audio = result
      })
    },
    updateBlockAudio({ getters, dispatch }, block) {
      const script = !block.scriptId ? getters.getScriptForBlock(block.id) : getters.getScript(block.scriptId)
      block.scriptId = script.id
      let index = script.blocks.findIndex(b => b.id == block.id)
      script.blocks.splice(index, 1, block)
      dispatch('saveScript', script)
    },
    // updateAudio(context,{audio,line}) {
    //   let script = context.state.scripts.find(s => s.blocks?.find(l => l.id == line))
    //   script.blocks.forEach((block) => {
    //     if (block.id == line) {
    //       block.audio = audio
    //       return
    //     }
    //   })

    //   context.dispatch('saveScript',script)
    // },
    // saveAudio(context,payload) {
    //   console.log('saving audio',payload)
    //   localforage.setItem('sound',payload).then(() => {
    //     context.state.audio = payload
    //   })
    // }
  },
  modules: {
    auth, cloud, social
  }
})
