<template>
    <v-card rounded="lg">
        <v-card-title class="cyan">
            Archive/Delete Script
        </v-card-title>
        <v-card-text>
            <v-container v-if="!isArchived">
                <h4>Archive</h4>
                You will still be able to view the script and it's details from the archive list<br />
                Archiving a script will do the following:
                <ul>
                    <li>remove script sharing</li>
                    <li>remove all audio files</li>
                    <li>remove all takes (it will keep any exported video)</li>
                    <li>hide the script from your active script list</li>
                </ul>
                <v-spacer></v-spacer><v-btn rounded class="warning" @click="archiveScript">Archive</v-btn>
            </v-container>
            <v-container v-else>
                This script is currently archived. 
                <v-btn rounded class="success" @click="unArchiveScript">Un-Archive</v-btn>
            </v-container>
            <v-divider></v-divider>
            <v-container>
                <h4>Delete</h4>
                
                <v-row>
                    <v-col cols="12">
                        <v-btn rounded dark @click="deleteScript('local')">Delete local copy</v-btn> (script will still be available on other devices and in cloud)
                    </v-col>
                    <v-col cols="12">
                        <v-btn rounded dark @click="deleteScript('all')">Delete all copies</v-btn> (script will be deleted from all devices and cloud backup)
                    </v-col>
                </v-row>
                
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDialogue">close</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    name: "ArchiveScript",
    props: ['script'],
    mounted() {
        
    },
    components: {
        
    },
    computed: {
        isArchived() {
            const script = this.$store.getters.getScript(this.script)
            return script.isArchived || false
        }
    },
    data() {
        return {
            whichDelete: 'local'
        }
    },
    methods: {
        async unArchiveScript() {
            await this.$store.dispatch('unArchiveScript',this.script)
            this.closeDialogue()
        },
        async archiveScript() {
            await this.$store.dispatch('archiveScript',this.script)
            this.closeDialogue()
        },
        async deleteScript(which) {
            if (!confirm('Are you sure you want to delete this script ' + (which == 'all' ? 'on all devices?' : 'on this device?') + ' This CANNOT be undone.')) return
            if (which == 'local') {
                await this.$store.dispatch('removeScript',this.script)
            } else if (which == 'all') {
                await this.$store.dispatch('completelyRemoveScript',this.script)
            }
            this.closeDialogue()
            if (this.$route.name != 'scripts') {
                this.$router.push({name: 'scripts'})
            }
        },
        closeDialogue() {
            this.$emit('close',true)
        }
    }
}
</script>