<template>
<v-card>
    <v-card-title>Need some help?</v-card-title>
    <v-card-text>
        <v-expansion-panels accordion>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Script Menu
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <ul>
                            <li><v-icon>mdi-form-textbox</v-icon>: Edit Title</li>
                            <li><v-icon>mdi-cloud-upload-outline</v-icon>: Save to cloud. Saves the script to the cloud. You can then download the script on other devices.</li>
                            <li><v-icon>mdi-cloud-sync</v-icon>: Sync to cloud. If you have made changes to your script, it will save them to the cloud (which happens when you leave the script automatically). Otherwise it will check the cloud for changes from other linked devices.</li>
                            <li><v-icon>mdi-forum-plus-outline</v-icon>: Add dialogue to the script</li>
                            <li><v-icon>mdi-upload</v-icon>: Upload/record mp3</li>
                            <li><v-icon>mdi-video-vintage</v-icon>: Go to selftape recording (partially implemented)</li>
                            <li><v-icon>mdi-delete</v-icon>: Deletes script (only locally, not on cloud)</li>
                        </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        View Menu
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <ul>
                            <li><v-icon>mdi-forum</v-icon>: Show/hide action lines</li>
                            <li><v-icon>mdi-button-pointer</v-icon>: Show/hide big prompt and play buttons (mobile only)</li>
                            <li><v-icon>mdi-account-group</v-icon>: Show/hide character list</li>
                        </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Scenes
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Scenes define start and end points to a subset of the script. 
                        They don't necessarily have to correspond to a "scene" in the traditional sense. 
                        Scenes can be filtered (showing only the part of the script between the start and end points) and run separately.
                        Deleting a scene only deletes the markers pointing to the start and end points. It doesn't delete any of the actual script.
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Options Menu
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Options for running lines.
                        <ul>
                            <li><v-icon>mdi-account-voice</v-icon>: Mute/play my line's audio when running lines</li>
                            <li><v-icon>mdi-sync</v-icon>: Whether to run lines on repeate when reaching the end of the section</li>
                            <li><v-icon>mdi-timer-outline</v-icon>: Do the countdown prior to running lines</li>
                            <li>MY LINES Visibility. Changes how my lines are displayed in the script. Clicking on the lines will toggle to fully visible and back.
                                <ul>
                                    <li>visible</li>
                                    <li>first word: shows only the first word</li>
                                    <li>first letter: shows the first letter of each word</li>
                                    <li>hidden: buttons for showing and hearing the line are displayed</li>
                                </ul>
                            </li>
                        </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Dialogue Block
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Dialogue blocks contain characters dialogue. For each line, you can record audio. Lines without recorded audio will have a computer generated voice.
                        
                        <ul>
                            <li>Edit dialogue block
                                <ul>
                                    <li>Character name</li>
                                    <li>Me/Them assignment</li>
                                    <li>Dialogue. New lines will be kept. Parentheticals will not be spoken by the speech synth</li>
                                </ul>
                            </li>
                            <li>Remove audio</li>
                            <li>Delete line</li>
                        </ul>

                        <ul>
                            <li><v-icon>mdi-</v-icon>: Show/hide line waveform. When the waveform is shown, you can trim the line audio by dragging along the waveform. clicking the sissors will trim the audio to the selected audio</li>
                            <li><v-icon>mdi-play</v-icon>: Play line</li>
                            <li><v-icon>mdi-record</v-icon>: Record line audio</li>
                        </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>
            
            
            
            
            
            
         
    </v-card-text>
</v-card>
</template>

<script>
/*
<li><v-icon>mdi-script-text-outline</v-icon>: N/A</li>
            
*/
export default {
    name: "HelpComponent",
    props: {},
    computed: {
        
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>