<template>
    <v-menu min-width="150" offset-y ref="sectionsMenu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text
        class="px-1"
        min-width="0"
          v-bind="attrs"
          v-on="on"
        >
        Sections
        </v-btn>
      </template>
 
<v-list dense class="pr-2">

        <v-list-item :class="`pa-0 pl-2 ${filterSection == sect.index ? 'filtered' : ''}`" v-for="(sect,vm) in sections" :key="`sm-${vm}`" :title="sect.hover" @click="scrollToBlock(sect.index)">
            
            <v-list-item-content v-text="sect.title"></v-list-item-content>
            <v-list-item-action class="ma-0 pa-1" v-if="sect.index >= 0">
                    <v-list-item-icon class="ma-0 pa-0 d-flex align-center">
                        <v-menu offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense class="pr-2">
                                <v-list-item v-if="filterSection != sect.index" class="pa-0" @click="setFilterSection(sect.index)">
                                    <v-list-item-avatar class="ma-0">
                                        <v-icon>mdi-filter</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>Show this section only</v-list-item-content>
                                </v-list-item>
                                <v-list-item v-else class="pa-0" @click="setFilterSection(null)">
                                    <v-list-item-avatar class="ma-0">
                                        <v-icon>mdi-filter-remove</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>Show entire script</v-list-item-content>
                                </v-list-item>
                                <v-list-item class="pa-0" @click="$store.commit('setEditSection',{scriptId,index: sect.index,type:'edit'})">
                                    <v-list-item-avatar class="ma-0">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>Edit Section</v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line class="pa-0" @click="$store.commit('setEditSection',{scriptId,index:sect.index,type:'remove'})">
                                    <v-list-item-avatar class="ma-0">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content><v-list-item-title>Delete Section</v-list-item-title>
                                        <v-list-item-subtitle>(does not remove the actual text)</v-list-item-subtitle>
                                        </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        
                    </v-menu>
                </v-list-item-icon>
            </v-list-item-action>
        </v-list-item>
        <v-divider v-if="sectionsMenu.length"></v-divider>
        <v-list-item class="pa-0" v-for="(item,vm) in sectionsMenu" :key="`rm-${vm}`" :title="item.title" @click.stop.prevent="item.click">
            <v-list-item-avatar class="ma-0">
                <v-icon>{{item.icon}}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content v-text="item.label"></v-list-item-content>
            <v-list-item-action v-if="item.action" class="ma-0">
                <v-btn icon @click="item.action">
                    <v-icon color="grey lighten-1">{{item.actionIcon}}</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <v-dialog width="400" v-model="showSceneHelper">
            <v-card rounded="lg">
                <v-card-title class="primary">What is a section?</v-card-title>
                <v-card-text>
                    <p>A section is way to mark a specific part of the script. 
                    It doesn't have to correspond to a traditional "scene" in the script. </p>
                    <p>You can use this feature to designate any part of the script that you would like to filter or run by itself.</p>
                    <p>Deleting a section only deletes the reference to the start and end points. It doesn't delete the lines themselves.</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showSceneHelper = false">close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    
      </v-list>
    </v-menu>
</template>

<script>

export default {
    name: "SectionList",
    props: ['scriptId'],
    
    computed: {
        sections() {
            return this.$store.getters.getScript(this.scriptId).sections
        },
        filterSection() {
            return this.$store.getters.filterSection
        },  
        sectionsMenu() {
            let list = []
            if (this.filterSection != null) {
                list.push({
                    click: () => {this.$store.commit('filterSection',null)},
                    icon: 'mdi-filter-remove',
                    label: 'Show entire script',
                    title: ''
                })
            }
            list.push({
                click: () => this.$store.commit('setEditSection',{scriptId: this.scriptId,index: -1,type:'edit'}),
                icon: 'mdi-plus',
                label: 'Add Section',
                title: ''
            })
            list.push({
                click: () => this.showSceneHelper = true,
                icon: 'mdi-help',
                label: 'What is a section'
            })
            return list

        },
    },
    
    data() {
        return {
            showSceneHelper: false,
        }
    },
    methods: {
        setFilterSection(n) {
            this.$store.commit('filterSection',n)
            if (n == null) {
                this.$store.commit('setLoopSection',null)
            } else {
                this.$store.commit('setLoopSection',{scriptId: this.scriptId,index: n})
            }
            
        },
        scrollToBlock(n) {
            if (n == undefined) return
            if (this.filterSection != null && this.filterSection != n) return
            const container = {
                //container: '#holder',
            }
            
            const targetid = '#section-' + n + '-start'
            this.$vuetify.goTo(targetid,container)
        },
    }
}
</script>