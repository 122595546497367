<template>
<v-row no-gutters>
    <v-col cols="12">
        <v-card-title class="pa-0">Default Script Language<v-spacer></v-spacer>
            <v-btn icon small @click="showHelp = !showHelp"><v-icon>mdi-help-circle</v-icon></v-btn>
        </v-card-title>
        <v-expand-transition>
            <div v-if="showHelp">
                <p>While the interface is only in English (for now), scripts can be in a bunch of different languages. Any new scripts will be automatically set to your default language. You can then change the language setting of any individual script, character or line.</p>

                <p>The script language is used during speech synthasis (during playback) and voice recognition (Virtual Partner)</p>
            </div>
        </v-expand-transition>
        <v-select :items="languageOpts" v-model="defaultLang"></v-select>
    </v-col>
</v-row>
</template>

<script>

export default {
    name: "LanguageSetting",
    props: ['noUpdate'],
    mounted() {
        
    },
    watch: {
        defaultLang() {
            console.log(this.defaultLang)
            if (!this.noUpdate) {
                this.$store.dispatch('saveSettings',{
                    defaultLang: this.defaultLang,
                })
            }
        }
    },
    components: {
        
    },
    computed: {
        languageOpts() {
            return this.$store.getters.getLanguageOpts
        },
    },
    data() {
        return {
            showHelp: false,
            defaultLang: this.$store.getters.getSetting('defaultLang') || 'en-US',
        }
    },
    methods: {
        
    }
}
</script>