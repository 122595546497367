import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCurrentUser } from '@/firebase/firebaseClient'

//import EditScriptView from '../views/EditScriptView.vue'
//import ScriptView from "../views/ScriptView.vue";
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "script" */ '../views/Home.vue'),
    meta: {
      authRequired: false
    },
  },
  {
    path: '/script/:id',
    name: 'script',
    component: () => import(/* webpackChunkName: "script" */ '../views/ScriptView.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/script/shared/:shared',
    name: 'sharedScript',
    component: () => import(/* webpackChunkName: "script" */ '../views/SharedView.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/scripts',
    name: 'scripts',
    component: () => import(/* webpackChunkName: "script" */ '../views/ScriptsView.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/signup',
    name: 'newUser',
    component: () => import(/* webpackChunkName: "script" */ '../views/Login.vue'),
    meta: {
      authRequired: false
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "script" */ '../views/Login.vue'),
    meta: {
      authRequired: false
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "script" */ '../views/Profile.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/friends',
    name: 'friends',
    component: () => import(/* webpackChunkName: "script" */ '../views/Friends.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/new',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewScriptView.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/newold',
    name: 'unifiedNew',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      authRequired: true
    },
  },

  {
    path: '/tape/:id',
    name: 'tape',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tape.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/export/:id',
    name: 'exports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VideoExport.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/videos/:id',
    name: 'videos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VideoList.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/flashcard/:id',
    name: 'flashcard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Flashcard.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/autocue/:id',
    name: 'autocue',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AutoCue.vue'),
    meta: {
      authRequired: true
    },
  },

  {
    path: '/test',
    name: 'test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/admin/editEmails',
    name: 'editEmailTemplates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EditEmailTemplates.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/admin/editVoices',
    name: 'editVoices',
    component: () => import(/* webpackChunkName: "about" */ '../views/EditVoices.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/admin/editPages',
    name: 'editPageTemplates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EditPageTemplates.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/admin/editNews',
    name: 'editNewsAlerts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EditNewsAlerts.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: '/about/:page',
    name: 'InfoPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InfoPage.vue'),
  },
  {
    path: '/quickstart',
    name: 'quickstart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Quickstart.vue'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  if (to.matched.some(record => record.meta.authRequired) && !await getCurrentUser()) {
    console.log('no user')
    next({
      path: '/',
    });
  } else {

    next()
  }
});

export default router
