import { db, deUndefine } from '@/firebase/firebaseClient'
import {
    setDoc,
    //addDoc,
    doc,
    getDoc,
    collection,
    query,
    where,
    collectionGroup,
    //arrayUnion,
    //arrayRemove 
    //updateDoc,
    onSnapshot,
    getDocs,
    //updateDoc,
    deleteDoc,
    //arrayUnion,
} from 'firebase/firestore'

import {
    getStorage, ref, uploadBytes, getDownloadURL, deleteObject//getMetadata 
} from "firebase/storage";

import { getDatabase, ref as realtimeRef, onValue, } from "firebase/database";

//import Stripe from 'stripe';

const store = {
    getters: {
        getTestimonials: state => state.testimonials,
        getFeatures: state => state.features,
        getLinks: state => state.links,
        isExproting: state => state.isExporting,
        getExportMessage: state => state.exportMessage,
        getExportedFile: state => state.exportedFile,
        getCloudScripts: state => state.cloudScripts,
        getCloudScript: state => id => state.cloudScripts.find(s => s.id == id),
        getUpdateListForScript: state => scriptId => state.updateList.find(u => u.scriptId == scriptId),
        getEmailTemplates: state => state.emailTemplates,
        getInfoPages: state => state.infoPages,
        getNewsAlerts: state => state.newsAlerts,
        getInfoPage: state => page => state.infoPages.find(p => p.id == page)
    },
    state: {
        //The user state will initially be null. After login, this state will be updated
        cloudScripts: [],
        updateList: [],
        isCloudLoaded: false,
        exportedFile: null,
        exportMessage: '',
        isExporting: false,
        cloudListeners: [],
        emailTemplates: [],
        testimonials: [
            {
                name: 'Malcolm Jeffries',
                link: 'https://app.spotlight.com/4379-7838-4837',
                img: 'malcolm_jeffries.jpeg',
                agent: 'CARLTON WEST ARTIST MANAGEMENT',
                agentLink: 'http://www.carltonwestartists.com/',
                credits: 'MY BEAUTIFUL DIVORCE, JULIUS CAESAR',
                text: "Over the years I've used a few line learning apps to help grapple with the onerous task of learning lines. While they have been good there has always been a blind spot here and there and invariably the process of inputting a script has been laborious. MyLine has changed that. It's easy to use with a simple, effective interface and makes loading up a script, editing, and character choice a doddle, leaving me to get on and learn my lines with very little fuss. Whether it be a few sides for a self tape/audition or a full blown play/screenplay MyLine has become an invaluable tool in my work as an actor.",
            },
            {
                name: 'Abby Carter',
                link: 'https://app.spotlight.com/4299-8970-4079',
                img: 'abby_carter.jpeg',
                agent: 'KMC ARTIST MANAGEMENT LTD',
                agentLink: 'http://www.kmc.management/',
                text: "I all round love the app - would definitely recommend to my other actor friends! I used to record my lines on my voice note app then have to try and do the correct space before the next line which was a logistical nightmare, this app has made that process so much easier.  I'm not the most tech savvy person but managed to eventually find my way round and it’s running smoothly.",
                credits: 'BURNING LETTERS',
            },
        ],
        links: [
            {
                img: 'Instagram_logo_2022.svg.png',
                link: 'https://instagram.com/myline.app',
            },
            {
                img: 'youtube_logo.jpg',
                link: 'https://youtube.com/@mylineapp?si=JCE7fm4iIlTP71ZL',
            },
            {
                img: 'Threads_(app).svg.png',
                link: 'https://www.threads.net/@briancaspe',
            },
            {
                img: '636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png',
                link: 'https://discord.gg/z4M8N4hn',
            },
        ],
        features: [
            {
                title: 'Import scripts in just a few taps!',
                imgSrc: '/img/screenshots/uploadImage.png',
                bullets: [
                    'Import or create your own scripts in 6 different ways',
                    'Open scripts directly from your email',
                    'Uses your phone\'s camera to scan paper scripts',
                    'Import scripts in screenplay or theatre play formatting',
                    'Create a new script by entering your own text',
                ],
                tutorials: [
                    "https://www.youtube.com/embed/l-TN4sH2yow",
                ]

            },
            {
                title: 'Run lines when and how you want',
                imgSrc: '/img/screenshots/playImage.png',

                bullets: [
                    'Hear your lines, leave a space to say them or listen-and-repeat to help learn',
                    'Adjusts to a wide variety of learning styles',
                    'Change the playback speed',
                    'Choose how much of your script to show',
                ]

            },
            {
                title: 'Edit your scripts, record your lines',
                imgSrc: '/img/screenshots/editImage.png',
                bullets: [
                    'Easily edit any text, dialogue or action',
                    'Run lines with audio you recorded',
                    'Wide variety of voices available for each character',
                    'Adjust the timing for any line',
                ]
            },
            // {
            //   title: 'Shoot & export your tape',
            //   imgSrc: '/img/selftape.jpeg',
            //   bullets: [
            //     'Record directly in the app',
            //     'MyLyne uses voice recognition to listen for your cue! No need for a partner',
            //     'Export your tape to mp4: choose the best takes, add title cards',
            // ]
            // }
        ],
        infoPages: [],
        newsAlerts: [],
    },
    mutations: {
        setinfoPagesloadable(state, payload) {
            state.infoPages = payload
        },
        setnewsalertsloadable(state, payload) {
            state.newsAlerts = payload
        },
        updateNewsAlert(state, payload) {
            const index = state.newsAlerts.findIndex(item => item.newsId == payload.newsId)
            if (index > -1) {
                state.newsAlerts.splice(index, 1, payload);
            }
        },

        deleteNewsAlert(state, payload) {
            const index = state.newsAlerts.findIndex(item => item.newsId == payload)
            if (index > -1) {
                state.newsAlerts.splice(index, 1);
            }
        },
        newEmailTemplate(state, payload) {
            state.emailTemplates.push({
                id: payload,
                dataFields: '',
                subject: '',
                html: '',
            })
        },
        newInfoPage(state, payload) {
            state.infoPages.push({
                id: payload,
                title: '',
                html: '',
            })
        },
        newNewsAlert(state, payload) {
            state.newsAlerts.push({
                newsId: payload,
                message: '',
                validUntil: 0,
                type: 'alert',
                minBuild: '',
                link: '',
                uploaded: new Date().getTime()
            })
        },
        setEmailTemplates(state, payload) {
            state.emailTemplates = payload
        },

        setTestimonials(state, payload) {
            state.testimonials = payload
        },
        setScreenshots(state, payload) {
            state.screenshots = payload
        },
        setLinks(state, payload) {
            state.links = payload
        },
        addCloudListener(state, payload) {
            state.cloudListeners.push(payload)
        },

        setExporting(state, payload) {
            state.isExporting = payload
        },
        exportMessage(state, payload) {
            state.exportMessage = payload
        },
        exportedFile(state, payload) {
            state.exportedFile = payload
        },
        setCloudTitles(state, payload) {
            state.cloudTitles = payload
        },
        setCloudLoaded(state, payload) {
            state.isCloudLoaded = payload
        },
        setCloudUpdates(state, { scriptId, updateList }) {
            //go through each of the files and if there are any that are new
            console.log('setCloudUpdates', scriptId, updateList)
        }
        //Mutation to update the user state
        //Takes in two arguments, the state and the payload. When we call this mutation, the payload will be user object from firebase auth
        //When the user logs out, we call the mutation and the payload will be null

    },
    actions: {
        async loadHomeItem(context, item) {
            console.log(item);
            try {
                const testimonialsRef = collection(db, item);
                const snaps = await getDocs(testimonialsRef)
                let temps = []

                snaps.forEach(doc => {
                    console.log(doc);
                    temps.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                console.log('temps', temps)
                context.commit('set' + item, temps)
            } catch (error) {
                console.log(error);
            }
        },
        async loadHomeItems(context) {
            console.log('hello');
            context.dispatch('loadHomeItem', 'Testimonials');
            context.dispatch('loadHomeItem', 'Links');
            context.dispatch('loadHomeItem', 'Screenshots');

        },

        // async downloadTitles(context) {
        //     const user = context.getters.getUser
        //     console.log('download titles user',user)
        //     const userId = user.uid
        //     if (!userId) return
        //     let titles = []

        //     // const querySnapshot = await getDocs(collection(db, "uploads", userId,'updates'));
        //     // querySnapshot.forEach((doc) => {
        //     //     // doc.data() is never undefined for query doc snapshots
        //     //     titles.push({
        //     //         id: doc.id,
        //     //         ...doc.data()
        //     //     })
        //     // });
        //     context.commit('setCloudTitles',titles)
        // },
        async loadEmailTemplates(context) {

            const templatesRef = collection(db, 'emailTemplates')
            const snaps = await getDocs(templatesRef)
            let temps = []
            snaps.forEach(doc => {
                temps.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            console.log(temps)
            context.commit('setEmailTemplates', temps)
        },
        async loadPages(context) {

            const templatesRef = collection(db, 'infoPages')
            const snaps = await getDocs(templatesRef)
            let temps = []
            snaps.forEach(doc => {
                temps.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            console.log(temps)
            context.commit('setinfoPagesloadable', temps)
        },
        async loadAlerts(context) {

            const templatesRef = collection(db, 'news')
            const snaps = await getDocs(templatesRef)
            let temps = []
            snaps.forEach(doc => {
                temps.push({
                    newsId: doc.id,
                    ...doc.data()
                })
            })
            console.log(temps)
            context.commit('setnewsalertsloadable', temps)
        },
        saveInfoPage(context, temp) {
            temp = deUndefine(temp)
            setDoc(doc(db, 'infoPages', temp.id), temp)
        },
        saveNewsAlert(context, temp) {
            temp = deUndefine(temp)
            console.log(temp)
            context.commit('updateNewsAlert', temp)
            setDoc(doc(db, 'news', temp.newsId), temp)
        },
        deleteNewsAlert(context, id) {
            context.commit('deleteNewsAlert', id)
            deleteDoc(doc(db, 'news', id))
        },
        saveEmailTemplate(context, temp) {
            temp = deUndefine(temp)
            setDoc(doc(db, 'emailTemplates', temp.id), temp)
        },

        async downloadScript(context, scriptid) {
            console.log(scriptid)
            let script = context.getters.getCloudScript(scriptid)
            for (var i in script.blocks) {
                let block = script.blocks[i]
                if (block.audio && typeof block.audio == 'string') {
                    await context.dispatch('downloadAudioForBlock', block)
                    //console.log(block.audio)
                }
            }
            context.dispatch('saveScript', script)
        },

        async downloadExportedVideo(context, exportUrl) {
            const storage = getStorage()
            getDownloadURL(ref(storage, exportUrl)).then(url => {
                const xhr = new XMLHttpRequest();
                //console.log('downloadURL',url)
                xhr.responseType = 'blob';
                xhr.onload = () => {
                    context.commit('exportedFile', xhr.response);
                    context.commit('setExporting', false)
                };
                xhr.open('GET', url);
                xhr.send();
            }).catch(e => console.log(e))
        },
        async downloadAudioForBlock(context, block) {
            //console.log('in download audio for block',block.id)

            const storage = getStorage()
            if (block.audio && typeof block.audio == 'string') { //audio is saved as the path to the file audio
                //console.log('downloading audio',block.id)
                try {
                    const url = await getDownloadURL(ref(storage, block.audio))
                    //console.log('download url',block.id,url)
                    if (url) {
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.onload = () => {
                            block.audio = xhr.response;
                            context.dispatch('setBlock', block)
                        };
                        xhr.open('GET', url);
                        xhr.send();
                    }
                } catch (e) {
                    block.audio = null
                    context.dispatch('setBlock', block)
                }
            } else {
                //console.log('block has no audio reference',block.id)
            }
        },

        async uploadCloudVideo(context, source) {
            const userId = context.getters.getUser?.uid
            if (!userId) {
                console.log('user not logged in for uploadCloudVideo')
                return
            }
            const refPath = userId + '/' + source.script + '/' + source.created + '.webm'
            const metadata = {
                contentType: 'video/webm',
            };
            const storage = getStorage();
            const blockRef = ref(storage, refPath);
            console.log('uploading video to cloud', source)
            await uploadBytes(blockRef, source.blob, metadata)
            source.uploaded = Date.now()
        },

        async uploadCloudAudio(context, block) {
            const userId = context.getters.getUser?.uid
            if (!block.owner_id) block.owner_id = userId
            if (!userId) {
                console.log('user not logged in for uploadCloudAudio')
                return
            }
            const refPath = block.owner_id + '/' + block.scriptId + '/' + block.id
            const metadata = {
                contentType: 'audio/mp3',
            };
            const storage = getStorage();
            const blockRef = ref(storage, refPath);
            console.log('uploading block to cloud', block.id, refPath)
            await uploadBytes(blockRef, block.audio, metadata)
            block.uploaded = Date.now()
        },

        async removeCloudFile(context, payload) {
            const storage = getStorage()
            const fileRef = ref(storage, payload)
            await deleteObject(fileRef)

        },

        async removeCloudScript(context, payload) {
            const userId = context.getters.getUser?.uid
            await context.dispatch('removeScriptFiles', payload)
            await context.dispatch('removeScriptShares', { script: payload, 'fid': 'all' })
            await deleteDoc(doc(db, 'uploads', userId, 'scripts', payload))
        },

        // async saveCloudFileUpdate(context,{refPath,updated,updateCloudFile}) {
        //     console.log(refPath,updated)
        //     let [userId,scriptId,blockId] = refPath.split('/')
        //     console.log(userId,scriptId,blockId)
        //     let updateList = context.getters.getUpdateListForScript(scriptId)
        //     if (!updateList) {
        //         updateList = {
        //             scriptId,
        //             updates: [{id: blockId,updated}]
        //         }
        //         context.state.updateList.push(updateList)
        //     } else {
        //         let newUpdate = updateList.find(u => u.id == blockId)
        //         if (!newUpdate) {
        //             newUpdate = {id: blockId}
        //             updateList.updates.push(newUpdate)
        //         }
        //         newUpdate.updated = updated
        //     }
        //     console.log(userId,context.state.updateList)

        //     if (updateCloudFile) {
        //          // setDoc(doc(db,'uploads',userId,'scripts',scriptId),{
        //     //     updates 
        //     // },{merge: true})
        //     }

        // },

        // async syncCloudScript({getters,dispatch},script) {
        //     //see if the cloud based script is more recent than the script we have. 
        //     //if it is, update the script and any audio
        //     const userId = getters.getUser?.uid

        //     if (!userId) {
        //         console.log('no user')
        //         return
        //     }
        //     if (!script.owner_id) script.owner_id = userId

        //     const scriptRef = collection(db, 'uploads',script.owner_id,'scripts');
        //     console.log('in script',script.title,scriptRef)
        //     const q = query(scriptRef,where(script.id + '_updated',">",script.updated))

        //     const querySnapshot = await getDocs(q);
        //     console.log('received snapshot',querySnapshot)
        //     let blocks = script.blocks
        //     querySnapshot.forEach((doc) => {
        //         const data = doc.data()
        //         console.log('checking query snapshot for doc',data.id)
        //         data.blocks.forEach(async block => {
        //             if (block.audio) {
        //                 let oldBlock = blocks.find(b => b.id == block.id)
        //                 if (oldBlock.audioUpdated > block.audioUpdated) {
        //                     console.log('using old block audio',new Date(oldBlock.audioUpdated).toUTCString())
        //                     block.audio = oldBlock.audio
        //                 } else {
        //                     console.log('downloading new audio',new Date(block.audioUpdated).toUTCString())
        //                     await dispatch('downloadAudioForBlock',block)
        //                 }
        //             }
        //         })
        //         console.log('new cloud data',data)
        //         Object.assign(script,data)

        //     });
        //     blocks.forEach(async b => {
        //         await dispatch('downloadAudioForBlock',b)
        //     })
        //     dispatch('saveScript',script)
        // },

        async saveScriptToCloud({ dispatch, getters }, script) {
            console.log('save script to cloud', script)
            script.inCloud = true
            if (script.owner_id == getters.getUser.uid || !script.owner_id) {
                dispatch('saveScript', script)
            } else {
                script.updated = Date.now()
            }
            dispatch('uploadCloudScript', script)
        },

        //this should just be the initial upload
        async uploadCloudScript({ getters, dispatch }, script) {
            console.log('uploading script', script)
            const userId = getters.getUser.uid
            if (!script.owner_id) script.owner_id = userId

            //do we need to strip out the audio so that we can upload the script?
            let scriptCopy = structuredClone(script)
            scriptCopy.lastUpload = scriptCopy.lastUpload || 0

            for (var i = 0; i < scriptCopy.blocks.length; i++) {
                var block = Object.assign({}, scriptCopy.blocks[i])
                block.lang = block.lang || scriptCopy.details?.lang || getters.getSetting('defaultLang') || 'en-US'
                if (block.audio) {
                    block.scriptId = script.id
                    block.owner_id = script.owner_id
                    //update the block audio file if this block's audioUpdated is more recent than what 
                    if (block.audioUpdated > scriptCopy.lastUpload) {
                        console.log('uploading audio', block.id)
                        dispatch('uploadCloudAudio', block)
                    } else {
                        console.log('audio not recent', block.id, new Date(block.audioUpdated).toUTCString(), new Date(scriptCopy.lastUpload).toUTCString())
                    }
                    block.audio = script.owner_id + '/' + script.id + '/' + block.id
                    scriptCopy.blocks[i] = block
                } else if (block.audioUpdated) {
                    dispatch('removeCloudFile', script.owner_id + '/' + script.id + '/' + block.id)
                    block.audioUpdated = ''
                }
            }
            scriptCopy.lastUpload = Date.now()
            script.lastUpload = scriptCopy.lastUpload
            scriptCopy[scriptCopy.id + '_updated'] = scriptCopy.updated

            //for now remove all of the videos.. maybe save only the favorite videos? not sure
            scriptCopy.videos = null
            try {
                scriptCopy = deUndefine(scriptCopy)
                setDoc(doc(db, 'uploads', script.owner_id, 'scripts', script.id), scriptCopy, { merge: true }).then().catch(e => {
                    console.log(e)
                })
            }
            catch (e) {
                console.log(e)
            }
            // setDoc(doc(db,'uploads',userId,'updates',script.id),{
            //     title: scriptCopy.title,
            //     created: scriptCopy.created,
            //     blocks: scriptCopy.blocks.length,
            //     sections: scriptCopy.sections.length,
            //     updated: scriptCopy.updated,
            //     lastUpload: scriptCopy.lastUpload
            // },{merge: true})
            return false;

            // const response = await signInWithEmailAndPassword(auth, email, password)
            // if (response) {
            //     context.commit('setUser', response.user)
            // } else {
            //     throw new Error('login failed')
            // }
        },




        async exportVideo({ dispatch, getters, commit }, payload) {
            commit('setExporting', true)

            const userId = getters.getUser?.uid
            const script = getters.getScript(payload.scriptId)
            let owner_id = script.owner_id || userId
            const BASE_PATH = owner_id + '/' + payload.scriptId + '/'
            //payload will eventually be the export object
            //upload all of the files
            //make sure the other character's audio is uploaded 
            let takes = payload.clips.filter(c => c.type == 'take')

            for (var i in takes) {
                let take = takes[i]
                let cues = []
                for (var j in take.cues) {
                    let cue = take.cues[j]

                    let block = script.blocks.find(b => b.id == cue.source)
                    block.scriptId = script.id
                    block.owner_id = owner_id
                    if (block.audio) {
                        if (!block.uploaded) {
                            await dispatch('uploadCloudAudio', block)
                        }
                        cue.gcsPath = BASE_PATH + cue.source
                        cues.push(cue)
                    }

                }
                take.cues = cues
                //now upload the video
                if (!take.source.uploaded) {
                    await dispatch('uploadCloudVideo', take.source)
                }
                take.gcsPath = BASE_PATH + take.source.created + '.webm'
                // take.tempFilename = take.source.created + '.webm'
                // fd.append(take.tempFilename, take.source.blob,take.tempFilename)
            }

            payload.settings.gcsPath = BASE_PATH + payload.settings.outputFilename + 'test.mp4'
            payload.settings.doFade = true
            console.log('going to send json: ', payload)

            //upload the videos
            //const url = process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'local' ? 'http://localhost:3000' : 'https://us-central1-record-rehearse-tape.cloudfunctions.net/ffmpeg-transcode'
            //https://firebase.google.com/docs/functions/firestore-events
            let id = '' + Date.now()
            const messagePath = owner_id + '/' + script.id + '/messages'

            const docRef = doc(db, 'exports', id)
            payload.settings.messagePath = messagePath
            let newPayload = JSON.parse(JSON.stringify(payload)) //this gets rid of the blobs
            commit('exportMessage', 'sending data to server')
            let realtimeDb = getDatabase()

            const messageRef = realtimeRef(realtimeDb, messagePath);

            await setDoc(docRef, newPayload)

            //listen for realtime messages
            console.log('listtening for message updates', messageRef)

            onValue(messageRef, async (snapshot) => {
                const data = snapshot.val();
                console.log(data)
                if (data?.msg) {
                    if (data.status == 'processing') {
                        console.log('proocessing message', data.msg)
                        commit('exportMessage', data.msg);
                    } else if (data.status == 'finished') {
                        commit('exportMessage', 'Your file is done! Getting preview')
                        console.log(data.msg)
                        //off(messageRef)
                        await dispatch('downloadExportedVideo', data.msg)
                    } else if (data.status == 'error') {
                        console.log('error', data.msg)
                        commit('setExporting', false)
                    }

                }
            });
            // const url = 'https://us-central1-record-rehearse-tape.cloudfunctions.net/ffmpeg-transcode-2'
            // fetch(url, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body:  JSON.stringify(payload)
            //   })
            //   .then(response => response.json())
            //   .then(data => {
            //     dispatch('downloadExportedVideo',data.uploadedTo)
            //   })
            //   .catch((error) => {
            //     console.log(error)
            //   })


        },

        // async inviteFriend(context,payload) {
        //     //this should upload a friend request to the request collection

        //     //there should be a function watching the request collection which, on change
        //     //1. check if there is already a user with this email. If they are, use their uid in the request. 
        //     //send them a request to join
        //     //if they aren't then send them a referral link (with this user's code so they can get credit)
        //     //send them an email request
        // },

        async cloudScriptUpdated(context, payload) {

            if (payload.type == 'removed') {
                const index = context.state.cloudScripts.findIndex(s => s.id == payload.doc.id)
                if (index >= 0) context.state.cloudScripts.splice(index, 1)

                context.dispatch('removeScript', payload.doc.id)
                return
            }
            const data = await payload.doc.data()
            let script = context.getters.getScript(data.id)
            if (!data) return
            if (script && script.updated < data.updated) {
                let blocks = script.blocks
                //console.log('updating local script ',script.title)
                data.blocks.forEach(async block => {
                    if (block.audio) {
                        let oldBlock = blocks.find(b => b.id == block.id)
                        if (oldBlock.audioUpdated > block.audioUpdated) {
                            console.log('using old block audio', new Date(oldBlock.audioUpdated).toUTCString())
                            block.audio = oldBlock.audio
                        } else {
                            console.log('downloading new audio', new Date(block.audioUpdated).toUTCString())
                            await context.dispatch('downloadAudioForBlock', block)
                        }
                    }
                })
                context.dispatch('saveScript', data)
            } else {
                //console.log('cloud script updatted is not more recent',script.id,script?.updated,data.updated)
            }

            let cloudScript = context.state.cloudScripts.find(s => s.id == data.id)
            if (cloudScript) {
                Object.assign(cloudScript, data)
            } else {
                context.state.cloudScripts.push(data)
            }
        },


        unsubscribeFromCloudListeners({ state }) {
            state.cloudListeners.forEach(listener => listener())
        },

        //make this into a function
        // async creditReferral(context,payload) {
        //     console.log(payload)
        //     //https://stripe.com/docs/api/customer_balance_transactions/create
        //     //const stripe = new Stripe("pk_test_51MPAqrHAo5jJPUCX01eVqcWmJeQp5RdtEllltfnc2eHf9Xcly1AGLb3Z4IPfvmATDilZTa3aRCapQDEkhNhkoBvs00TzYfuy9o");
        //     // const userId = context.getters.getUser.uid
        //     // const sub = context.getters.getSubscription
        //     // console.log(stripe,sub)
        //     // const amount = sub.items[0].price.unit_amount * -1
        //     // const currency = sub.items[0].price.currency

        //     // const balanceTransaction = await stripe.customers.createBalanceTransaction(
        //     //     payload,
        //     //     {amount, currency}
        //     // );
        //     // console.log('updating credit',balanceTransaction)
        //     // updateDoc(doc(db,'users',userId),{referredBy: ''})
        // },

        setInfoPage(context, payload) {
            const data = payload.doc.data()
            const index = context.state.infoPages.findIndex(p => p.id == data.id)
            if (index >= 0) {
                context.state.infoPages.splice(index, 1, data)
            } else {
                context.state.infoPages.push(data)
            }
            context.getters.getStorage.setItem('infoPages', context.state.infoPages)
        },
        addCloudListeners(context) {
            const userId = context.getters.getUser.uid
            //console.log('adding cloud listeners with uid',userId)
            if (!userId) return

            const templatesRef = collection(db, 'infoPages')
            let infoPagesUnsubscribe = onSnapshot(templatesRef, async (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    context.dispatch('setInfoPage', change)
                })
            })
            context.commit('addCloudListener', infoPagesUnsubscribe)


            //script listener
            const scriptRef = collection(db, 'uploads', userId, 'scripts')
            let scriptUnsubscribe = onSnapshot(scriptRef, async (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    //console.log('cloud script change',change.type,change.doc.id)
                    context.dispatch('cloudScriptUpdated', change)
                })
            })
            context.commit('addCloudListener', scriptUnsubscribe)

            const subscriptionsRef = collection(db, "users", userId, "subscriptions")
            const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]))

            let subscriptionUnsubscribe = onSnapshot(q, async (snapshot) => {
                snapshot.docChanges().forEach(async (change) => {
                    let subscription = change.doc.data()

                    subscription.priceData = (await getDoc(subscription.price)).data();
                    context.commit('setSubscription', subscription)
                })
            });
            context.commit('addCloudListener', subscriptionUnsubscribe)

            const friendsRef = collection(db, 'users', userId, 'friends')
            let friendsUnsubscribe = onSnapshot(friendsRef, async (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    //console.log('friend doc changed',change.doc.data())

                    context.dispatch('friendUpdated', change)
                })
            })
            context.commit('addCloudListener', friendsUnsubscribe)

            const friendRequestRef = collection(db, 'friend_requests')
            const requestq = query(friendRequestRef, where('to_email', '==', context.getters.getUser.email), where('status', '==', 'pending'))
            //console.log('checking for friend request for',user.email,q)
            let friendRequestUnsubscribe = onSnapshot(requestq, async (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    context.dispatch('friendRequest', change)
                })
            })
            context.commit('addCloudListener', friendRequestUnsubscribe)

            const requestm = query(friendRequestRef, where('from_id', '==', context.getters.getUser.uid), where('status', '==', 'pending'))
            //console.log('checking for friend request for',user.email,q)
            let friendRequestMadeUnsubscribe = onSnapshot(requestm, async (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    context.dispatch('friendRequest', change)
                })
            })
            context.commit('addCloudListener', friendRequestMadeUnsubscribe)
            //let querySnapshot = await getDocs(q)
            // querySnapshot.forEach((snap) => {
            //     //if there is no referal code in the query, then use the user id from the friend request to get their referal code
            //     if (!newData.referredBy) {
            //         newData.referredBy = snap.data().from_id
            //     }
            //     //make sure to update the new information as the requested user is now an actual user

            // })

            const sharedRef = query(collectionGroup(db, 'shared'), where('fid', '==', userId))
            let sharedUnsubscribe = onSnapshot(sharedRef, async (snapshot) => {
                //console.log('hello')
                snapshot.docChanges().forEach((change) => {
                    context.dispatch('sharedUpdated', change)
                })
            },
                (error) => console.log('there was an error getting the shared collection for', userId, error))
            context.commit('addCloudListener', sharedUnsubscribe)
        }

    }
}

// export the store
export default store