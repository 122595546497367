<template>
<v-row no-gutters>
    <v-col cols="12">
        <v-card-title class="pa-0">Highlighting</v-card-title>
        You can set the highlight color for your lines and other character's lines.
    </v-col>
    <v-col cols="6">
    <div style="display:grid">
        <span class="overline">My Lines</span>
        <v-swatches
            v-model="myHighlight"
            swatches="text-advanced"
            :wrapper-style="{'line-height':'normal'}"
            :trigger-style="{'width': '30px','height':'30px','border':'1px solid black'}"
        ></v-swatches>
        
    </div>
</v-col>
<v-col cols="6">
    <div style="display:grid">
        <span class="overline">Others' Lines</span>
        <v-swatches
            v-model="themHighlight"
            swatches="text-advanced"
            :wrapper-style="{'line-height':'normal'}"
            :trigger-style="{'width': '30px','height':'30px','border':'1px solid black'}"
        ></v-swatches>
        
    </div>
</v-col>
</v-row>
</template>

<script>

import VSwatches from 'vue-swatches'
export default {
    name: "HighlightSettings",
    props: ['noUpdate'],
    mounted() {
        
    },
    components: {
        VSwatches,
    },
    computed: {
        
    },
    watch: {
        myHighlight() {
            if (!this.noUpdate) {
                this.$store.dispatch('saveSettings',{
                    myHighlight: this.myHighlight,
                })
            }
        },
        themHighlight() {
            if (!this.noUpdate) {
                this.$store.dispatch('saveSettings',{
                    theirHighlight: this.themHighlight,
                })
            }
        },
    },
    data() {
        return {
            myHighlight: this.$store.getters.getSetting('myHighlight') || '#FFFF00',
            themHighlight: this.$store.getters.getSetting('theirHighlight') || '#00FFFF',
        }
    },
    methods: {
        
    }
}
</script>