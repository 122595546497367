<template>
    <v-card>
        <v-card-title class="primary">
        Settings
        </v-card-title>

        <v-card-text>
            <v-tabs
                v-model="tab"
                >
                <v-tab
                    v-for="page in pages"
                    :key="page"
                    class="px-1"
                >
                    {{ page }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" touchless>
                <v-tab-item>
                    <v-card height="250" style="overflow-y:auto">
                        <v-card-text>
                            <language-setting ref="language" :noUpdate="true"></language-setting>
                            
                            <cloud-setting-vue ref="cloud" :noUpdate="true"></cloud-setting-vue>
                            
                            <highlight-setting-vue ref="highlighter" :no-update="true"></highlight-setting-vue>
                        </v-card-text>
                    </v-card>
                    
                </v-tab-item>
                
            <v-tab-item>
                <v-card height="250" style="overflow-y:auto">
                <v-select label="Preferred video input" :items="videoDevices" v-model="videoDevice"></v-select>
            <v-divider></v-divider>
            
            <v-divider></v-divider>
            <span class="overline">Audio</span>
            <v-row>
                <v-col cols="6" sm="4">
                    <v-select label="Preferred input device" :items="audioDevices" v-model="audioDevice"></v-select>
                </v-col>
                <v-col cols="6" sm="4">
                    <v-select label="Save as" :items="blobTypes" v-model="audioFormat"></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field clearable label="Gain +/-" min="0" hint="1 is no change" type="number" step=".1" v-model.number="gainValue">
                        <template v-slot:append-outer>
                            <record-btn :afterRecording="updateAudio" :gain="gainValue"></record-btn>
                            </template>
                    </v-text-field>
                    
                </v-col>
            </v-row>
            
            <waveform-elem
            :blobFileURL="blob"
            ></waveform-elem>
        </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card height="250" dense style="overflow-y:auto">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field v-model.number="countdown.seconds" label="Countdown" suffix="seconds" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field label="Beeps on" hint="comma separated list" v-model="countdown.beeps" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select label="Format" :items="countdownFormats" v-model="countdown.format" hide-details="auto"></v-select>
                    </v-col>
                </v-row>
            </v-card>
            </v-tab-item>
            </v-tabs-items>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            small
            @click="close"
        >
            Cancel
        </v-btn>
        <v-btn
            color="success"
            rounded
            small
            @click="saveAndClose"
        >
            Save settings
        </v-btn>
        </v-card-actions>
    </v-card>
</template>
<style>
.v-color-picker__swatch .v-color-picker__color {
    border-radius: 50% !important;
    max-height: 25px !important;
    height: 25px;
    width: 25px;
}
</style>
<script>
import WaveformElem from "@/components/scriptElems/WaveformElem.vue";
import RecordBtn from "@/components/scriptElems/RecordBtn.vue";
import HighlightSettingVue from './HighlightSetting.vue';
import LanguageSetting from "./LanguageSetting.vue";

import CloudSettingVue from './CloudSetting.vue';
export default {
    name: "RecordingSettings",
    props: {},
    components: {
        WaveformElem,
        RecordBtn,
        HighlightSettingVue,
        LanguageSetting,
        CloudSettingVue,
    },
    mounted() {
        this.deviceOptions()

    },
    computed: {
        languageOpts() {
            return this.$store.getters.getLanguageOpts
        },
    },
    
    data() {
        return {
            blob: null,
            pages: ['General','Audio/Video','Countdown'],
            tab: '',
            countdownFormats: ['numbers','dots','movie'],
            
            gainValue: this.$store.getters.getSetting('gainValue'),
            audioDevices: [],
            videoDevices: [],
            blobTypes: ['mp3','wav'],
            defaultLang: this.$store.getters.getSetting('defaultLang') || 'en-US',
            audioFormat: this.$store.getters.getSetting('audioBlobFormat'),
            //videoFormat: this.$store.getters.getSetting('videoBlobFormat'),
            audioDevice: this.$store.getters.getSetting('audioDevice'),
            videoDevice: this.$store.getters.getSetting('videoDevice'),
            myHighlight: this.$store.getters.getSetting('myHighlight') || '#FFFFFF',
            themHighlight: this.$store.getters.getSetting('theirHighlight') || '#FFFFFF',
            defaultCloudSave: this.$store.getters.getSetting('defaultCloudSave') || false,
            countdown: this.$store.getters.getSetting('countdown') || {seconds: 0,beeps: '0',format: 'numbers'},
        }
    },
    methods: {
        deviceOptions() {
            var _this = this
            navigator.mediaDevices.enumerateDevices().then(devices => {
                devices.forEach(d => {
                    if (d.kind == 'audioinput') {
                        _this.audioDevices.push({
                            value: d.deviceId,
                            text: d.label
                        })
                    } else if (d.kind == 'videoinput') {
                        _this.videoDevices.push({
                            value: d.deviceId,
                            text: d.label || 'not shown'
                        })
                    }

                })
            })
            
        },
        close() {
            this.$emit('close')
        },
        saveAndClose() {
            if (this.gainValue <= 0) this.gainValue = 1 
            this.$store.dispatch('saveSettings',{
                gainValue: this.gainValue,
                audioBlobFormat: this.audioFormat,
                defaultLang: this.$refs.language.defaultLang,
                //videoBlobFormat: this.videoFormat,
                audioDevice: this.audioDevice,
                videoDevice: this.videoDevice,
                myHighlight: this.$refs.highlighter.myHighlight,
                theirHighlight: this.$refs.highlighter.themHighlight,
                countdown: this.countdown,
                defaultCloudSave: this.defaultCloudSave,
            })
            this.close()
        },
        saveGain() {
            this.$store.dispatch('saveSetting',{gainValue: this.gainValue})
        },
        updateAudio(blob) {
            this.blob = blob.blob
        },
        
        setGain() {
            var gain = this.recorder.gain(parseFloat(this.gainValue))
            console.log('gain changed',gain)
        },
        
    }
}
</script>