<template>
<v-card class="black--text">
                <v-card-title class="pa-1">Highlighting DONE!</v-card-title>
                <v-card-text class="black--text">
                <p>Before you get to running your lines, look through the script to make sure we found all the lines.</p>
                <p>Your lines will be highlighted in <span class="myline">yellow</span>. <br />Other characters will be <span class="theirline">blue</span>. <br />Action lines are <span class="actionline">grey</span>.</p>
                <p>Once all of the dialogue is highlighted, click IMPORT LINES</p>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>What if highlighting is wrong?</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>Go through and re-highlight the dialogue that was missed, or action that was incorrectly marked as dialogue. 
                                The text will switch back and forth between action and dialogue.</p>
                                <p>REMEMBER:
                            <ul><li>When highlighting dialogue, always include the character name!</li>
                            <li>If you need to start over, click the "Clear Highlight" button.</li></ul></p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            How do I exclude text from being imported?
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>You can exclude entire pages by entering the page numbers in the "Skip Pages" field. None of the text on that page will be imported. You can also include page ranges (eg. "2-4").</p>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>What do the sliders do?</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>If you only want to import some of the dialogue/action on a page, use the vertical margins slider to exclude either the top or bottom of the page. These are set on a page by page basis.</p>
                            <p>You can also set margins on either the left or right using the top slider. These margins are the same for every page.</p>
                            <p>Again, text which is outside the margins will not be imported.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                </v-expansion-panels>
                
            </v-card-text>
  
            </v-card>
</template>
<style>
.myline {
        background: #FFF59D;
    }
    .theirline {
        background: #BBDEFB;
    }
    .actionline {
        background: #E0E0E0;
    }
</style>
<script>

export default {
    name: "HelpImport3",
    props: {},
    computed: {
        
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>